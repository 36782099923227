export const testCenterTranslations = {
  en: {
    testCenter: {
      testCenter: 'Test Center',
      examSessions: 'Exam Sessions',
      labors: 'Labors',
      relatedTCAssessors: 'Related TC Assessors',
      from: 'From',
      to: 'To',
      bookingDetails: 'Booking details',
      practicalResults: 'Practical results',
      basicInformation: 'Basic Information',
      category: 'Category',
      uploadFiles: {
        uploadFiles: 'Upload Files',
        uploadedFiles: 'Uploaded Files',
        clearFilter: 'Clear Filter',
        addIndividual: 'Add Individual',
        addGroup: 'Add Group'
      },
      laborsPage: {
        editLabor: 'Edit Labor',
        editEmail: 'Edit Email',
        editEmailHint: 'Will be used to share the certificate ',
        labors: 'labors',
        listOfLabors: 'List Of Labors',
        filterLabors: 'Filter Labors',
        registeredLabors: 'Registered Labors',
        downloadCsv: 'Download CSV',
        laborsCounterTooltip: {
          all: 'The Count of All Labors Exam',
          pass: 'The Count of All Labors That Passed',
          fail: 'The Count of All Labors That Failed',
          pending: 'The Count of Unpaid Certificates',
          expired: 'The Count of Expired Certificates',
          rejected: 'The Count of Rejected Exam',
          reserved: 'The Count of All Labors That Reserved Exams'
        },
        counter: {
          all: 'All',
          pass: 'Pass',
          fail: 'Fail',
          pending: 'Pending',
          expired: 'Expired',
          rejected: 'Rejected',
          reserved: 'Reserved'
        },
        group: 'Group'
      },
      assessorsPage: {
        inviteAssessor: 'Invite assessor',
        selectRegistrationMethod: 'Choose registration method',
        enterReason: 'Enter Reason'
      },
      assessorDetails: {
        languages: 'Languages',
        educationalQualification: 'Educational Qualification',
        experience: 'Experience',
        institute: 'Institute',
        instituteName: 'Institute Name',
        language: 'Language',
        itSkills: 'IT Skills',
        teamsZoomSkills: 'Teams/Zoom Skills',
        professionalQualification: 'Professional Qualification',
        internationalQualification: 'International Qualification',
        internationalExperience: 'International Experience',
        rejectModalTitle: 'Please, describe bellow the reason to <b>reject</b> the applied Assessor candidate',
        approveModalTitle: 'Are you sure you want to <b>approve</b> the applied Assessor candidate?',
        approvedSuccessfully: 'The Assessor has been approved successfully',
        rejectedSuccessfully: 'The Assessor has been rejected successfully',
        viewPassport: 'View passport',
        licensesCertifications: 'Licenses & Certifications',
        experienceCertificate: 'Experience certificate',
        it_diploma: 'IT diploma',
        it_certificate: 'IT certificate',
        internet: 'Internet',
        degree: 'Degree',
        diploma: 'Diploma',
        training: 'Training',
        no_international_qualification: 'No international qualification',
        cancellationReason: 'Cancellation reason'
      },
      sessionsManagement: {
        startCBT: 'Start CBT',
        startPractical: 'Start Practical',
        testTakers: 'Test takers',
        title: 'Sessions management',
        sessionId: 'Session ID',
        sessionDate: 'Session Date',
        duration: 'Duration',
        numberOfSeats: 'Number of seats',
        numberOfReservations: 'Number of reservations',
        repeatSession: 'Repeat session',
        examStatus: 'Exam session status',
        typeHere: 'Type here',
        equals: 'Equals',
        moreThan: 'More than',
        lessThan: 'Less than',
        seats: 'Seats',
        reservations: 'Reservations',
        selectCategory: 'Select category',
        selectRegistrationMethod: 'Choose registration method',
        examSessionStatus: 'Exam session status',
        selectStatus: 'Select status',
        chooseDate: 'Choose date',
        from: 'From',
        to: 'To',
        enterCancelReason: 'Please enter the cancellation reason',
        enterReason: 'Enter Reason',
        confirmCancellation: 'I confirm the reservation cancellation and acknowledge that all the data entered are correct and under my responsibility',
        assignedAssessor: 'Assigned Assessor',
        selectAssessor: 'Select Assessor',
        rejectionReason: 'Rejection reason',
        session: 'Session',
        code: 'Code',
        cancellationReason: 'Cancellation Reason',
        sessionInformation: 'Session Information',
        cancelledBy: 'Cancelled by',
        userRole: 'Role',
        repeat: {
          does_not_repeat: 'Does not repeat',
          daily: 'Daily',
          weekly: 'Weekly',
          monthly_on_the_first: 'Monthly on the first',
          monthly: 'Monthly'
        },
        removeReservationModal: {
          checkboxText: 'I confirm the reservation cancellation and acknowledge that all the data entered are correct and under my responsibility',
          modalTitle: 'Please enter the cancellation reason',
          textareaPlaceholder: 'Enter reason',
          textareaLabelText: 'Reason'
        }
      },
      transactionHistory: {
        transactionHistory: 'Transaction History'
      },
      reports: {
        reports: 'Reports'
      }
    }
  },
  ar: {
    testCenter: {
      testCenter: 'مركز الاختبار',
      examSessions: 'جلسة الاختبار',
      labors: 'العمالة',
      relatedTCAssessors: 'المقيمين المرتبطين',
      from: 'من',
      to: 'الى',
      bookingDetails: 'تفاصيل الحجز',
      practicalResults: 'نتائج الاختبار العملي',
      basicInformation: 'المعلومات الأساسية',
      category: 'الفئة',
      uploadFiles: {
        uploadFiles: 'ملفات العمالة',
        uploadedFiles: 'ملفات العمالة',
        clearFilter: 'إزالة التصفية',
        addIndividual: 'إضافة عامل',
        addGroup: 'إضافة مجموعة'
      },
      laborsPage: {
        editLabor: 'تحرير العمل',
        editEmail: 'تعديل البريد الالكتروني',
        labors: 'العمالة',
        editEmailHint: 'سيتم إستخدامه لمشاركة الشهادة',
        listOfLabors: 'قائمة العمال',
        filterLabors: 'تصفية القائمة',
        registeredLabors: 'العمالة المسجلين',
        downloadCsv: 'تحميل ملف CSV',
        laborsCounterTooltip: {
          all: 'عدد اختبارات جميع العمالة',
          pass: 'جميع العمالة الناجحين ',
          fail: 'جميع العمالة الراسبين',
          pending: 'عدد الشهادات الغير مدفوعة',
          expired: 'عدد الشهادات منتهية الصلاحية',
          rejected: 'عدد الاختبارات المرفوضة',
          reserved: 'عدد العمالة الذين حجزوا اختبارات'
        },
        counter: {
          all: 'الكل',
          pass: 'ناجح',
          fail: 'راسب',
          pending: 'معلق',
          expired: 'منتهية الصلاحية',
          rejected: 'مرفوض',
          reserved: 'محجوز'
        },
        group: 'مجموعة'
      },
      assessorsPage: {
        inviteAssessor: 'دعوة المراقب',
        selectRegistrationMethod: 'اختر طريقة التسجيل',
        enterReason: 'ادخل السبب'
      },
      assessorDetails: {
        languages: 'اللغات',
        educationalQualification: 'المؤهل العلمي',
        experience: 'الخبرات',
        institute: 'المعهد',
        instituteName: 'اسم المعهد',
        language: 'اللغات',
        itSkills: 'مهارات تكنولوجيا المعلومات',
        teamsZoomSkills: 'برامج الانترنت (تيمز-زوم)',
        professionalQualification: 'المؤهلات المهنية',
        internationalQualification: 'المؤهلات الدولية',
        internationalExperience: 'الخبرات الدولية',
        rejectModalTitle: 'فضلا ادخل سبب رفض المقيّم ادناه (سيتم عرض السبب للمقيم)',
        approveModalTitle: 'هل انت متأكد من قبول طلب تسجيل المقيّم؟',
        approvedSuccessfully: 'تم قبول المقيّم بنجاح',
        rejectedSuccessfully: 'تم رفض المقيّم بنجاح',
        viewPassport: 'عرض جواز السفر',
        licensesCertifications: 'التراخيص والشهادات',
        experienceCertificate: 'شهادات الخبرة',
        it_diploma: 'دبلوم تكنولوجيا المعلومات',
        it_certificate: 'شهادة تكنولوجيا المعلومات',
        internet: 'إنترنت',
        degree: 'الدرجة العلمية',
        diploma: 'شهادة دبلوم',
        training: 'دورة تدريب',
        no_international_qualification: 'لايوجد',
        cancellationReason: 'سبب الإلغاء'
      },
      sessionsManagement: {
        startPractical: 'بداية العملي',
        startCBT: 'بداية النظري',
        testTakers: 'المختبرون',
        title: 'إدارة الجلسات',
        sessionId: 'معرف الجلسة',
        typeHere: 'أكتب هنا',
        equals: 'يساوي',
        moreThan: 'أكثر من',
        lessThan: 'أقل من',
        seats: 'المقاعد',
        reservations: 'الحجوزات',
        selectCategory: 'اختر الفئة',
        selectRegistrationMethod: 'اختر طريقة التسجيل',
        examSessionStatus: 'حالة جلسة الاختبار',
        selectStatus: 'اختر الحالة',
        chooseDate: 'اخر التاريخ',
        from: 'من',
        to: 'إلى',
        enterCancelReason: 'الرجاء إدخال سبب الإلغاء',
        enterReason: 'ادخل السبب',
        confirmCancellation: 'أؤكد إلغاء الحجز وأقر بأن جميع البيانات المدخلة صحيحة وتحت مسؤوليتي',
        assignedAssessor: 'المقيّم المكلّف',
        selectAssessor: 'اختر المقيّم',
        rejectionReason: 'سبب الرفض',
        session: 'الجلسة',
        code: 'الرمز',
        sessionDate: 'تاريخ الجلسة',
        duration: 'مدة',
        numberOfSeats: 'عدد المقاعد',
        numberOfReservations: 'عدد الحجوزات',
        repeatSession: 'تكرار الجلسة',
        examStatus: 'حالة جلسة الاختبار',
        cancellationReason: 'سبب الإلغاء',
        sessionInformation: 'معلومات الجلسة',
        cancelledBy: 'ألغيت من قبل',
        userRole: 'دور المستخدم',
        repeat: {
          does_not_repeat: 'عدم تكرار',
          daily: 'يوميا',
          weekly: 'اسبوعيا',
          monthly_on_the_first: 'أول يوم من كل شهر',
          monthly: 'شهريا'
        },
        removeReservationModal: {
          checkboxText: 'أؤكد إلغاء الحجز وأقر بأن جميع البيانات المدخلة صحيحة وتحت مسؤوليتي',
          modalTitle: 'الرجاء إدخال سبب الإلغاء',
          textareaPlaceholder: 'ادخل السبب',
          textareaLabelText: 'السبب'
        }
      },
      transactionHistory: {
        transactionHistory: 'تاريخ المعاملة'
      },
      reports: {
        reports: 'التقارير'
      }
    }
  }
};
