<template>
  <div :class="{'loading-is-active': loading}" class="svp-layout">
    <svp-header
      v-if="useNewHeader"
      :sidebar-is-open.sync="sidebarIsOpen"
      :use-aside="useAside"
      :use-notification="useNotification"
    >
      <template>
        <slot name="header"/>
      </template>
    </svp-header>
    <svp-header-old
      v-else
      :sidebar-is-open.sync="sidebarIsOpen"
      :use-aside="useAside"
      :use-notification="useNotification"
    >
      <template>
        <slot name="header"/>
      </template>
    </svp-header-old>
    <svp-aside
      v-if="useAside && useNewAside"
      :is-loading="asideIsLoading"
      :nav-items="navItems"
      :sidebar-is-open.sync="sidebarIsOpen"
      @closeSidebar="closeSidebar()"
    >
      <template>
        <slot name="asideList"/>
      </template>
    </svp-aside>

    <svp-aside-old
      v-if="useAside && !useNewAside"
      :is-loading="asideIsLoading"
      :nav-items="navItems"
      :sidebar-is-open.sync="sidebarIsOpen"
      @closeSidebar="closeSidebar()"
    >
      <template>
        <slot name="asideList"/>
      </template>
    </svp-aside-old>
    <main :class="sidebarOpenClass" class="svp-main">
      <slot/>
    </main>

    <svp-footer v-if="useFooter"/>
    <div
      :class="{'overlay-is-open': sidebarIsOpen, 'overlay-is-closed': sidebarIsOpen === false }"
      class="svp-overlay"
      @click="sidebarIsOpen = false"
    />
    <transition name="fade">
      <div v-if="loading" class="svp-loading">
        <svp-spinner/>
      </div>
    </transition>
  </div>
</template>

<script>

import SvpHeaderOld from '@/app/shared/components/appShell/header/SvpHeaderOld.vue';
import SvpHeader from '@/app/shared/components/appShell/header/SvpHeader.vue';
import SvpAsideOld from '@/app/shared/components/appShell/aside/SvpAsideOld.vue';
import SvpAside from '@/app/shared/components/appShell/aside/SvpAside.vue';
import SvpFooter from '@/app/shared/components/appShell/footer/SvpFooter.vue';
import SvpSpinner from '@/app/shared/components/appShell/spinner/SvpSpinner.vue';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    navItems: {
      type: Array,
      default: () => []
    },
    useNewAside: {
      type: Boolean,
      default: false
    },
    useNewHeader: {
      type: Boolean,
      default: false
    },
    useAside: {
      type: Boolean,
      default: true
    },
    useFooter: {
      type: Boolean,
      default: false
    },
    useNotification: {
      type: Boolean,
      default: true
    },
    asideIsLoading: {
      type: Boolean,
      default: false
    }
  },
  components: { SvpSpinner, SvpFooter, SvpAsideOld, SvpHeaderOld, SvpAside, SvpHeader },
  data () {
    return {
      sidebarIsOpen: false,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    sidebarOpenClass () {
      if (!this.sidebarIsOpen || !this.useAside) return '';
      return this.useNewAside ? 'sidebar-is-open-new' : 'sidebar-is-open';
    }
  },
  methods: {
    updateWindowWidth () {
      this.windowWidth = window.innerWidth;
    },
    closeSidebar () {
      if (this.windowWidth > 768) return;
      this.sidebarIsOpen = false;
    }
  },
  watch: {
    windowWidth (newWidth) {
      this.sidebarIsOpen = newWidth > 768;
    }
  },
  created () {
    window.addEventListener('resize', this.updateWindowWidth);
    if (this.windowWidth > 768) this.sidebarIsOpen = true;
  },
  destroyed () {
    window.removeEventListener('resize', this.updateWindowWidth);
  }
};
</script>

<style lang="scss">
.svp-layout {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .svp-main {
    padding: 40px 16px 100px 16px;
    margin-top: 70px;
    transition: transform 0.3s ease;
    position: relative;
    flex-grow: 1;
    @include media('>=tablet') {
      padding: 40px 40px 120px 40px;
    }
    @include media('>=widescreen') {
      padding: 40px 80px 160px 40px;
    }

    &.sidebar-is-open {
      transform: translateX($sidebar-mob-width-old);
      @include media('>tablet') {
        transform: none;
        margin-left: $sidebar-full-width-old;
      }
    }

    &.sidebar-is-open-new {
      transform: translateX($sidebar-full-width);
      @include media('>phone') {
        transform: none;
        margin-left: $sidebar-full-width;
      }
    }

  }

  .svp-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;
    transition: background-color 0.3s ease;
    @include media('>tablet') {
      display: none;
    }

    &.overlay-is-open {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &.overlay-is-closed {
      display: none;
    }

  }

  .svp-loading {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1100;
    display: flex;
    background-color: $color-white;
    // TODO: should be realized "overflow hidden" when loader is show. But for now styles leak or vuex store leak
    //@at-root body {
    //  overflow: hidden;
    //}
  }
}

</style>
