import Gateway from '@/api/index';

class RegistrationAssessorApi {
  assessorRegistration = (data) => {
    return Gateway.post('assessor_space/registrations', data);
  }

  validateAssessorRegistration = (data) => {
    return Gateway.post('assessor_space/registrations/validate', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  assessorTCRegistration = (data) => {
    return Gateway.post('test_center_owner_space/assessors/register', data);
  }

  validateAssessorTCRegistration = (data) => {
    return Gateway.post('test_center_owner_space/assessors/validate_registration', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }

    });
  }
}

export default new RegistrationAssessorApi();
