<template>
  <div class="svp-icon">
    <svg :width="width" :height="height" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3877 15.8228C16.4936 15.8228 19.853 12.9747 19.853 9.49366C19.853 6.01265 16.4936 3.16455 12.3877 3.16455C8.28177 3.16455 4.92236 6.01265 4.92236 9.49366C4.92236 12.9747 8.28177 15.8228 12.3877 15.8228Z" fill="currentColor"/>
      <path d="M24.8922 20.6487L21.6262 14.8734C22.8393 13.3703 23.5858 11.4715 23.5858 9.49367C23.5858 4.27215 18.5467 0 12.3878 0C6.22889 0 1.18979 4.27215 1.18979 9.49367C1.18979 11.5506 1.93632 13.3703 3.24276 14.9525L0.0699876 20.6487C-0.0233292 20.8861 -0.0233292 21.2025 0.0699876 21.3608C0.163304 21.519 0.536571 21.7563 0.816522 21.7563L5.20241 21.9937L8.18855 24.7627C8.37518 24.9209 8.65513 25 8.84176 25C8.84176 25 8.93508 25 9.0284 25C9.30835 25 9.5883 24.7627 9.77493 24.5253L12.4811 19.7785L15.1873 24.5253C15.2806 24.7627 15.5606 24.9209 15.9338 25C15.9338 25 16.0272 25 16.1205 25C16.4004 25 16.5871 24.9209 16.7737 24.7627L19.7598 21.9937L24.1457 21.7563C24.4257 21.7563 24.7056 21.5981 24.8922 21.3608C25.0789 21.1234 24.9856 20.8861 24.8922 20.6487ZM12.3878 1.58228C17.5202 1.58228 21.7195 5.14241 21.7195 9.49367C21.7195 13.8449 17.5202 17.4051 12.3878 17.4051C7.25538 17.4051 3.05612 13.8449 3.05612 9.49367C3.05612 5.14241 7.25538 1.58228 12.3878 1.58228Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconRosette',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
