<template>
  <div class="svp-spinner" :style="{ width: widthPX, height: heightPX, 'max-width': widthPX, 'max-height': heightPX }">
    <span class="svp-spinner-inner"/>
  </div>
</template>

<script>
export default {
  name: 'SvpSpinner',
  props: {
    width: {
      type: [String, Number],
      default: '200'
    },
    height: {
      type: [String, Number],
      default: '200'
    }
  },
  computed: {
    widthPX () {
      return `${this.width}px`;
    },
    heightPX () {
      return `${this.height}px`;
    }
  }
};
</script>

<style lang="scss">
.svp-spinner {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
  .svp-spinner-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .svp-spinner-inner::before,
  .svp-spinner-inner::after,
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    border: 20px solid transparent;
    border-top-color: $color-primary;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    top: 10%;
    left: 10%;
  }
  .svp-spinner-inner::before {
    animation-delay: -0.45s;
  }
  .svp-spinner-inner::after {
    animation-delay: -0.3s;
  }
  &::before {
    animation-delay: -0.15s;
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
