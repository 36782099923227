<template>
  <div class="sign-template" :class="[$route.name]">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <button class="btn btn-auth" @click="goBack">{{ $t('back') }}</button>
        <div class="sign-template__logo--form sign-template__logo">
          <div @click="goToHomePage"><span v-html="getLogo()"/></div>
        </div>
        <div class="sign-template__content">
          <transition name="fade" mode="out-in">
            <router-view/>
          </transition>
        </div>
      </div>
      <div class="col-md-6  d-sm-none d-md-block">
        <img class="side-logo" src="@/assets/img/sign-in-logo.svg">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Auth',
  data () {
    return {
    };
  },
  methods: {
    getLogo () {
      try {
        const src = require('@/assets/img/svp-logo.png');
        return `<img src="${src}" alt="PACC" class="sign-template__logo-img" />`;
      } catch (err) {
        return '';
      }
    },
    goToHomePage () {
      this.$router.push('/home').catch(err => {});
    },
    goBack () {
      this.$router.go(-1);
    }
  }
};
</script>
