import axios from 'axios';
import LocalStorageService from '@/services/localStorageService';
import RefreshTokenApi from '@/api/refreshToken.api';
import { get } from 'lodash';
import store from '@/store';

const module = {
  state: {
    isTokenRefreshing: false
  },
  getters: {
    isTokenAlreadyRefreshing: (state) => state.isTokenRefreshing

  },
  mutations: {
    'SET_TOKEN_REFRESH_STATUS' (state, payload) {
      state.isTokenRefreshing = payload;
    }
  },
  actions: {
    setTokenRefreshingStatus ({ state, commit }, payload) {
      commit('SET_TOKEN_REFRESH_STATUS', payload);
    },
    setNewAuthHeaderToAxios () {
      const token = LocalStorageService.getAccessToken();
      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    },
    setAccessTokenToLocalstorage ({ state, dispatch }, data) {
      const { access, access_expires_at } = data;
      LocalStorageService.setAccessToken(access);
      LocalStorageService.setTokenLifeTime(access_expires_at);
      dispatch('setTokenRefreshingStatus', false);
    },
    async getRefreshToken ({ commit, dispatch, getters }) {
      return await RefreshTokenApi.getRefreshToken();
    },
    refreshTokenHandler: async ({ commit, dispatch }) => {
      try {
        await store.dispatch('setTokenRefreshingStatus', true);
        // Get new access token
        const { data } = await dispatch('getRefreshToken');
        // Save new access token in LocalStorage
        await dispatch('setAccessTokenToLocalstorage', data);
        await dispatch('setNewAuthHeaderToAxios');
      } catch (err) {
        dispatch('setTokenRefreshingStatus', false);
        console.error(err);
        if (get(err, 'response.status', null) === 401) {
          // Remove User and Token from localStorage
          dispatch('clearUserAccessAndRedirect', true);
        }
      }
    }
  }
};

export default module;
