<template>
  <aside :class="{'is-open': sidebarIsOpen}" class="svp-aside">
    <nav class="content">
      <ul>
        <slot>
          <template v-if="isLoading">
            <svp-skeleton v-for="(_, key) in 8" :key="key" height="50px" type="button" width="100%"/>
          </template>

          <template v-else>
            <li v-for="item in navItems" @click="closeSidebar">
              <router-link :to="{ name: item.routeName }" active-class="active">
                <component :is="item.icon" width="25"/>
                <div>{{ $t(item.descriptionPath) }}</div>
              </router-link>
            </li>
          </template>
        </slot>
      </ul>
    </nav>
  </aside>
</template>

<script>
import SvpSkeleton from '@/app/shared/components/appShell/skeleton/SvpSkeleton.vue';

export default {
  name: 'SvpAside',
  props: {
    sidebarIsOpen: {
      type: Boolean,
      default: false
    },
    navItems: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: { SvpSkeleton },
  methods: {
    closeSidebar () {
      this.$emit('closeSidebar');
    }
  }
};
</script>

<style lang="scss" scoped>
.svp-aside {
  position: fixed;
  top: 70px;
  height: calc(100vh - 70px);
  width: $sidebar-mob-width-old;
  z-index: 600;
  transform: translateX(-100%);
  background-color: $color-white;
  box-shadow: 0 7px 24px rgba(66, 101, 139, .17);
  transition: transform 0.3s ease;
  overflow: auto;

  @include media('>tablet') {
    width: $sidebar-full-width-old;
  }

  .content {
    padding: 20px 10px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        a {
          padding: 10px;
          border-radius: 10px;
          color: $color-grey;
          display: flex;
          gap: 15px;
          align-items: center;

          span {
            width: 100px;
          }

          .svp-icon {
            display: flex;
          }

          &:hover {
            background-color: $color-green-light2;

            svg {
              color: $color-primary;
            }
          }

          &.active {
            background-color: $color-green-light2;

            svg {
              color: $color-primary;
            }
          }
        }
      }
    }
  }

  &.is-open {
    transform: translateX(0);
  }
}
</style>
