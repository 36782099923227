import ExamSessionsApi from '@/api/examSessions.api';

const module = {
  state: {
    createdSessionsCount: 0,
    sessionDetails: {
      category: {
        exam_type: ''
      },
      status: '',
      labors: []
    }
  },
  getters: {
    createdSessionsCount: state => state.createdSessionsCount,
    getSessionDetailsById: (state) => state.sessionDetails,
    getIsPendingPracticalVisible: (state) => {
      return (
        state.sessionDetails?.category.exam_type === 'cbt_and_practical' &&
        state.sessionDetails?.status === 'in_progress' &&
        state.sessionDetails?.labors.some((i) => i.reservation.exam_result === 'pending')
      );
    }
  },
  mutations: {
    'SET_CREATED_SESSIONS_COUNT' (state, count) {
      state.createdSessionsCount = state.createdSessionsCount + count;
    },
    'SET_SESSION_DETAILS_BY_ID' (state, data) {
      state.sessionDetails = data;
    }
  },
  actions: {
    getAvailableDatesSvp: async ({ rootState }, params) => {
      return await ExamSessionsApi.getAvailableDatesSvp(rootState.user.userSpace, params);
    },

    getExamSessions: async ({ commit, rootState }, params) => {
      return await ExamSessionsApi.getExamSessions(rootState.user.userSpace, params);
    },

    getAssignedExamSessions: async ({ commit, rootState }, params) => {
      return await ExamSessionsApi.getAssignedExamSessions(rootState.user.userSpace, params);
    },

    getExamSessionById: async ({ commit, rootState }, id) => {
      try {
        const response = await ExamSessionsApi.getExamSessionById(rootState.user.userSpace, id);
        commit('SET_SESSION_DETAILS_BY_ID', response.data);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },

    getPracticalResults: async ({ commit, rootState }, id) => {
      return await ExamSessionsApi.getPracticalResults(rootState.user.userSpace, id);
    },

    updatePracticalResult: async ({ rootState }, data) => {
      return await ExamSessionsApi.updatePracticalResult(rootState.user.userSpace, data);
    },

    createSession: async ({ commit, rootState }, params) => {
      try {
        const response = await ExamSessionsApi.createSession(rootState.user.userSpace, params);
        commit('SET_CREATED_SESSIONS_COUNT', 1);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },

    cancelReservations: async ({ rootState }, payload) => {
      const { id, cancellation_reason } = payload;
      return await ExamSessionsApi.cancelReservations(rootState.user.userSpace, id, { cancellation_reason });
    },

    recheduleReservation: async ({ rootState }, payload) => {
      const { id } = payload;
      return await ExamSessionsApi.recheduleReservation(rootState.user.userSpace, id, payload);
    },

    cancelSessions: async ({ rootState }, payload) => {
      const { id, cancellation_reason } = payload;
      return await ExamSessionsApi.cancelSessions(rootState.user.userSpace, id, { cancellation_reason });
    },

    getTestTakerExamInfo: async ({ commit, rootState }, params) => {
      return await ExamSessionsApi.getTestTakerExamInfo(rootState.user.userSpace, params);
    },

    startExam: async ({ commit, rootState }, params) => {
      return await ExamSessionsApi.startExam(rootState.user.userSpace, params);
    },
    /** getUnassignedSessions
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    getUnassignedSessions: async ({ commit, rootState }, params) => {
      return await ExamSessionsApi.getUnassignedSessions(rootState.user.userSpace, params);
    },

    getExamSessionReservations: async ({ commit, rootState }, payload) => {
      const { params, id } = payload;
      return await ExamSessionsApi.getExamSessionReservations(rootState.user.userSpace, params, id);
    },

    approveSessionByAssessor: async ({ commit, rootState }, id) => {
      return await ExamSessionsApi.approveSessionByAssessor(rootState.user.userSpace, id);
    },

    rejectSessionByAssessor: async ({ commit, rootState }, id) => {
      return await ExamSessionsApi.rejectSessionByAssessor(rootState.user.userSpace, id);
    },

    getExamConstraints: async ({ rootState }) => {
      return await ExamSessionsApi.getExamConstraints(rootState.user.userSpace);
    }
  }
};

export default module;
