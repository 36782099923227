export const legislatorTranslations = {
  en: {
    legislator: {
      sessionInformation: 'Session Information',
      testTakers: 'Test takers',
      uploadedFiles: 'Uploaded Files',
      labors: 'Labors',
      assessors: 'Assessors',
      payment: 'Payment',
      testCenters: 'Test Centers',
      sessionsReport: 'Sessions Report',
      transactionHistory: 'Transaction History',
      reports: 'Reports',
      examSessions: 'Exam sessions',
      chooseCity: 'Choose city',
      selectCategory: 'Select category',
      selectStatus: 'Select Status',
      sessionId: 'Session ID',
      sessionDate: 'Session Date',
      startEndTime: 'Start/End Time',
      testCenter: 'Test Center',
      fullName: 'Full name',
      success: 'success',
      checkValidity: 'Check validity',
      certificates: 'Certificates',
      uploadedLaborsResult: 'Uploaded Labors Results',
      registeredLaborsResults: 'Registered Labors Results',
      downloadCsv: 'Download CSV',
      phoneNumber: 'Phone number',
      editLabor: 'Edit Labor',
      CNIC: 'CNIC',
      registrationMethod: 'Registration method',
      from: 'From',
      to: 'To',
      cancel: 'Cancel',
      send: 'Send',
      delete: 'Delete',
      fileId: 'File ID',
      uploadDate: 'Upload Date',
      counter: {
        all: 'All',
        pass: 'Pass',
        fail: 'Fail',
        pending: 'Pending',
        expired: 'Expired',
        rejected: 'Rejected',
        reserved: 'Reserved'
      },
      laborsCounterTooltip: {
        all: 'The Count of All Labors Exam',
        pass: 'The Count of All Labors That Passed',
        fail: 'The Count of All Labors That Failed',
        pending: 'The Count of Unpaid Certificates',
        expired: 'The Count of Expired Certificates',
        rejected: 'The Count of Rejected Exam',
        reserved: 'The Count of All Labors That Reserved Exams'
      },
      assessor: {
        languages: 'Languages',
        educationalQualification: 'Educational Qualification',
        experience: 'Experience',
        institute: 'Institute',
        instituteName: 'Institute Name',
        language: 'Language',
        itSkills: 'IT Skills',
        teamsZoomSkills: 'Teams/Zoom Skills',
        professionalQualification: 'Professional Qualification',
        internationalQualification: 'International Qualification',
        internationalExperience: 'International Experience',
        rejectModalTitle: 'Please, describe bellow the reason to <b>reject</b> the applied Assessor candidate',
        approveModalTitle: 'Are you sure you want to <b>approve</b> the applied Assessor candidate?',
        approvedSuccessfully: 'The Assessor has been approved successfully',
        rejectedSuccessfully: 'The Assessor has been rejected successfully',
        viewPassport: 'View passport',
        licensesCertifications: 'Licenses & Certifications',
        experienceCertificate: 'Experience certificate',
        it_diploma: 'IT diploma',
        it_certificate: 'IT certificate',
        internet: 'Internet',
        degree: 'Degree',
        diploma: 'Diploma',
        training: 'Training',
        no_international_qualification: 'No international qualification',
        moreTen: 'More than 10 Years',
        five: '5 Years',
        three: '3 Years',
        sixteenMore: '16 Years and Above',
        fourteen: '14 Years',
        more_than_3: 'More than 3 years',
        less_than_3: 'Less than 3 years',
        no_international_experience: 'No international experience',
        dateOfBirth: 'Date of Birth',
        gender: 'Gender',
        active: 'Active',
        approved: 'Approved',
        first_review: 'First Review',
        second_review: 'Second Review',
        rejected: 'Rejected',
        rejected_by_legislator: 'Rejected by legislator',
        cancellationReason: 'Cancellation Reason',
        number: 'Number',
        download: 'Download'
      },
      sessions: {
        code: 'Code',
        duration: 'Duration',
        numberOfSeats: 'Number of seats',
        numberOfReservations: 'Number of reservations',
        repeatSession: 'Repeat session',
        examStatus: 'Exam session status',
        assignedAssessor: 'Assigned Assessor',
        cancellationReason: 'Cancellation Reason',
        identityNumber: 'Identity number',
        reservationId: 'Reservation ID',
        occupation: 'Occupation',
        repeat: {
          does_not_repeat: 'Does not repeat',
          daily: 'Daily',
          weekly: 'Weekly',
          monthly_on_the_first: 'Monthly on the first',
          monthly: 'Monthly'
        }
      },
      laborsPage: {
        editEmail: 'Edit Email',
        editEmailHint: 'Will be used to share the certificate ',
        labors: 'labors',
        listOfLabors: 'List Of Labors',
        filterLabors: 'Filter Labors',
        registeredLabors: 'Registered Labors',
        counter: {
          all: 'All',
          pass: 'Pass',
          fail: 'Fail',
          pending: 'Pending',
          expired: 'Expired',
          rejected: 'Rejected',
          reserved: 'Reserved'
        },
        group: 'Group'
      },
      uploadPage: {
        numberOfLabors: 'Number of Labors',
        uploadFiles: 'Upload Files',
        uploadedFiles: 'Uploaded Files',
        clearFilter: 'Clear Filter',
        addIndividual: 'Add Individual',
        addGroup: 'Add Group'
      },
      assessorsPage: {
        relatedTCAssessors: 'Related TC Assessors',
        inviteAssessor: 'Invite assessor',
        selectRegistrationMethod: 'Choose registration method'
      },
      paidLabors: {
        listOfLabors: 'List Of Labors',
        filterLabors: 'Filter Labors'
      },
      paymentTablePage: {
        issue: 'Issue',
        getCredit: 'Get Credit'
      }
    }
  },
  ar: {
    legislator: {
      sessionInformation: 'معلومات الجلسة',
      testTakers: 'المختبرون',
      uploadedFiles: 'ملفات العمالة',
      labors: 'العمالة',
      assessors: 'المقيّمين',
      payment: 'الدفعات',
      testCenters: 'مراكز الاختبار',
      sessionsReport: 'تقرير الجلسات',
      transactionHistory: 'تاريخ المعاملة',
      reports: 'التقارير',
      examSessions: 'جلسة الاختبار',
      chooseCity: 'اختر المدينة',
      selectCategory: 'اختر الفئة',
      selectStatus: 'اختيار الحالة',
      sessionId: 'معرف الجلسة',
      sessionDate: 'تاريخ الجلسة',
      startEndTime: 'وقت البدء / الانتهاء',
      testCenter: 'مركز الاختبار',
      fullName: 'الاسم الكامل',
      success: 'ناجحة',
      checkValidity: 'التحقق من الصلاحية',
      passportNumber: 'رقم جواز السفر',
      email: 'البريد الالكتروني',
      certificates: 'الشهادات',
      uploadedLaborsResult: 'قائمة العمالة المدرجة',
      registeredLaborsResults: 'نتائج العمالة المسجلين',
      downloadCsv: 'تحميل ملف CSV',
      editLabor: 'تحرير العمل',
      CNIC: 'رقم الهوية الوطنية',
      registrationMethod: 'طريقة التسجيل',
      from: 'من',
      to: 'إلى',
      cancel: 'إلغاء',
      send: 'ارسال',
      name: 'الاسم',
      nationality: 'الجنسية',
      delete: 'حذف',
      fileId: 'معرّف الملف',
      uploadDate: 'تاريخ الرفع',
      laborsCounterTooltip: {
        all: 'عدد اختبارات جميع العمالة',
        pass: 'جميع العمالة الناجحين ',
        fail: 'جميع العمالة الراسبين',
        pending: 'عدد الشهادات الغير مدفوعة',
        expired: 'عدد الشهادات منتهية الصلاحية',
        rejected: 'عدد الاختبارات المرفوضة',
        reserved: 'عدد العمالة الذين حجزوا اختبارات'
      },
      counter: {
        all: 'الكل',
        pass: 'ناجح',
        fail: 'راسب',
        pending: 'معلق',
        expired: 'منتهية الصلاحية',
        rejected: 'مرفوض',
        reserved: 'محجوز'
      },
      assessor: {
        languages: 'اللغات',
        educationalQualification: 'المؤهل العلمي',
        experience: 'الخبرات',
        institute: 'المعهد',
        instituteName: 'اسم المعهد',
        language: 'اللغات',
        itSkills: 'مهارات تكنولوجيا المعلومات',
        teamsZoomSkills: 'برامج الانترنت (تيمز-زوم)',
        professionalQualification: 'المؤهلات المهنية',
        internationalQualification: 'المؤهلات الدولية',
        internationalExperience: 'الخبرات الدولية',
        rejectModalTitle: 'فضلا ادخل سبب رفض المقيّم ادناه (سيتم عرض السبب للمقيم)',
        approveModalTitle: 'هل انت متأكد من قبول طلب تسجيل المقيّم؟',
        approvedSuccessfully: 'تم قبول المقيّم بنجاح',
        rejectedSuccessfully: 'تم رفض المقيّم بنجاح',
        viewPassport: 'عرض جواز السفر',
        licensesCertifications: 'التراخيص والشهادات',
        experienceCertificate: 'شهادات الخبرة',
        it_diploma: 'دبلوم تكنولوجيا المعلومات',
        it_certificate: 'شهادة تكنولوجيا المعلومات',
        internet: 'إنترنت',
        degree: 'الدرجة العلمية',
        diploma: 'شهادة دبلوم',
        training: 'دورة تدريب',
        no_international_qualification: 'لايوجد',
        moreTen: 'أكثر من 10 سنوات',
        five: '5 سنوات',
        three: '3 سنوات',
        more_than_3: 'أكثر من 3 سنوات',
        less_than_3: 'أقل من 3 سنوات',
        sixteenMore: '16 سنة فما فوق',
        fourteen: '14 سنة',
        no_international_experience: 'لايوجد',
        dateOfBirth: 'تاريخ الميلاد',
        gender: 'الجنس',
        rejected: 'مرفوض',
        active: 'نشط',
        approved: 'مقبول',
        first_review: 'مراجعة اولى',
        second_review: 'مراجعة اخيرة',
        rejected_by_legislator: 'تم رفضه من قبل المشرع',
        cancellationReason: 'سبب الإلغاء',
        number: 'ارقام',
        download: 'تحميل'
      },
      sessions: {
        code: 'الرمز',
        duration: 'مدة',
        numberOfSeats: 'عدد المقاعد',
        numberOfReservations: 'عدد الحجوزات',
        repeatSession: 'تكرار الجلسة',
        examStatus: 'حالة جلسة الاختبار',
        assignedAssessor: 'المقيّم المكلّف',
        cancellationReason: 'سبب الإلغاء',
        identityNumber: 'رقم الهوية',
        reservationId: 'رقم الحجز',
        occupation: 'المهنة',
        repeat: {
          does_not_repeat: 'عدم تكرار',
          daily: 'يوميا',
          weekly: 'اسبوعيا',
          monthly_on_the_first: 'أول يوم من كل شهر',
          monthly: 'شهريا'
        }
      },
      laborsPage: {
        editEmail: 'تعديل البريد الالكتروني',
        labors: 'العمالة',
        editEmailHint: 'سيتم إستخدامه لمشاركة الشهادة',
        listOfLabors: 'قائمة العمال',
        filterLabors: 'تصفية القائمة',
        registeredLabors: 'العمالة المسجلين',
        group: 'مجموعة'
      },
      uploadPage: {
        numberOfLabors: 'عدد العمالة',
        uploadFiles: 'ملفات العمالة',
        uploadedFiles: 'ملفات العمالة',
        clearFilter: 'إزالة التصفية',
        addIndividual: 'إضافة عامل',
        addGroup: 'إضافة مجموعة'
      },
      assessorsPage: {
        relatedTCAssessors: 'المقيمين المرتبطين',
        inviteAssessor: 'دعوة المراقب',
        selectRegistrationMethod: 'اختر طريقة التسجيل'
      },
      paidLabors: {
        listOfLabors: 'قائمة العمال',
        filterLabors: 'تصفية القائمة'
      },
      paymentTablePage: {
        issue: 'إصدار',
        getCredit: 'اشحن رصيدك'
      }
    }
  }
};
