let runtimeConfig = {};

export const requestConfig = async configJson => {
  const response = await fetch(configJson);
  runtimeConfig = await response.json();
  return runtimeConfig;
};

export const getEnvironmentVar = code => {
  const env = runtimeConfig[`VUE_APP_${code}`] || process.env[`VUE_APP_${code}`] || runtimeConfig[code];

  if (!env) {
    return '';
  }
  return env;
};
