import { lazyLoading } from '@/app/shared/router/utils';

export const landingRoutes = [
  {
    path: '/home',
    name: 'Home',
    component: lazyLoading('landing', 'Home')
  },
  {
    path: '*',
    redirect: { name: 'Home' }
  }
];
