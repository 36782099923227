import TestCentersApi from '@/api/testCenters.api';

const module = {
  state: {
    testCenterById: null
  },
  getters: {
    getTestCenterById: state => state.testCenterById
  },
  mutations: {
    'SET_TEST_CENTER_BY_ID' (state, data) {
      state.testCenterById = data;
    },
    'REMOVE_TEST_CENTER_BY_ID' (state) {
      state.testCenterById = null;
    }
  },
  actions: {
    /** getTestCenters
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    getTestCenters: async ({ rootState }, params) => {
      return await TestCentersApi.getTestCenters(rootState.user.userSpace, params);
    },
    createNewTestCenter: async ({ commit, rootState }, data) => {
      return await TestCentersApi.createNewTestCenter(rootState.user.userSpace, data);
    },
    updateTestCenter: async ({ rootState }, { payload, id }) => {
      return await TestCentersApi.updateTestCenter(rootState.user.userSpace, id, payload);
    },
    /** getTestCenterById
     * @param commit
     * @param rootState
     * @param id
     * @returns {Promise<unknown>}
     */
    getTestCenterById: async ({ commit, rootState }, id) => {
      try {
        const response = await TestCentersApi.getTestCenterById(rootState.user.userSpace, id);
        commit('SET_TEST_CENTER_BY_ID', response.data);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    /** updateTestCenterStatus
     * @param params { id, data }
     * @returns {Promise<unknown>}
     */
    updateTestCenterStatus: async ({ commit, rootState }, { id, data }) => {
      return await TestCentersApi.updateTestCenterStatus(rootState.user.userSpace, id, data);
    },
    removeTestCenterById: ({ commit }) => {
      commit('REMOVE_TEST_CENTER_BY_ID');
    }
  }
};

export default module;
