import store from '@/store';

export const checkViewPermission = (to, from, next) => {
  if (store.getters.permissionForViewOnly) return next({ name: 'TestCenterUploadPage' });
  next();
};

export const isUserTestCenter = (to, from, next) => {
  if (store.state.user.isTestCenterOwner) return next();
  return next({ path: '/' });
};
