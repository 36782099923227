import { userSpaces } from '@/config';
import PermissionsApi from '@/api/permissions.api';

const module = {
  state: {
    permissionsObject: {},
    permissionsCountry: {},
    permissionsLabors: {},
    permissionsPayment: {},
    permissionsTestCenters: {},
    permissionsCategories: [],
    permissionsIsFetched: false
  },
  getters: {
    permissionsObject: state => state.permissionsObject,
    permissionsCountry: state => state.permissionsCountry,
    permissionsLabors: state => state.permissionsLabors,
    permissionsPayment: state => state.permissionsPayment,
    permissionsTestCenters: state => state.permissionsTestCenters,
    permissionsCategories: state => state.permissionsCategories,
    permissionsLaborsUploadResults: state => state.permissionsLabors.upload_results,
    permissionsIsFetched: state => state.permissionsIsFetched
  },
  mutations: {
    'SET_PERMISSIONS' (state, data) {
      state.permissionsObject = data;
    },
    'SET_PERMISSIONS_COUNTRY' (state, data) {
      state.permissionsCountry = data;
    },
    'SET_PERMISSIONS_LABORS' (state, data) {
      state.permissionsLabors = data;
    },
    'SET_PERMISSIONS_PAYMENT' (state, data) {
      state.permissionsPayment = data;
    },
    'SET_PERMISSIONS_TEST_CENTERS' (state, data) {
      state.permissionsTestCenters = data;
    },
    'SET_PERMISSIONS_CATEGORIES' (state, data) {
      state.permissionsCategories = data;
    },
    'SET_PERMISSIONS_FETCHED' (state, status) {
      state.permissionsIsFetched = status;
    }
  },
  actions: {
    /** getPermissions
     * @param params ()
     * @returns {Promise<unknown>}
     */
    getPermissions: async ({ commit, rootState, dispatch }) => {
      commit('SET_PERMISSIONS_FETCHED', false);
      try {
        const response = await PermissionsApi.getPermissions(rootState.user.userSpace);
        const { data, status } = response;
        if (status === 200) {
          await dispatch('setPermissions', data);
          commit('SET_PERMISSIONS_FETCHED', true);
        }
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    /**
     * setPermissions
     * @param {Object} data
     */
    setPermissions: ({ commit, rootState }, data) => {
      const { permissions: { legislator, test_center_owner, assessor } } = data;
      // Set permissions based on 'userSpace'
      // const permissionsObject = rootState.user.userSpace === userSpaces.legislator ? legislator : test_center_owner;
      const permissionsObject = rootState.user.userSpace === userSpaces.legislator
        ? legislator
        : rootState.user.userSpace === userSpaces.test_center_owner
          ? test_center_owner
          : assessor;
      const { labors, payment, test_centers } = permissionsObject;
      const { country, categories } = data;

      commit('SET_PERMISSIONS', permissionsObject);
      commit('SET_PERMISSIONS_COUNTRY', country);
      commit('SET_PERMISSIONS_LABORS', labors);
      commit('SET_PERMISSIONS_PAYMENT', payment);
      commit('SET_PERMISSIONS_TEST_CENTERS', test_centers);
      commit('SET_PERMISSIONS_CATEGORIES', categories);
    },

    clearPermissions: ({ commit }) => {
      commit('SET_PERMISSIONS', {});
      commit('SET_PERMISSIONS_COUNTRY', {});
      commit('SET_PERMISSIONS_LABORS', {});
      commit('SET_PERMISSIONS_PAYMENT', {});
      commit('SET_PERMISSIONS_TEST_CENTERS', {});
      commit('SET_PERMISSIONS_CATEGORIES', []);
      commit('SET_PERMISSIONS_FETCHED', false);
    }
  }
};
export default module;
