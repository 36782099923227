<template>
  <svp-layout :aside-is-loading="!permissionsIsFetched" :nav-items="navList" :useAside="useAside">
    <router-view/>
  </svp-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getPermittedPath } from '@/services/userPermissions';
import SvpLayout from '@/app/shared/components/appShell/layout/SvpLayout.vue';
import IconUpload from '@/app/shared/components/appShell/icons/IconUpload.vue';
import IconUser from '@/app/shared/components/appShell/icons/IconUser.vue';
import IconUserFolder from '@/app/shared/components/appShell/icons/IconUserFolder.vue';
import IconCase from '@/app/shared/components/appShell/icons/IconCase.vue';
import IconTransaction from '@/app/shared/components/appShell/icons/IconTransaction.vue';
import IconRosette from '@/app/shared/components/appShell/icons/IconRosette.vue';
import { isFeatureAvailable } from '@/utils/featureFlags';
import IconCreditCard from '@/app/shared/components/appShell/icons/IconCreditCard.vue';

export default {
  name: 'TestCenterLayout',
  components: {
    SvpLayout
  },
  data () {
    return {
      pageTimeOut: 0,
      navItems: []
    };
  },
  computed: {
    ...mapGetters([
      'permissionsObject',
      'permissionsLabors',
      'permissionsPayment',
      'permissionsIsFetched',
      'permissionsTestCenters',
      'getIsTestCenterOwner'
    ]),
    isHideSessionsReportForLegislatorActiveFF () {
      return isFeatureAvailable('legislator_view_sessions_and_reservations');
    },
    showAssessorsPageForTestCenterFF () {
      return isFeatureAvailable('tc_view_assessors_list');
    },
    showAssessorsPageForLegislatorFF () {
      return isFeatureAvailable('legislator_view_assessors_list');
    },
    tcStartExamFF () {
      return isFeatureAvailable('tc_start_exam');
    },
    useAside () {
      return this.$route.meta?.showAside;
    },
    navList () {
      const navItems = [];
      const addNavItem = (icon, descriptionPath, routeName) => {
        navItems.push({ icon, descriptionPath, routeName });
      };

      if (this.permissionsLabors.view_uploaded_files) {
        addNavItem(IconUpload, 'nav.uploadedFiles', 'TestCenterUploadPage');
      }
      if (this.permissionsLabors.view) {
        addNavItem(IconUser, 'nav.labors', 'TestCenterLaborsPage');
      }
      if (this.getIsTestCenterOwner && this.showAssessorsPageForTestCenterFF) {
        addNavItem(IconUserFolder, 'assessors', 'TestCenterAssessorsPage');
      }
      if (this.permissionsPayment.make_payment) {
        addNavItem(IconCreditCard, 'nav.payment', 'TestCenterPaymentTablePage');
      }
      if (this.permissionsTestCenters.view) {
        addNavItem(IconCase, 'nav.testCenters', 'TestCenterTestCentersList');
      }
      if (this.permissionsPayment.view_transaction_history) {
        addNavItem(IconTransaction, 'nav.transactionHistory', 'TestCenterTransactionHistoryPage');
        addNavItem(IconTransaction, 'nav.reports', 'TestCenterReports');
      }
      if (this.getIsTestCenterOwner) {
        addNavItem(IconTransaction, 'nav.examSessions', 'TestCenterExamSessions');
        addNavItem(IconRosette, 'nav.sessionsManagement', 'TestCenterSessionsManagement');
      }
      return navItems;
    }
  },
  methods: {
    ...mapActions(['SET_PAGE_LOADING', 'getCertificatePrice']),
    /**
     * @param {Function} cbFunction callback function
     * @param arguments You can pass arguments after 'cbFunction'
     * call example: fnWrapperWithLoader(cbFunction, obj, arr, str)
     */

    // why all this logic exist, what reason?
    fnWrapperWithLoader (cbFunction) {
      const args = Array.prototype.splice.call(arguments, 1);

      try {
        clearTimeout(this.pageTimeOut);

        this.SET_PAGE_LOADING(true); // Show loader

        cbFunction(...args);
      } catch (err) {
        console.error(err);
      }

      this.pageTimeOut = setTimeout(() => {
        this.SET_PAGE_LOADING(false); // Hide loader
      }, 1000);
    },

    redirectBasedOnPermissions (permissionsObject) {
      const { path, meta: { withoutPermissions } } = this.$route;
      const redirectPath = getPermittedPath(permissionsObject, path);
      if (redirectPath !== path && !withoutPermissions) {
        this.$router.push({ path: redirectPath }).catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
      }
    }
  },
  watch: {
    permissionsObject (val) {
      this.fnWrapperWithLoader(this.redirectBasedOnPermissions, val);
    }
  },
  async created () {
    try {
      await this.getCertificatePrice();
    } catch (error) {
      console.log(error.response);
    }
  }
};
</script>
