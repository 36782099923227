import { lazyLoading } from '@/app/shared/router/utils';
import LaborLayout from '@/app/labor/layout/LaborLayout.vue';
import { isUserLabor } from '@/app/labor/routes/utils';
import store from '@/store';
import { isFeatureAvailable } from '@/utils/featureFlags';

export const laborRoutes = [
  {
    path: '/labor',
    component: LaborLayout,
    meta: {
      auth: true
    },
    beforeEnter (to, from, next) {
      isUserLabor(to, from, next);
    },
    children: [
      {
        path: 'account-dashboard',
        component: lazyLoading('labor', 'AccountDashboard'),
        name: 'AccountDashboard'
      },
      {
        path: '',
        component: lazyLoading('labor', 'BookingPage'),
        name: 'BookingPage',
        children: [
          {
            path: 'booking',
            name: 'Booking',
            meta: { auth: true },
            component: lazyLoading('labor', 'Booking'),
            async beforeEnter (to, from, next) {
              await store.dispatch('getFeatureFlagsConfiguration');
              // regarding ticket PVPE-4976 (Enhance Booking page for labors)
              if (!isFeatureAvailable('use_new_booking')) next({ name: 'BookingOld' });
              else next();
            }
          },
          {
            path: 'booking',
            name: 'BookingOld',
            component: lazyLoading('labor', 'BookingOld'),
            async beforeEnter (to, from, next) {
              await store.dispatch('getFeatureFlagsConfiguration');
              // regarding ticket PVPE-4976 (Enhance Booking page for labors)
              if (isFeatureAvailable('use_new_booking')) next({ name: 'Booking' });
              else next();
            }
          },
          {
            path: 'booking/steps',
            component: lazyLoading('labor', 'BookingAppointment'),
            name: 'BookingAppointment',
            meta: {
              // name should be from message.js i18n
              breadcrumb: [
                { name: 'labor.nav.booking', link: 'Booking' },
                { name: 'labor.nav.bookAnAppointment', link: 'BookingAppointment' }
              ]
            },
            async beforeEnter (to, from, next) {
              await store.dispatch('getFeatureFlagsConfiguration');
              // regarding ticket PVPE-4977 (Edit 1st step of book an appointment flow)
              if (!isFeatureAvailable('use_new_booking')) next({ name: 'BookingStepsOld' });
              else next();
            }
          },
          {
            path: 'booking/steps',
            component: lazyLoading('labor', 'BookingStepsOld'),
            name: 'BookingStepsOld',
            async beforeEnter (to, from, next) {
              await store.dispatch('getFeatureFlagsConfiguration');
              // regarding ticket PVPE-4977 (Edit 1st step of book an appointment flow)
              if (isFeatureAvailable('use_new_booking')) next({ name: 'BookingAppointment' });
              else next();
            }
          },
          {
            path: 'reschedule/steps',
            component: lazyLoading('labor', 'RescheduleAppointment'),
            name: 'RescheduleAppointment',
            meta: {
              // name should be from message.js i18n
              breadcrumb: [
                { name: 'labor.nav.booking', link: 'Booking' },
                { name: 'labor.nav.rescheduleAppointment', link: 'RescheduleAppointment' }
              ]
            },
            async beforeEnter (to, from, next) {
              if (to.query.reservationId) {
                next();
              } else {
                next({ name: 'Booking' });
              }
            }
          },
          {
            path: 'booking/payment',
            component: lazyLoading('labor', 'payment/PaymentPage'),
            children: [
              {
                path: 'steps',
                component: lazyLoading('labor', 'payment/PaymentSteps'),
                name: 'LaborPaymentSteps'
              }
            ]
          }
        ]
      },
      {
        path: 'transaction-history',
        component: lazyLoading('labor', 'transactions/TransactionsHistoryList'),
        name: 'LaborTransactionHistory'
      },
      {
        path: 'booking/summary',
        component: lazyLoading('labor', 'Summary'),
        name: 'Summary',
        meta: { showAside: false },
        props: true
      },
      {
        path: 'booking/:id',
        component: lazyLoading('labor', 'BookingDetails'),
        name: 'BookingDetails',
        props: true,
        meta: {
          showAside: false,
          breadcrumb: [
            // name should be from message.js i18n
            { name: 'labor.bookingDetails.bookedAppointments', link: 'Booking' }
          ]
        }
      },
      {
        path: 'free-booking-confirmation',
        props: true,
        component: lazyLoading('labor', 'FreeBookingSuccessMsg'),
        name: 'FreeBookingSuccessMsg'
      },
      {
        path: 'confirmation',
        props: true,
        component: lazyLoading('labor', 'payment/PaymentSessionConfirmation'),
        meta: { showAside: false },
        name: 'PaymentSessionConfirmation'
      },
      { path: '*', redirect: { name: 'Booking' } }
    ]
  }
];
