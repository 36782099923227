import countriesQuery from 'countries-code/countries_code';
import CountriesApi from '@/api/countries.api';

const module = {
  state: {
    countryList: [],
    phoneCountryList: [
      ['', '', '', ''],
      ['أفغانستان', 'Afghanistan (‫افغانستان‬‎)', 'af', '93'],
      ['ألبانيا', 'Albania (Shqipëri)', 'al', '355'],
      ['الجزائر', 'Algeria (‫الجزائر‬‎)', 'dz', '213'],
      ['ساموا الأمريكية', 'American Samoa', 'as', '1684'],
      ['اندورا', 'Andorra', 'ad', '376'],
      ['أنجولا', 'Angola', 'ao', '244'],
      ['أنجويلا', 'Anguilla', 'ai', '1264'],
      ['انتيجوا و باربودا', 'Antigua and Barbuda', 'ag', '1268'],
      ['الارجنتين', 'Argentina', 'ar', '54'],
      ['ارمينيا', 'Armenia (Հայաստան)', 'am', '374'],
      ['آروبا', 'Aruba', 'aw', '297'],
      ['استراليا', 'Australia', 'au', '61', 0],
      ['النمسا', 'Austria (Österreich)', 'at', '43'],
      ['اذربيجان', 'Azerbaijan (Azərbaycan)', 'az', '994'],
      ['جزر البهاما', 'Bahamas', 'bs', '1242'],
      ['البحرين', 'Bahrain (‫البحرين‬‎)', 'bh', '973'],
      ['بنجلاديش', 'Bangladesh (বাংলাদেশ)', 'bd', '880'],
      ['باربادوس', 'Barbados', 'bb', '1246'],
      ['بيلاروس', 'Belarus (Беларусь)', 'by', '375'],
      ['بلجيكا', 'Belgium (België)', 'be', '32'],
      ['بليز', 'Belize', 'bz', '501'],
      ['بنين', 'Benin (Bénin)', 'bj', '229'],
      ['برمودا', 'Bermuda', 'bm', '1441'],
      ['بوتان', 'Bhutan (འབྲུག)', 'bt', '975'],
      ['بوليفيا', 'Bolivia', 'bo', '591'],
      ['البوسنة', 'Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
      ['بوتسوانا', 'Botswana', 'bw', '267'],
      ['البرازيل', 'Brazil (Brasil)', 'br', '55'],
      ['المقاطعة البريطانية في المحيط الهندي', 'British Indian Ocean Territory', 'io', '246'],
      ['جزر فرجين', 'British Virgin Islands', 'vg', '1284'],
      ['بروناى', 'Brunei', 'bn', '673'],
      ['بلغاريا', 'Bulgaria (България)', 'bg', '359'],
      ['بوركينا', 'Burkina Faso', 'bf', '226'],
      ['بوروندى', 'Burundi (Uburundi)', 'bi', '257'],
      ['كامبوديا', 'Cambodia (កម្ពុជា)', 'kh', '855'],
      ['الكاميرون', 'Cameroon (Cameroun)', 'cm', '237'],
      ['كندا', 'Canada', 'ca', '1', 1, ['204', '226', '236', '249', '250', '289', '306', '343', '365', '387', '403', '416', '418', '431', '437', '438', '450', '506', '514', '519', '548', '579', '581', '587', '604', '613', '639', '647', '672', '705', '709', '742', '778', '780', '782', '807', '819', '825', '867', '873', '902', '905']],
      ['كاب فيرد', 'Cape Verde (Kabu Verdi)', 'cv', '238'],
      ['هولندا الكاريبية', 'Caribbean Netherlands', 'bq', '599', 1],
      ['جزر كايمان', 'Cayman Islands', 'ky', '1345'],
      ['جمهورية وسط أفريقيا', 'Central African Republic (République centrafricaine)', 'cf', '236'],
      ['تشاد', 'Chad (Tchad)', 'td', '235'],
      ['تشيلي', 'Chile', 'cl', '56'],
      ['الصين', 'China (中国)', 'cn', '86'],
      ['جزيرة كريسماس', 'Christmas Island', 'cx', '61', 2],
      ['جزر كوكوس', 'Cocos (Keeling) Islands', 'cc', '61', 1],
      ['كولومبيا', 'Colombia', 'co', '57'],
      ['جزر القمر', 'Comoros (‫جزر القمر‬‎)', 'km', '269'],
      ['جمهورة  الكنغو الديمقراطية', 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '243'],
      ['الكنغو', 'Congo (Republic) (Congo-Brazzaville)', 'cg', '242'],
      ['جزر كوك', 'Cook Islands', 'ck', '682'],
      ['كوستاريكا', 'Costa Rica', 'cr', '506'],
      ['كوت دي فوار', 'Côte d’Ivoire', 'ci', '225'],
      ['كرواتيا', 'Croatia (Hrvatska)', 'hr', '385'],
      ['كوبا', 'Cuba', 'cu', '53'],
      ['كوراساو', 'Curaçao', 'cw', '599', 0],
      ['قبرص', 'Cyprus (Κύπρος)', 'cy', '357'],
      ['جمهورية التشيك', 'Czech Republic (Česká republika)', 'cz', '420'],
      ['الدنمارك', 'Denmark (Danmark)', 'dk', '45'],
      ['جيبوتى', 'Djibouti', 'dj', '253'],
      ['الدمنيكان', 'Dominica', 'dm', '1767'],
      ['جمهورية الدمنيكان', 'Dominican Republic (República Dominicana)', 'do', '1', 2, ['809', '829', '849']],
      ['الاكوادور', 'Ecuador', 'ec', '593'],
      ['مصر', 'Egypt (‫مصر‬‎)', 'eg', '20'],
      ['السلفادور', 'El Salvador', 'sv', '503'],
      ['غينيا الأستوائية', 'Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240'],
      ['أريتيريا', 'Eritrea', 'er', '291'],
      ['استونيا', 'Estonia (Eesti)', 'ee', '372'],
      ['إثيوبيــــا', 'Ethiopia', 'et', '251'],
      ['جزر - فوكلاند', 'Falkland Islands (Islas Malvinas)', 'fk', '500'],
      ['جزر فاروه', 'Faroe Islands (Føroyar)', 'fo', '298'],
      ['فيجــــــى', 'Fiji', 'fj', '679'],
      ['فنلاندا', 'Finland (Suomi)', 'fi', '358', 0],
      ['فرنسا', 'France', 'fr', '33'],
      ['جوانا الفرنسية', 'French Guiana (Guyane française)', 'gf', '594'],
      ['بولينيزيا الفرنسية', 'French Polynesia (Polynésie française)', 'pf', '689'],
      ['الجابون', 'Gabon', 'ga', '241'],
      ['جامبيا', 'Gambia', 'gm', '220'],
      ['جورجيا', 'Georgia (საქართველო)', 'ge', '995'],
      ['ألمانيا', 'Germany (Deutschland)', 'de', '49'],
      ['غانـا', 'Ghana (Gaana)', 'gh', '233'],
      ['جبل طــــارق', 'Gibraltar', 'gi', '350'],
      ['اليونان', 'Greece (Ελλάδα)', 'gr', '30'],
      ['جرين لاند', 'Greenland (Kalaallit Nunaat)', 'gl', '299'],
      ['جرينادا', 'Grenada', 'gd', '1473'],
      ['جواد يلوب', 'Guadeloupe', 'gp', '590', 0],
      ['جوام', 'Guam', 'gu', '1671'],
      ['جواتيمالا', 'Guatemala', 'gt', '502'],
      ['غيرنسي', 'Guernsey', 'gg', '44', 1],
      ['غينيا', 'Guinea (Guinée)', 'gn', '224'],
      ['غينيا بيساو', 'Guinea-Bissau (Guiné Bissau)', 'gw', '245'],
      ['جويانا', 'Guyana', 'gy', '592'],
      ['هايتى', 'Haiti', 'ht', '509'],
      ['هندوراس', 'Honduras', 'hn', '504'],
      ['هونج كونج', 'Hong Kong (香港)', 'hk', '852'],
      ['المجر', 'Hungary (Magyarország)', 'hu', '36'],
      ['ايسلاندا', 'Iceland (Ísland)', 'is', '354'],
      ['الهند', 'India (भारत)', 'in', '91'],
      ['إندونسيا', 'Indonesia', 'id', '62'],
      ['ايران', 'Iran (‫ایران‬‎)', 'ir', '98'],
      ['العراق', 'Iraq (‫العراق‬‎)', 'iq', '964'],
      ['ايرلاندا', 'Ireland', 'ie', '353'],
      ['جزيرة آيل أوف مان', 'Isle of Man', 'im', '44', 2],
      ['إسرائيل', 'Israel (‫ישראל‬‎)', 'il', '972'],
      ['ايطاليا', 'Italy (Italia)', 'it', '39', 0],
      ['جامايكا', 'Jamaica', 'jm', '1876'],
      ['اليابان', 'Japan (日本)', 'jp', '81'],
      ['جيرسي', 'Jersey', 'je', '44', 3],
      ['الاردن', 'Jordan (‫الأردن‬‎)', 'jo', '962'],
      ['كاذاخستان', 'Kazakhstan (Казахстан)', 'kz', '7', 1],
      ['كينيا', 'Kenya', 'ke', '254'],
      ['كيريباتيا', 'Kiribati', 'ki', '686'],
      ['كوسوفو', 'Kosovo', 'xk', '383'],
      ['الكويت', 'Kuwait (‫الكويت‬‎)', 'kw', '965'],
      ['قرغيزستان', 'Kyrgyzstan (Кыргызстан)', 'kg', '996'],
      ['لاوس', 'Laos (ລາວ)', 'la', '856'],
      ['لاتفيا', 'Latvia (Latvija)', 'lv', '371'],
      ['لبنان', 'Lebanon (‫لبنان‬‎)', 'lb', '961'],
      ['ليسوتو', 'Lesotho', 'ls', '266'],
      ['ليبيريا', 'Liberia', 'lr', '231'],
      ['ليبيا', 'Libya (‫ليبيا‬‎)', 'ly', '218'],
      ['ليختشتاين', 'Liechtenstein', 'li', '423'],
      ['لتوانيا', 'Lithuania (Lietuva)', 'lt', '370'],
      ['لوكسمبورج', 'Luxembourg', 'lu', '352'],
      ['ماكاو الصين', 'Macau (澳門)', 'mo', '853'],
      ['ماكيدونيا', 'Macedonia (FYROM) (Македонија)', 'mk', '389'],
      ['مدغـشقر', 'Madagascar (Madagasikara)', 'mg', '261'],
      ['مالاوى', 'Malawi', 'mw', '265'],
      ['ماليزيا', 'Malaysia', 'my', '60'],
      ['المالديف', 'Maldives', 'mv', '960'],
      ['مالى', 'Mali', 'ml', '223'],
      ['مالطا', 'Malta', 'mt', '356'],
      ['جزر مارشال', 'Marshall Islands', 'mh', '692'],
      ['المارتينك', 'Martinique', 'mq', '596'],
      ['موريتانيا', 'Mauritania (‫موريتانيا‬‎)', 'mr', '222'],
      ['موريشيوس', 'Mauritius (Moris)', 'mu', '230'],
      ['مايوت', 'Mayotte', 'yt', '262', 1],
      ['المكسيك‏', 'Mexico (México)', 'mx', '52'],
      ['ولاية مكرونسيا الأتحادية', 'Micronesia', 'fm', '691'],
      ['جمهورية مالدوفا', 'Moldova (Republica Moldova)', 'md', '373'],
      ['موناكو', 'Monaco', 'mc', '377'],
      ['منغوليا', 'Mongolia (Монгол)', 'mn', '976'],
      ['الجبل الاسود', 'Montenegro (Crna Gora)', 'me', '382'],
      ['مونتسرات', 'Montserrat', 'ms', '1664'],
      ['المغرب', 'Morocco (‫المغرب‬‎)', 'ma', '212', 0],
      ['موزمبيق', 'Mozambique (Moçambique)', 'mz', '258'],
      ['مينمار', 'Myanmar (Burma) (မြန်မာ)', 'mm', '95'],
      ['نامبيا', 'Namibia (Namibië)', 'na', '264'],
      ['ناورو', 'Nauru', 'nr', '674'],
      ['نيبال', 'Nepal (नेपाल)', 'np', '977'],
      ['هولندا', 'Netherlands (Nederland)', 'nl', '31'],
      ['نيو - كاليدونيا', 'New Caledonia (Nouvelle-Calédonie)', 'nc', '687'],
      ['نيوزيلاند', 'New Zealand', 'nz', '64'],
      ['نيكاراجوا', 'Nicaragua', 'ni', '505'],
      ['النيجر', 'Niger (Nijar)', 'ne', '227'],
      ['نيجيريا', 'Nigeria', 'ng', '234'],
      ['نيوى جزر', 'Niue', 'nu', '683'],
      ['جزيرة نورفولك', 'Norfolk Island', 'nf', '672'],
      ['كوريا الشمالية', 'North Korea (조선 민주주의 인민 공화국)', 'kp', '850'],
      ['جزر ماريانا الشمالية', 'Northern Mariana Islands', 'mp', '1670'],
      ['النرويج', 'Norway (Norge)', 'no', '47', 0],
      ['عمان', 'Oman (‫عُمان‬‎)', 'om', '968'],
      ['باكستان', 'Pakistan (‫پاکستان‬‎)', 'pk', '92'],
      ['بالو', 'Palau', 'pw', '680'],
      ['فلسطين', 'Palestine (‫فلسطين‬‎)', 'ps', '970'],
      ['بنما', 'Panama (Panamá)', 'pa', '507'],
      ['بابوا - نيو - جونييا', 'Papua New Guinea', 'pg', '675'],
      ['باراجواى', 'Paraguay', 'py', '595'],
      ['بيرو', 'Peru (Perú)', 'pe', '51'],
      ['الفلبين', 'Philippines', 'ph', '63'],
      ['بولندا', 'Poland (Polska)', 'pl', '48'],
      ['البرتغال', 'Portugal', 'pt', '351'],
      ['بورتوريكو', 'Puerto Rico', 'pr', '1', 3, ['787', '939']],
      ['قطر', 'Qatar (‫قطر‬‎)', 'qa', '974'],
      ['رينو', 'Réunion (La Réunion)', 're', '262', 0],
      ['رومانيا', 'Romania (România)', 'ro', '40'],
      ['روسيا الإتحادية', 'Russia (Россия)', 'ru', '7', 0],
      ['روانــــــــــدا', 'Rwanda', 'rw', '250'],
      ['القديس بارثولوميو', 'Saint Barthélemy', 'bl', '590', 1],
      ['هيلينا', 'Saint Helena', 'sh', '290'],
      ['سانت كيتس و نافيس', 'Saint Kitts and Nevis', 'kn', '1869'],
      ['ســانت لوتشــيا', 'Saint Lucia', 'lc', '1758'],
      ['القديس مارتن', 'Saint Martin (Saint-Martin (partie française))', 'mf', '590', 2],
      ['سانت بيير و ميجولين', 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
      ['سانت فينسنت و جرينادين', 'Saint Vincent and the Grenadines', 'vc', '1784'],
      ['ساموا', 'Samoa', 'ws', '685'],
      ['سان مارينو', 'San Marino', 'sm', '378'],
      ['ساو - توم - وبرنسيب', 'São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239'],
      ['المملكة العربية السعودية', 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '966'],
      ['السنغال', 'Senegal (Sénégal)', 'sn', '221'],
      ['صربيا', 'Serbia (Србија)', 'rs', '381'],
      ['سيشل', 'Seychelles', 'sc', '248'],
      ['سيراليون', 'Sierra Leone', 'sl', '232'],
      ['سنغافورة', 'Singapore', 'sg', '65'],
      ['سانت مارتن', 'Sint Maarten', 'sx', '1721'],
      ['جمهورية سلوفاكيا', 'Slovakia (Slovensko)', 'sk', '421'],
      ['سلوفينيا', 'Slovenia (Slovenija)', 'si', '386'],
      ['جزر سليمــــان', 'Solomon Islands', 'sb', '677'],
      ['الصومال', 'Somalia (Soomaaliya)', 'so', '252'],
      ['جنوب أفريقيا', 'South Africa', 'za', '27'],
      ['كوريا الجنــوبية', 'South Korea (대한민국)', 'kr', '82'],
      ['جمهورية جنوب السودان', 'South Sudan (‫جنوب السودان‬‎)', 'ss', '211'],
      ['أسبانيا', 'Spain (España)', 'es', '34'],
      ['سريلانكا', 'Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94'],
      ['السودان', 'Sudan (‫السودان‬‎)', 'sd', '249'],
      ['سورينام', 'Suriname', 'sr', '597'],
      ['سفلبارد', 'Svalbard and Jan Mayen', 'sj', '47', 1],
      ['سوازيـلانـــــــــد', 'Swaziland', 'sz', '268'],
      ['السويد', 'Sweden (Sverige)', 'se', '46'],
      ['سويسرا', 'Switzerland (Schweiz)', 'ch', '41'],
      ['سوريا', 'Syria (‫سوريا‬‎)', 'sy', '963'],
      ['تايوان الصين', 'Taiwan (台灣)', 'tw', '886'],
      ['طاجكستان', 'Tajikistan', 'tj', '992'],
      ['تنزانيا', 'Tanzania', 'tz', '255'],
      ['تايلاند', 'Thailand (ไทย)', 'th', '66'],
      ['تيمور ليشتي', 'Timor-Leste', 'tl', '670'],
      ['توجو', 'Togo', 'tg', '228'],
      ['توكيلاو', 'Tokelau', 'tk', '690'],
      ['تونجــا', 'Tonga', 'to', '676'],
      ['ترينيداد وتوباجو', 'Trinidad and Tobago', 'tt', '1868'],
      ['تونس', 'Tunisia (‫تونس‬‎)', 'tn', '216'],
      ['تركيا', 'Turkey (Türkiye)', 'tr', '90'],
      ['تركمنستان', 'Turkmenistan', 'tm', '993'],
      ['جزر تركس وكايكوس', 'Turks and Caicos Islands', 'tc', '1649'],
      ['توفالــــــــو', 'Tuvalu', 'tv', '688'],
      ['جزر فيرجينيا', 'U.S. Virgin Islands', 'vi', '1340'],
      ['أوغندا', 'Uganda', 'ug', '256'],
      ['اكرانيا', 'Ukraine (Україна)', 'ua', '380'],
      ['الإمارات العربية المتحدة', 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '971'],
      ['المملكة المتحدة', 'United Kingdom', 'gb', '44', 0],
      ['الولايات المتحدة الامريكية', 'United States', 'us', '1', 0],
      ['أورجواى', 'Uruguay', 'uy', '598'],
      ['اوزبكستان', 'Uzbekistan (Oʻzbekiston)', 'uz', '998'],
      ['فانواتو', 'Vanuatu', 'vu', '678'],
      ['الفاتيكان', 'Vatican City (Città del Vaticano)', 'va', '39', 1],
      ['فنزويلا', 'Venezuela', 've', '58'],
      ['فييتنام', 'Vietnam (Việt Nam)', 'vn', '84'],
      ['واليز فوتونا', 'Wallis and Futuna (Wallis-et-Futuna)', 'wf', '681'],
      ['الصحراء الغربية', 'Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '212', 1],
      ['الجمهورية اليمنية', 'Yemen (‫اليمن‬‎)', 'ye', '967'],
      ['زامبيا', 'Zambia', 'zm', '260'],
      ['زيمبابوى', 'Zimbabwe', 'zw', '263'],
      ['جزر آلاند', 'Åland Islands', 'ax', '358', 1]]
  },
  getters: {
    countryList: state => state.countryList,
    phoneCountryList: (state, rootState) => {
      return state.phoneCountryList.map((country) => {
        return {
          name: rootState.lang === 'ar' ? `${country[0]}` : country[1],
          iso2: country[2].toUpperCase(),
          dialCode: country[3],
          priority: country[4] || 0,
          areaCodes: country[5] || null
        };
      });
    },
    countryListTranslated: (state) => {
      return state.countryList.map((country) => {
        return {
          ...country,
          name: state.lang === 'ar' ? country.arabic_name : country.english_name
        };
      });
    }
  },
  mutations: {
    'SET_COUNTRY_LIST' (state, countryList) {
      // Set 'Alpha-2 code' for each country
      const list = countryList.map((item) => {
        if (item.country_code === 'ROM') item.country_code = 'ROU'; // Fix Alpha-3 for Romania

        const alphaCode = countriesQuery.convertAlphaCode(item.country_code); // Get Alpha-2 code based on Alpha-3 code
        item.alpha2_code = alphaCode.toLowerCase() !== 'wrong country code input' ? alphaCode : null;
        return item;
      });
      state.countryList = list;
    }
  },
  actions: {
    /** getCountryList
     * @param params ()
     * @returns {Promise<unknown>}
     */
    async getCountryList ({ commit, rootState }, params) {
      try {
        const response = await CountriesApi.getCountryList(rootState.user.userSpace, params);
        const { countries } = response.data;
        await commit('SET_COUNTRY_LIST', countries);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    /** getCountry
     * @param params ()
     * @returns {Promise<unknown>}
     * @param id
     */
    getCountryListById: async ({ commit, rootState }, id) => {
      return await CountriesApi.getCountryById(rootState.user.userSpace, id);
    },
    /** getNationalities
     * @param params ()
     * @returns {Promise<unknown>}
     * @param id
     */
    getNationalities: async ({ commit, rootState }, params) => {
      return await CountriesApi.getNationalities(rootState.user.userSpace, params);
    },
    /** getCitiesByCountry
     * @param params ()
     * @returns {Promise<unknown>}
     * @param id
     */
    getCitiesByCountry: async ({ commit }, country) => {
      return await CountriesApi.getCitiesByCountry(country);
    }
  }
};

export default module;
