import NotificationsApi from '@/api/notifications.api';

const module = {
  state: {
    notifications_list: [],
    not_read_count: 0
  },
  getters: {
    notifications_list: (state) => state.notifications_list,
    not_read_count: (state) => state.not_read_count
  },
  actions: {
    getNotifications: async ({ commit, rootState }, params) => {
      try {
        const user_id = await JSON.parse(localStorage.getItem('user')).id; // Get user id from LocalStorage
        const payload = {
          ...params,
          user_id
        };
        const response = await NotificationsApi.getNotifications(rootState.user.userSpace, payload);
        await commit('CONCAT_NOTIFICATIONS', response.data.notifications); // Set list of notifications
        await commit('SET_COUNTER', response.data.not_read_count); // Set counter of unread notificatons
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    setNotReadCount: ({ commit }, data) => {
      commit('NOT_READ_COUNT', data);
    },
    pushNewNotification: ({ commit }, data) => {
      commit('PUSH_NOTIFICATION', data);
    },
    concatWithNotifications: ({ commit }, data) => {
      commit('CONCAT_NOTIFICATIONS', data);
    },
    clearNotifications: ({ commit }) => {
      commit('CLEAR_NOTIFICATIONS');
    }
  },
  mutations: {
    NOT_READ_COUNT (state, data) {
      state.not_read_count = data;
    },
    SET_COUNTER (state, count) {
      state.not_read_count = count;
    },
    PUSH_NOTIFICATION (state, data) {
      state.notifications_list.unshift(data);
    },
    CONCAT_NOTIFICATIONS (state, data) {
      state.notifications_list = state.notifications_list.concat(data);
    },
    CLEAR_NOTIFICATIONS (state) {
      state.notifications_list = [];
    }
  }
};

export default module;
