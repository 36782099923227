import Gateway from '@/api/index';

class AccountApi {
  getAccountInfo = (url) => {
    return Gateway.get(`${url}/account`);
  }

  updateAccountInfo = (url, data) => {
    return Gateway.patch(`${url}/account`, data);
  }
}

export default new AccountApi();
