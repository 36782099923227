import AccountApi from '@/api/account.api';

const module = {
  state: {
    accountInfo: null
  },
  getters: {
    accountInfo: state => state.accountInfo
  },
  mutations: {
    'SET_ACCOUNT_INFO' (state, data) {
      if (data.logo_url) data.logo = `<img src="${data.logo_url}" alt="Logo" width="92">`;
      data.show_logo_text = data.show_logo ? 'Yes' : 'No';
      data.full_phone = data.country_code + data.phone_number;

      state.accountInfo = data;
    }
  },
  actions: {
    async getAccountInfo ({ commit, rootState }) {
      try {
        const response = await AccountApi.getAccountInfo(rootState.user.userSpace);
        const { data } = response;
        await commit('SET_ACCOUNT_INFO', data);
        return Promise.resolve(response);
      } catch (e) {
        await Promise.reject(e);
      }
    },
    async updateAccountInfo ({ commit, rootState }, payload) {
      try {
        const response = await AccountApi.updateAccountInfo(rootState.user.userSpace, payload);
        const { data } = response;
        commit('SET_ACCOUNT_INFO', data);
        return Promise.resolve(response);
      } catch (e) {
        await Promise.reject(e);
      }
    }
  }
};

export default module;
