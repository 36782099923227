
<template>
  <div class="svp-icon">
    <svg :width="width" :height="height" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.9 8L11.052 5.6L6.9 3.2V8ZM16.148 1.736C16.252 2.112 16.324 2.616 16.372 3.256C16.428 3.896 16.452 4.448 16.452 4.928L16.5 5.6C16.5 7.352 16.372 8.64 16.148 9.464C15.948 10.184 15.484 10.648 14.764 10.848C14.388 10.952 13.7 11.024 12.644 11.072C11.604 11.128 10.652 11.152 9.772 11.152L8.5 11.2C5.148 11.2 3.06 11.072 2.236 10.848C1.516 10.648 1.052 10.184 0.852 9.464C0.748 9.088 0.676 8.584 0.628 7.944C0.572 7.304 0.548 6.752 0.548 6.272L0.5 5.6C0.5 3.848 0.628 2.56 0.852 1.736C1.052 1.016 1.516 0.552 2.236 0.352C2.612 0.248 3.3 0.176 4.356 0.128C5.396 0.0719999 6.348 0.048 7.228 0.048L8.5 0C11.852 0 13.94 0.128 14.764 0.352C15.484 0.552 15.948 1.016 16.148 1.736Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconYoutube',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
