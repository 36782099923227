import VisitorLayout from '@/app/visitor/layout/VisitorLayout.vue';

import { lazyLoading } from '@/app/shared/router/utils';

export const visitorRoutes = [
  {
    path: '/visitor',
    component: VisitorLayout,
    children: [
      {
        path: '',
        component: lazyLoading('visitor', 'TestCenterSearch'),
        name: 'TestCenterSearch'
      },
      {
        path: 'privacy-policy',
        component: lazyLoading('visitor', 'PrivacyPolicyPage'),
        name: 'PrivacyPolicyPage'
      },
      {
        path: 'terms-of-use',
        component: lazyLoading('visitor', 'TermsOfUsePage'),
        name: 'TermsOfUsePage'
      },
      {
        path: 'about-us',
        component: lazyLoading('visitor', 'AboutUs'),
        name: 'AboutUs'
      },
      {
        path: 'knowledge',
        component: lazyLoading('visitor', 'knowledge/index'),
        children: [
          {
            path: '/',
            component: lazyLoading('visitor', 'knowledge/KnowledgeCenter'),
            name: 'KnowledgeCenter'
          },
          {
            path: 'faq',
            component: lazyLoading('visitor', 'knowledge/FAQ'),
            name: 'FAQ'
          },
          {
            path: 'occupation',
            component: lazyLoading('visitor', 'knowledge/Occupation'),
            name: 'Occupation',
            beforeEnter (to, from, next) {
              next({ name: 'KnowledgeCenter' });
            }
          }
        ]
      },
      {
        path: '*',
        redirect: { name: 'TestCenterSearch' }
      }
    ]
  }
];
