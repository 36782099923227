import { landingTranslations } from '@/app/landing/i18n/messages';
import { assessorTranslations } from '@/app/assessor/i18n/messages';
import { legislatorTranslations } from '@/app/legislator/i18n/messages';
import { laborTranslations } from '@/app/labor/i18n/messages';
import { authTranslations } from '@/app/auth/i18n/messages';
import { visitorTranslations } from '@/app/visitor/i18n/messages';
import { testCenterTranslations } from '@/app/testCenter/i18n/messages';

const messages = {
  en: {
    select: 'Select',
    codeWasSent: 'Code was sent',
    failed: 'Failed',
    years: 'years',
    male: 'Male',
    female: 'Female',
    ذكر: 'Male',
    انثى: 'Female',
    copyright: 'Copyright',
    allRightsReserved: 'All rights reserved',
    addAttachment: 'Add attachment',
    ticketStatus: {
      solved: 'Solved',
      open: 'Open',
      pending: 'In progress',
      new: 'New',
      closed: 'Closed'
    },
    label: {
      id: 'ID number',
      nationality: 'Nationality',
      gender: 'Gender',
      iqama_id: 'Iqama Number',
      dateOfBirth: 'Date of birth'
    },
    verificationExpired: 'Verification code expired',
    id: 'id',
    legislators: 'Legislators',
    newLegislator: 'New Legislator',
    editLegislator: 'Edit Legislator',
    legislatorsInformation: 'Legislators Information',
    englishName: 'English Name',
    arabicName: 'Arabic Name',
    country: 'Country',
    arabic: 'Arabic',
    english: 'English',
    selectCountry: 'Select Country',
    city: 'City',
    selectCity: 'Select City',
    address: 'Address',
    postalCode: 'Postal code',
    contactInformation: 'Contact information',
    name: 'Name',
    phoneNumber: 'Phone Number',
    email: 'Email',
    createLegislator: 'Create Legislator',
    createSuccessfully: 'Legislator has been create successfully',
    status: 'Status',
    actions: 'Actions',
    selectStatus: 'Select Status',
    cancel: 'Cancel',
    add: 'Add',
    edit: 'Edit',
    save: 'Save',
    back: 'Back',
    next: 'Next',
    payNow: 'Pay Now',
    agree: 'agree',
    send: 'Send',
    notifications: 'Notifications',
    newNotification: 'New Notification',
    invalidFile: 'Invalid File',
    invalidFileType: 'Invalid File Type',
    backToHomePage: 'Back to home page',
    backTo: 'Back to',
    pay: 'Pay',
    done: 'Done',
    paymentSuccess: 'Payment Successfully',
    deactivate: 'Deactivate',
    newTestCenter: 'New Test Center',
    editTestCenter: 'Edit Test Center',
    testCenterOwner: 'Test Center Owner',
    testCenterOwnerName: 'Test Center Owner Name',
    testCenterOwnerEmail: 'Test Center Owner Email',
    testCenterInformation: 'Test Center information',
    selectCategory: 'Select Category',
    supportedCategories: 'Supported Categories',
    supportedOccupations: 'Supported Occupations',
    buildingNumber: 'Building number',
    streetName: 'Street name',
    location: 'Location',
    clearFilter: 'Clear Filter',
    goToProgram: 'Go To Program',
    ok: 'Ok',
    listIsEmpty: 'List is empty',
    continue: 'Continue',
    apply: 'Apply',
    CNIC: 'CNIC',
    assessors: 'Assessors',
    relatedTCAssessors: 'Related TC Assessors',
    assessor: 'Assessor',
    approve: 'Approve',
    reject: 'Reject',
    uploadedLaborsResult: 'Uploaded Labors Results',
    registeredLaborsResults: 'Registered Labors Results',
    yes: 'Yes',
    no: 'No',
    startEndTime: 'Start/End Time',
    testTakersCount: 'Test Takers Count',
    number: 'Number',
    file: 'File',
    verify: 'Verify',
    free: 'Free',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    error: 'Error',
    unassignedSessions: 'Unassigned sessions',
    assignedSessionsItem: 'Assigned sessions',
    assessorNav: {
      basicInformation: 'Basic Information',
      otherDetails: 'Other Details'
    },
    assessorPage: {
      basicInformation: 'Basic information',
      testTakers: 'Test takers',
      practicalResults: 'Practical results',
      pendingPractical: 'Pending practical'
    },
    assessorTestTakers: {
      fullName: 'Full name',
      identityNumber: 'Identity number',
      reservationId: 'Reservation ID',
      occupation: 'Occupation',
      examResult: 'Exam result',
      status: 'Status'
    },
    assessorModals: {
      approveModalTitle: 'Are you sure you want to approve attendance for this session?',
      successApproveMessage: 'The session is successfully assigned to you. Please view the assigned sessions in the corresponding tab',
      duplicateErrorModalTitle: 'Duplicate Assessor Application',
      duplicateErrorModalSubTitle: 'The requested session already has an assigned assessor.',
      cancelModalTitle: 'Are you sure you want to reject attendance for this session?',
      successCancelMessage: 'Attendance for the session is successfully rejected'
    },
    messages: {
      cantBeEmpty: 'Can\'t be empty',
      postalCodeCantBeEmpty: 'Postal code can\'t be empty',
      streetCantBeEmpty: 'Street can\'t be empty',
      cityCantBeEmpty: 'City can\'t be empty',
      emailCantBeEmpty: 'Email can\'t be empty',
      nameCantBeEmpty: 'Name can\'t be empty',
      categoriesCantBeEmpty: 'At least one category must be selected',
      contactNumberCantBeEmpty: 'Contact number can\'t be empty',
      onlyEnglishCharacters: 'Only english characters',
      onlyArabicCharacters: 'Only arabic characters',
      сontainEnglishAndSpecialChar: 'Should contain English letters and special characters only',
      сontainEnglishCharAndNumber: 'Should contain English letters and Numbers only',
      сontainEnglishSpecialCharAndNumber: 'Should contain English letters, special characters and numbers only',
      digitsOnly: 'Should contain digits only',
      invalidEmail: 'Please enter a valid email',
      min5digits: 'Minimum 5 digits',
      max25digits: 'Maximum 25 digits',
      maxValue100: 'Shall not be greater than 100',
      maxLength25: 'Maximum 25 digits',
      minLength5: 'Minimum 5 digits'
    },
    errorMessages: {
      required: 'This field is required.',
      postalCodeCantBeEmpty: 'Postal code can\'t be empty',
      streetCantBeEmpty: 'Street can\'t be empty',
      cityCantBeEmpty: 'City can\'t be empty',
      emailCantBeEmpty: 'Email can\'t be empty',
      nameCantBeEmpty: 'Name can\'t be empty',
      categoriesCantBeEmpty: 'At least one category must be selected',
      occupationsCantBeEmpty: 'At least one occupation must be selected',
      contactNumberCantBeEmpty: 'Contact number can\'t be empty',
      englishChar: 'Only english characters',
      arabicChar: 'Only arabic characters',
      englishSpecChar: 'Should contain English letters and special characters only',
      englishCharAndNumbers: 'Should contain English letters and Numbers only',
      englishSpecCharAndNumbers: 'Should contain English letters, digits and special characters only',
      englishOrArabic: 'Should contain English or Arabic characters',
      numberChar: 'Should contain digits only',
      email: 'Please enter a valid email',
      maxValue100: 'Shall not be greater than 100',
      maxLength7: 'Maximum 7 digits',
      maxLength3: 'Maximum 3 characters',
      maxLength9: 'Maximum 9 characters',
      maxLength10: 'Maximum 10 digits',
      maxLength20: 'Maximum 20 digits',
      maxLength25: 'Maximum 25 digits',
      maxLength50: 'Maximum 50 digits',
      maxLength100: 'Maximum 100 digits',
      maxLength250: 'Maximum 250 digits',
      minLength5: 'Minimum 5 digits',
      minLength6: 'Minimum 6 digits',
      minLength7: 'Minimum 7 digits',
      minLength10: 'Minimum 10 digits',
      minLength2: 'Minimum 2 characters',
      minLength8: 'Minimum 8 characters',
      minLength9: 'Minimum 9 characters',
      issueCertificate: 'Labor with below info have an active certificate within the same category,  are you sure you want to proceed with issuing another certificate knowing that the amount is not refundable',
      somethingWentWrong: 'Something went wrong',
      beErrorOccured: 'An error has occurred. Please try again.',
      codeIsIncorrect: 'Invalid or expired verification code',
      showLaborsWithEmptyEmails: 'Show labor with empty emails only',
      characters820: '8-20 characters',
      upperCaseLetter: 'Uppercase letter',
      lowerCaseLetter: 'Lowercase letter',
      containsNumber: 'Number',
      specialChar: 'Special character',
      passportNumber: 'Should contain Min 1 character and Min 7 digits',
      passportNumberLetters: 'Passport number should start with 1 or 2 letters',
      passportNumberDigits: 'Passport number should contain minimum 5, maximum 9 numbers',
      passportNumberTotal: 'Passport number should contain maximum 9 digits',
      serialNumber: 'Invalid certificate number',
      sameAs: 'Password do not match',
      dateFormatIsInvalid: 'Invalid date',
      invalidAgeForWork: 'Allowed for registration age is between the ages of 15 and 70.',
      dateIsAfter: 'Date should be in the future',
      defaultFetchError: 'An error has occurred. Please try again.'
    },
    messagesAlert: {
      areYouSure: 'Are you sure?',
      createdTestCenter: 'Test Center has been created successfully',
      deletedTestCenter: 'Test center has been deleted successfully',
      updatedTestCenter: 'Test Center has been updated successfully',
      individualLaborAddSuccess: 'Individual has been add successfully',
      receiveEmail: 'You will receive list of certificates at your email address in a few minutes',
      leaveThisPage: 'The record has been modified, your changes will be discarded. Are you sure you want to leave this page?',
      leavePaymentPage: 'Are you sure you want to leave the payment page?',
      deleteTestCenter: 'By deleting the test center you are revoking this account from access PACC?',
      noEnoughBalance: 'There is no enough balance to issue the certificate',
      issueFreePaid: 'You can’t issue certificates for both credit types [Free & Paid] at the sometime',
      issueCertificates: 'Are your sure you want to issue {count} certificate/s?',
      orderCantBeRefunded: 'Order can’t be refunded after the payment',
      errorForAddIndividualLabor: 'Can\'t add a duplicated record for labor with passport number {passportNumber}',
      warningForAddIndividualLabor: 'The labor with passport No {passportNumber} has an existing record with different exam date',
      warningForAddLaborsGroup: 'Labors with shown passport numbers have an existing records with different exam date',
      errorForAddLaborsGroup: 'Can not upload labors with these passport numbers',
      accountInactive: 'The test center account was inactive',
      fileUploadSucces: 'File has been uploaded successfully'
    },
    successMessages: {
      success: 'Success',
      done: 'Done',
      accountUpdated: 'Account has been successfully updated'
    },
    sendEmailModal: {
      toAccount: 'To my account',
      toLabors: 'To labors',
      sendCertificateToEmails: 'Send certificate to emails'
    },
    labors: {
      editLabor: 'Edit Labor',
      editEmail: 'Edit Email',
      editEmailHint: 'Will be used to share the certificate ',
      labors: 'labors',
      listOfLabors: 'List Of Labors',
      filterLabors: 'Filter Labors',
      registeredLabors: 'Registered Labors',
      downloadCsv: 'Download CSV',
      laborsCounterTooltip: {
        all: 'The Count of All Labors Exam',
        pass: 'The Count of All Labors That Passed',
        fail: 'The Count of All Labors That Failed',
        pending: 'The Count of Unpaid Certificates',
        expired: 'The Count of Expired Certificates',
        rejected: 'The Count of Rejected Exam',
        reserved: 'The Count of All Labors That Reserved Exams'
      },
      counter: {
        all: 'All',
        pass: 'Pass',
        fail: 'Fail',
        pending: 'Pending',
        expired: 'Expired',
        rejected: 'Rejected',
        reserved: 'Reserved'
      },
      group: 'Group'
    },
    uploadSingleLabor: {
      addIndividual: 'Add Individual'
    },
    uploadGroup: {
      addGroup: 'Add Group',
      downloadCsvSample: 'Download CSV Sample',
      testCentersList: 'Test Centers List',
      categoryList: 'Category List',
      uploadFile: 'Upload File',
      noData: 'No Data Available',
      choseFile: 'Choose file',
      allowedFileType: 'Allowed file type: CSV',
      fileInfo: 'File Information',
      maxSizeErr: 'Max size %{sizeInKB} KB'
    },
    nationalId: 'National ID',
    laborName: 'Labor Name',
    passport: 'Passport',
    occupation: 'Occupation',
    examDate: 'Exam Date',
    testCenter: 'Test Center',
    score: 'Exam Result %',
    selectOccupation: 'Select Occupation',
    selectTestCenter: 'Select Test Center',
    fileName: 'File Name',
    numberPassedLabors: 'Number of Passed Labors',
    pricePerLabors: 'Price Per Labors',
    price: 'Price',
    noDataAvailable: 'No Data Available',
    individualInformation: 'Individual Information',
    warning: 'Warning',
    confirm: 'Confirm',
    privacyPageLink: 'Privacy Policy',
    termsOfUse: 'Terms Of Use',
    aboutUs: 'About Us',
    knowledgeCenter: 'Knowledge center',
    countries: 'Countries',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
    homePage: {
      signIn: 'Sign in'
    },
    nav: {
      uploadedFiles: 'Uploaded Files',
      labors: 'Labors',
      payment: 'Payment',
      testCenters: 'Test Centers',
      transactionHistory: 'Transaction History',
      reports: 'Reports',
      examSessions: 'Exam sessions',
      sessionsManagement: 'Sessions management',
      startExam: 'Start exam',
      sessionsReport: 'Sessions Report',
      unassignedSessions: 'Unassigned sessions'
    },
    tableMain: {
      fileId: 'File ID',
      uploadDate: 'Upload Date',
      uploadFile: 'Upload file',
      numberOfPassedLabors: 'Number of Passed Labors',
      numberOfLabors: 'Number of Labors',
      totalLabors: 'Total Labors',
      actions: 'Actions',
      searchResults: 'Search Results',
      prev: '<Prev',
      next: 'Next>',
      laborId: 'Labor ID',
      laborName: 'Labor Name',
      passportNumber: 'Passport Number',
      testCenter: 'Test Centers',
      category: 'Category',
      ownerName: 'Owner Name',
      examDate: 'Exam Date',
      examResult: 'Exam Result',
      email: 'Email',
      amount: 'Amount',
      totalAmount: 'Total Amount',
      testCenterId: 'ID',
      name: 'Name',
      city: 'City',
      testCenterOwner: 'Test Center Owner',
      status: 'Status',
      referenceNumber: 'Reference Number',
      date: 'Date',
      invoice: 'Invoice',
      report: 'Report',
      certificates: 'Certificates',
      id: 'ID',
      fileName: 'File Name',
      numberOfCertificates: 'Number Of Certificates',
      pricePerCertificate: 'Price Per Certificate',
      pricePerLabor: 'Price Per Labor',
      grandTotal: 'grandTotal',
      certificateExpirationDate: 'Certificate Expiration Date',
      categoryName: 'Category Name',
      delete: 'Delete',
      fullName: 'Full Name',
      rejectedReason: 'Rejected Reason',
      nationality: 'Nationality',
      send: 'Send',
      nationalId: 'National ID',
      noData: 'There are no data',
      cancel: 'Cancel',
      view: 'View',
      approve: 'Approve',
      groupId: 'Group No',
      registrationMethod: 'Registration method',
      self_registered: 'Self-Registered',
      tc_invited: 'TC-Invited',
      appliedOn: 'Applied On',
      score: 'Score',
      startDateAndTime: 'Start Date and Time',
      duration: 'Duration',
      noTestTakers: 'No test takers yet',
      sortBy: 'Sort by',
      order: 'Order',
      startDate: 'Start date',
      creationDate: 'Creation date',
      sessionId: 'Session ID',
      sessionDate: 'Session Date',
      startEndTime: 'Start/End Time'
    },
    reviewStates: {
      active: 'Active',
      approved: 'Approved',
      first_review: 'First Review',
      second_review: 'Second Review',
      rejected: 'Rejected',
      rejected_by_legislator: 'Rejected by legislator'
    },
    statuses: {
      passed: 'passed',
      pending: 'pending',
      failed: 'failed',
      expired: 'expired',
      rejected: 'rejected',
      active: 'active',
      inactive: 'inactive',
      preparedCheckout: 'prepared checkout',
      prepared_checkout: 'prepared checkout',
      success: 'success',
      reserved: 'reserved',
      completed: 'completed',
      canceled: 'canceled',
      withdrawn: 'withdrawn',
      scheduled: 'scheduled',
      in_progress: 'in progress',
      unpublished: 'unpublished',
      no_result_yet: 'no result yet',
      valid: 'Valid',
      assessor_withdrawn: 'Assessor withdrawn',
      drafted: 'Drafted'
    },
    payment: {
      payment: 'Payment',
      balance: 'Balance',
      credits: 'Credits',
      numberOfLabors: 'Number of Labors',
      transactionHistory: 'Transaction History',
      issue: 'Issue',
      getCredit: 'Get Credit',
      certificates: 'Certificates',
      unitPrice: 'Unit Price',
      total: 'Total',
      totalAmount: 'Total Amount',
      enterCardDetails: 'PLEASE ENTER YOUR CARD DETAILS TO A PURCHASE',
      transactionAmount: 'Transaction Amount'
    },
    paymentConfirmation: {
      paymentConfirmation: 'Payment Confirmation',
      grandTotal: 'grand total',
      transactionInformation: 'Transaction Information',
      certificates: 'Certificates',
      certificatesDownloadText: 'You can download list of certificates now',
      download: 'Download',
      sendEmail: 'Send Email'
    },
    testCenters: {
      testCenters: 'Test Centers',
      addTestCenter: 'Add Test Center',
      testCenterOwner: 'Test Center Owner',
      name: 'Name',
      generalInfo: 'General Info',
      generalInformation: 'General Information',
      testCenterInformation: 'Test Center Information',
      officialContactNumber: 'Official Contact Number',
      city: 'City',
      country: 'Country',
      streetName: 'Street Name',
      postalCode: 'Postal Code',
      supportedOccupations: 'Supported Occupations',
      email: 'Email',
      location: 'Location',
      selectCategory: 'Select Category',
      selectCountry: 'Select Country',
      selectOccupation: 'Select Occupation'
    },
    userInfo: {
      legislator: 'Legislator',
      testCenterOwner: 'Test Center Owner',
      changePassword: 'Change password',
      accountInformation: 'Account Information',
      logout: 'Sign out',
      assessor: 'Assessor',
      labor: 'Labor'
    },
    changePassword: {
      changePassword: 'Change Password',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
      changePasswordBtn: 'Change Password'
    },
    accountInformation: {
      accountInformation: 'Account Information',
      contactInformation: 'Contact Information',
      logo: 'Logo',
      showLogo: 'Show Logo',
      address: 'Address',
      postalCode: 'Postal Code',
      fullName: 'Full Name',
      phoneNumber: 'Phone Number',
      email: 'Email',
      edit: 'Edit',
      save: 'Save',
      cancel: 'Cancel',
      uploadLogo: 'Upload Logo',
      officialContactNumber: 'Official Contact Number'
    },
    checkValidity: {
      checkValidity: 'Check validity',
      verify: 'Verify',
      passportNumber: 'Passport Number',
      certificateSerialNumber: 'Certificate Serial Number',
      results: 'Results',
      laborName: 'Labor Name',
      category: 'Category',
      validUntil: 'Valid Until',
      enterCertificateSerialNumber: 'Enter certificate serial number',
      enterPassportNumber: 'Enter passport number',
      certificateNumber: 'Certificate number',
      enterCertificateNumber: 'Enter certificate number',
      certificateExpired: 'The certificate is expired',
      certificateIsValid: 'The certificate is valid',
      certificateInformation: 'Certificate information',
      certificateDoesntExist: 'The certificate does not exist',
      noResultsFound: 'No results are found for the entered combination'
    },
    validity: {
      checkCertificateAndExam: 'Check certificate & exam result',
      check: 'Check certificate validity',
      checkLaborResult: 'Check labor result',
      passportNumber: 'Passport Number',
      enterPassportNumber: 'Enter Passport Number',
      serialNumber: 'Certificate Serial Number',
      enterSerialNumber: 'Enter Certificate Serial Number',
      certificateNumber: 'Certificate number',
      enterCertificateNumber: 'Enter certificate number',
      certificateExpired: 'The certificate is expired',
      certificateIsValid: 'The certificate is valid',
      certificateInformation: 'Certificate information',
      certificateDoesntExist: 'The certificate does not exist',
      certificateSerialNumber: 'Certificate Serial Number',
      laborName: 'Labor Name',
      category: 'Category',
      validUntil: 'Valid Until',
      occupationKey: 'Occupation Key',
      occupationID: 'Occupation ID',
      nationalityCode: 'Nationality Code',
      enterOccupationID: 'Enter Occupation ID',
      enterOccupationKey: 'Enter Occupation Key',
      enterNationalityCode: 'Enter Nationality Code',
      laborResult: 'Exam result',
      noResults: 'No results are found for the entered combination'
    },
    countDown: {
      verificationExpired: 'Expired verification code',
      resendSms: 'Resend SMS code',
      resendCode: 'Resend code'
    },
    signIn: {
      step1: 'Step 1',
      step2: 'Step 2',
      step3: 'Step 3',
      heading: 'Sign in',
      label: {
        email: 'Email',
        emailOrPhone: 'Email or Phone number',
        password: 'Password',
        rememberMe: 'Remember me'
      },
      forgot: 'I forgot my password',
      continue_button: 'Continue',
      error_msg: 'Invalid Email or password.',
      back_link: 'Back to home page',
      twoFactorVerification: 'Two-Factor Verification',
      enterDigitsPhone: 'Enter the 6 digit code we sent you via phone number to continue',
      enterDigitsEmail: 'Enter the 6 digit code we sent you via email to continue',
      wrongCode: 'Wrong code provided. Try again',
      verifySms: 'Verify via SMS',
      verifyWhatsApp: 'Verify via WhatsApp',
      verifyEmail: 'Verify via Email',
      invalidEmailOrPassword: 'Invalid Email or Password',
      invalidPhoneOrPassword: 'Invalid Phone number or Password'
    },
    successRegistration: {
      signIn: 'Sign in',
      welcome: 'Welcome',
      createdSuccess: 'Your account has been created successfully!',
      validUntil: 'Valid Until',
      enterPassportNumber: 'Enter passport number',
      enterCertificateSerialNumber: 'Enter certificate serial number'
    },
    filesUploader: {
      dropFiles: 'Drop files to attach, or',
      view: 'Browse',
      fileSize: 'individual file upload size limit',
      mb: 'MB',
      allowedTypes: 'Allowed file type'
    },
    examSessions: {
      examSessions: 'Exam Sessions',
      openSessions: 'Open Sessions',
      chooseSession: 'Choose Session',
      addSession: 'Add Session',
      seeMore: 'See more',
      newSession: 'New Session',
      from: 'From',
      to: 'To',
      time: 'Time',
      seats: 'Seats',
      chooseNumber: 'Choose Number',
      chooseCategory: 'Choose Category',
      doesNotRepeat: 'Does not repeat',
      does_not_repeat: 'Does not repeat',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      monthlyOnThe: 'Monthly on the',
      monthlyOnTheFirst: 'Monthly on the first',
      monthlyOnTheSecond: 'Monthly on the second',
      monthlyOnTheThird: 'Monthly on the third',
      monthlyOnTheFourth: 'Monthly on the fourth',
      monthlyOnTheLast: 'Monthly on the last',
      first: 'first',
      second: 'second',
      third: 'third',
      fourth: 'fourth',
      last: 'last',
      custom: 'Custom',
      repeatSession: 'Repeat session',
      successSession: 'Session has been added successfully',
      errorSession: 'There is no available assessor who can conduct this exam at the desired date and time, please change the date or time to look for available assessors.',
      tooltip: {
        duration: 'The session duration should be between 90 min and 2h'
      },
      repeat: {
        does_not_repeat: 'Does not repeat',
        daily: 'Daily',
        weekly_on: 'Weekly on',
        monthly_on_the_first: 'Monthly on the first'
      }
    },
    sessionsManagement: {
      title: 'Sessions management',
      sessionId: 'Session ID',
      category: 'Category',
      sessionDate: 'Session Date',
      duration: 'Duration',
      numberOfSeats: 'Number of seats',
      numberOfReservations: 'Number of reservations',
      repeatSession: 'Repeat session',
      examStatus: 'Exam session status',
      typeHere: 'Type here',
      equals: 'Equals',
      moreThan: 'More than',
      lessThan: 'Less than',
      seats: 'Seats',
      reservations: 'Reservations',
      selectCategory: 'Select category',
      selectRegistrationMethod: 'Choose registration method',
      examSessionStatus: 'Exam session status',
      selectStatus: 'Select status',
      chooseDate: 'Choose date',
      from: 'From',
      to: 'To',
      enterCancelReason: 'Please enter the cancellation reason',
      enterReason: 'Enter Reason',
      confirmCancellation: 'I confirm the reservation cancellation and acknowledge that all the data entered are correct and under my responsibility',
      sessionInformation: '',
      assignedAssessor: 'Assigned Assessor',
      selectAssessor: 'Select Assessor',
      rejectionReason: 'Rejection reason'
    },
    sessionInformation: {
      session: 'Session',
      code: 'Code',
      category: 'Category',
      sessionDate: 'Session Date',
      duration: 'Duration',
      numberOfSeats: 'Number of seats',
      numberOfReservations: 'Number of reservations',
      repeatSession: 'Repeat session',
      examStatus: 'Exam session status',
      cancellationReason: 'Cancellation Reason',
      sessionInformation: 'Session Information',
      repeat: {
        does_not_repeat: 'Does not repeat',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly_on_the_first: 'Monthly on the first',
        monthly: 'Monthly'
      }
    },
    testTakers: {
      testTakers: 'Test takers',
      fullName: 'Worker full name',
      identityNumber: 'Identity number',
      reservationId: 'Reservation ID',
      nationalId: 'National ID',
      occupation: 'Occupation',
      examResult: 'Exam result',
      status: 'Status',
      attachment: 'Attachment',
      actions: 'Actions',
      reservationNum: 'Reservation num',
      reservationNumber: 'Reservation number',
      startCBTExam: 'Start CBT exam',
      startPracticalExam: 'Start Practical exam',
      startCBT: 'Start CBT',
      startPractical: 'Start Practical',
      testTakerFullName: 'Test taker name',
      uploadErrorOccurred: 'An error has occurred. Please try again.',
      numberOfFilesMoreThanOne: 'The number of files should not exceed one, and each file should not exceed 5 megabytes in size. File must be in pdf, jpd, doc, docx, png, and jpeg formats.',
      fileSubmittedSuccessfully: 'Applicant’s file has been submitted successfully!'
    },
    practicalResults: {
      practicalResults: 'Practical results',
      pendingPractical: 'Pending practical',
      nationalId: 'National ID',
      score: 'Score',
      attachments: 'Attachments',
      hintScore: 'Please manually enter your score for each test taker',
      saveScores: 'Save scores',
      enterScore: 'Enter score',
      confirm: 'Are you sure you want to save the practical scores and complete the exam?',
      startPracticalExam: 'Start Practical Exam',
      tooltipText: 'The practical exam is already submitted'
    },
    paymentHint: {
      text: "If you don't have a bank account, please visit a trusted recruitment agency to pay through the SVP system using a their bank account",
      more: 'View more',
      less: 'Show less',
      title: 'Use your login credentials to complete the payment and confirm the booking',
      step1: 'Step 1',
      step2: 'Step 2',
      step3: 'Step 3',
      step4: 'Step 4',
      step5: 'Step 5',
      step1text: 'Go to recruitment agency',
      step2text: 'Pay in cash',
      step3text: 'Open your account',
      step4text: 'Recruitment agency enter bank account',
      step5text: 'Done'
    },
    startExam: {
      startExam: 'Start exam',
      testTakersAndExamInfo: 'Test Taker & Exam info',
      termsAndConditions: 'Terms and Conditions',
      passportNumber: 'Passport Number',
      reservationNumber: 'Reservation Number',
      reservationInfo: 'Reservation info',
      enterNumber: 'Enter number',
      check: 'Check',
      testTakerInfo: 'Test Taker info',
      testDate: 'Test Date',
      time: 'Time',
      userName: 'User Name',
      nationality: 'Nationality',
      occupation: 'Occupation',
      examType: 'Exam Type',
      next: 'Next',
      cbt: 'CBT (Computer Based Test)',
      error: 'Records that match the entered data were not found',
      errorPassed: 'You cannot launch an exam session that has already passed.',
      blankPrometricCode: 'Prometric code can’t be blank.',
      cbt_and_practical: 'CBT and Practical Exam',
      testTakerName: 'Test Taker Name',
      checkboxText: 'I have acknowledge that I read the',
      validation: 'User data validation',
      dataIsProceed: 'Your data is being proceed, <strong>please wait</strong>',
      connectionExpired: 'Connection time has expired, please try again'
    },
    examInstructions: {
      instructions: 'The instructions',
      conditions: {
        1: 'Under the scope of the Skills Verification Program, some occupations that will advertised through official channels of communication with the Ministry of Labor and Social Development, and the portal of the program',
        2: 'The employer must register the test takers to sit for the Skills Verification test through the online portal and pay all outstanding fees prior to the exam date',
        3: 'The Skills Verification exam is containing two types of exams theoretical and practical.',
        4: 'The theoretical part of the exam’s duration is 30 minutes, the test taker can leave upon completion',
        5: 'The practical part of the exam’s duration is 30 minutes, the test taker can leave upon completion',
        6: 'The passing grade for the Skill Verification exam is 50% (The program reserves the right to',
        7: 'Test takers should present proof of Identification when appearing for the test',
        8: 'Test takers should oblige to all rules and regulations regarding good conduct, and in the event of doing the opposite, the test center or its representatives are in power to take any further actions that they deem fit',
        9: 'In case of failing a test, the test taker is granted the privilege to retake the exam.',
        10: 'The price of the Skill Verification Service will consist of both a theoretical and practical test'
      },
      checkboxText: 'I have acknowledge that I read the',
      startExam: 'Start exam'
    },
    passportModal: {
      headSubText: 'Is this your passport number?',
      modalText: 'Please make sure <b>you have entered</b> your <b>passport number correctly</b> as you won’t be able to edit or change it after registration',
      confirmBtnText: 'Confirm and proceed',
      cancelBtnText: 'Go Back'
    },
    repeatSessionModal: {
      heading: 'Repeat session',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      repeatEvery: 'Repeat Every',
      ends: 'Ends',
      on: 'On',
      after: 'After',
      sessions: 'sessions',
      repeatOn: 'Repeat on',
      monthlyOnDay: 'Monthly on day',
      selectOption: 'Select option'
    },
    viewCancelReservationModal: {
      cancelledBy: 'Cancelled by',
      userRole: 'Role',
      cancellationReason: 'Cancellation reason',
      gotIt: 'Got it'
    },
    metaTags: {
      description: 'The Professional Accreditation Program aims at verifying that professional workers possess the qualifications and skills required to perform their jobs in Saudi Arabia, which contributes to raising the quality of skill level in the labor market and increasing productivity.',
      keywords: 'Skills verification program Saudi Arabia, Skills verification program, International skills verification program, Professional accreditation program Saudi Arabia, Professional accreditation program, PACC, SVP'
    },
    availableAssessorModal: {
      titleText: 'No available assessor who can conduct exam on the date(s):',
      createAll: 'Create all other sessions?',
      viewMore: 'View more',
      timeLessThan: 'The specified time is less than the allowed <span>minimum of {limit} hours</span> before the start session.'
    },
    userRoles: {
      super_admin: 'Super Administrator',
      admin: 'Administrator',
      test_center_owner: 'Test Center Owner',
      labor: 'Labor'
    },
    ...landingTranslations.en,
    ...assessorTranslations.en,
    ...laborTranslations.en,
    ...authTranslations.en,
    ...visitorTranslations.en,
    ...legislatorTranslations.en,
    ...testCenterTranslations.en
  },
  ar: {
    select: 'اختار',
    codeWasSent: 'تم ارسال الرمز',
    failed: 'فشل',
    years: 'سنوات',
    male: 'ذكر',
    female: 'انثى',
    copyright: 'حقوق النشر',
    allRightsReserved: '. جميع الحقوق محفوظة.',
    addAttachment: 'إضافة مرفقات',
    ticketStatus: {
      solved: 'مغلق',
      open: 'مفتوح',
      pending: 'تحت الإجراء',
      new: 'جديد',
      closed: 'مغلقة'
    },
    label: {
      id: 'رقم الهوية',
      nationality: 'الجنسية',
      gender: 'الجنس',
      iqama_id: 'رقم الإقامة',
      dateOfBirth: 'تاريخ الميلاد'
    },
    verificationExpired: 'انتهت صلاحية رمز التحقق',
    id: 'م',
    legislators: 'المُشرعات',
    newLegislator: 'مُشرع جديد',
    editLegislator: 'تحرير المشرع',
    legislatorsInformation: 'معلومات المُشرع',
    englishName: 'الإسم بالإنجليزية',
    arabicName: 'الإسم بالعربية',
    selectCountry: 'اختر الدولة',
    country: 'الدولة',
    arabic: 'العربية',
    english: 'الإنجليزية',
    selectCity: 'اختر المدينة',
    city: 'المدينة',
    address: 'العنوان',
    postalCode: 'الرمز البريدي',
    contactInformation: 'معلومات التواصل',
    name: 'الاسم',
    phoneNumber: 'رقم الجوال',
    email: 'البريد الإلكتروني',
    createLegislator: 'إنشاء المشرع',
    createSuccessfully: 'تم إنشاء المُشرع بنجاح',
    status: 'الحالة',
    actions: 'إجراءات',
    selectStatus: 'اختر الحالة',
    add: 'إضافة',
    edit: 'تعديل',
    cancel: 'إلغاء',
    save: 'حفظ',
    back: 'رجوع',
    next: 'التالي',
    payNow: 'ادفع الان',
    agree: 'يوافق على',
    send: 'ارسال',
    notifications: 'التنبيهات',
    newNotification: 'إشعار جديد',
    invalidFile: 'ملف غير صالح',
    invalidFileType: 'نوع الملف غير صالح',
    backToHomePage: 'العودة إلى الصفحة الرئيسية',
    backTo: 'العودة إلى الصفحة',
    pay: 'دفع',
    paymentSuccess: 'تم الدفع بنجاح',
    done: 'إضافة',
    newTestCenter: 'مركز إختبار جديد',
    editTestCenter: 'مركز إختبار تعديل',
    clearFilter: 'إزالة التصفية',
    goToProgram: 'انتقل إلى البرنامج',
    ok: 'موافق',
    listIsEmpty: 'القائمة فارغة',
    continue: 'استمرار',
    apply: 'تطبيق',
    CNIC: 'رقم الهوية الوطنية',
    assessors: 'المقيّمين',
    relatedTCAssessors: 'المقيمين المرتبطين',
    assessor: 'المقيّم',
    approve: 'قبول',
    reject: 'رفض',
    uploadedLaborsResult: 'قائمة العمالة المدرجة',
    registeredLaborsResults: 'نتائج العمالة المسجلين',
    yes: 'نعم',
    no: 'لا',
    startEndTime: 'وقت البدء / الانتهاء',
    testTakersCount: 'Test Takers Count',
    number: 'ارقام',
    file: 'الملف',
    verify: 'تحقق',
    free: 'Free',
    monday: 'الاثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الاربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    saturday: 'السبت',
    sunday: 'الاحد',
    unassignedSessions: 'الجلسات الغير مسندة',
    assignedSessionsItem: 'الجلسات المسندة',
    error: 'خطأ',
    assessorNav: {
      basicInformation: 'المعلومات الأساسية',
      otherDetails: 'تفاصيل أخرى'
    },
    assessorPage: {
      basicInformation: 'المعلومات الأساسية',
      testTakers: 'المختبرون',
      practicalResults: 'نتائج الاختبار العملي',
      pendingPractical: 'اختبارات عملية غير مكتملة'
    },
    assessorTestTakers: {
      fullName: 'الاسم الكامل',
      identityNumber: 'رقم الهوية',
      reservationId: 'رقم الحجز',
      occupation: 'المهنة',
      examResult: 'نتيجة الاختبار',
      status: 'الحالة'
    },
    assessorModals: {
      approveModalTitle: 'هل انت متأكد من التقديم على هذه الجلسة؟',
      successApproveMessage: 'تم تعيين الجلسة بنجاح، بإمكانك الاطلاع على بيانات الجلسات من خلال الصفحة المخصصة',
      duplicateErrorModalTitle: 'طلب تقديم مكرر',
      duplicateErrorModalSubTitle: 'يوجد مقيم آخر معين للجلسة',
      cancelModalTitle: 'هل انت متأكد من رفض هذه الجلسة؟',
      successCancelMessage: 'تم رفض الجلسة بنجاح'
    },
    errorMessages: {
      required: 'حقل إجباري.',
      postalCodeCantBeEmpty: 'لا يمكن أن يكون الرمز البريدي فارغًا',
      streetCantBeEmpty: 'لا يمكن أن يكون الشارع  فارغًا',
      cityCantBeEmpty: 'لا يمكن أن تكون المدينة فارغة',
      emailCantBeEmpty: 'لا يمكن أن يكون البريد الإلكتروني فارغًا',
      nameCantBeEmpty: 'لا يمكن أن يكون الاسم  فارغًا',
      categoriesCantBeEmpty: 'يجب تحديد فئة واحدة على الأقل',
      occupationsCantBeEmpty: 'يجب تحديد مهنة واحدة على الأقل',
      contactNumberCantBeEmpty: 'رقم الاتصال لا يمكن أن يكون فارغًا',
      englishChar: 'أحرف إنجليزية فقط',
      arabicChar: 'أحرف عربية فقط',
      englishSpecChar: 'يجب أن يحتوي على أحرف إنجليزية ورموز فقط',
      englishCharAndNumbers: 'يجب أن يحتوي على أحرف وأرقام إنجليزية فقط',
      englishSpecCharAndNumbers: 'يجب أن يحتوي على أحرف وأرقام ورموز باللغة الإنجليزية فقط',
      englishOrArabic: 'يجب أن يحتوي على حروف إنجليزية أو عربية',
      numberChar: 'يجب أن يحتوي على أرقام فقط',
      email: 'يرجى إدخال بريد إلكتروني صحيح',
      maxValue100: 'يجب ألا تكون أكبر من 100',
      maxLength7: 'الحد الأقصى 7 رقمًا',
      maxLength3: 'الحد الأقصى 3 أحرف',
      maxLength9: 'الحد الأقصى 9 أحرف',
      maxLength10: 'الحد الأقصى 10 رقمًا',
      maxLength20: 'الحد الأقصى 20 رقمًا',
      maxLength25: 'الحد الأقصى 25 رقمًا',
      maxLength50: 'الحد الأقصى 50 رقمًا',
      maxLength100: 'الحد الأقصى 100 رقمًا',
      maxLength250: 'الحد الأقصى 250 رقمًا',
      minLength5: 'الحد الأدنى 5 أرقام',
      minLength6: 'الحد الأدنى 6 أرقام',
      minLength7: 'الحد الأدنى 7 أرقام',
      minLength10: 'الحد الأدنى 10 أرقام',
      minLength2: 'الحد الأدنى 2 أحرف',
      minLength8: 'الحد الأدنى 8 أحرف',
      minLength9: 'الحد الأدنى 9 أحرف',
      issueCertificate: 'العامل صاحب رقم جواز السفر التالي لديه شهادة نشطة ضمن نفس الفئة ، هل أنت متأكد أنك تريد المضي قدمًا في إصدار شهادة أخرى مع العلم أن المبلغ غير قابل للاسترداد',
      somethingWentWrong: 'هناك خطأ ما',
      beErrorOccured: 'حدث خطأ، يرجى المحاولة مرة أخرى.',
      codeIsIncorrect: 'رمز التحقق الذي أدخلته غير صحيح أو قد انتهت صلاحيته',
      showLaborsWithEmptyEmails: 'إظهار العمل مع رسائل البريد الإلكتروني الفارغة فقط',
      characters820: 'احرف انجليزية 8-20',
      upperCaseLetter: 'احرف كبيرة',
      lowerCaseLetter: 'احرف صغيرة',
      containsNumber: 'ارقام',
      specialChar: 'رموز',
      passportNumber: 'يجبان يحتوي حرف واد و٧ ارقام على الاقل',
      passportNumberLetters: 'رقم الجواز يجب أن يبدأ بحرف او حرفين',
      passportNumberDigits: 'رقم الجواز يجب أن يحتوي ٦ خانات كحد أدنى و ١١ خانة كحد أقصى',
      passportNumberTotal: 'رقم الجواز يجب ان يحتوي ٩ ارقام واحرف كحد اقصى',
      serialNumber: 'رقم الشهادة غير صحيح',
      sameAs: 'كلمة المرور لا تتطابق',
      dateFormatIsInvalid: 'صيغة التاريخ المدخلة غير صحيحة',
      invalidAgeForWork: ' يجب أن يكون عمر المستخدم ما بين 15 و 70 سنة.',
      dateIsAfter: 'التاريخ يجب أن يكون في المستقبل',
      defaultFetchError: 'حدث خطأ، يرجى المحاولة مرة أخرى.'
    },
    messagesAlert: {
      areYouSure: 'هل أنت متأكد ؟',
      createdTestCenter: 'تم إنشاء مركز الاختبار بنجاح',
      deletedTestCenter: 'تم حذف مركز الاختبار بنجاح',
      updatedTestCenter: 'تم تحديث مركز الاختبار بنجاح',
      receiveEmail: 'سوف تتلقى قائمة بالشهادات على عنوان بريدك الإلكتروني في غضون بضع دقائق',
      leaveThisPage: 'تم تعديل البيانات في الصفحة، في حال المغادرة دون الحفظ لن يتم حفظ التغييرات. هل انت متأكد من مغادرة الصفحة؟',
      leavePaymentPage: 'هل أنت متأكد من مغادرة صفحة الدفع؟',
      deleteTestCenter: 'في حال حذف مركز الإختبار سيتم إلغاء صلاحيته من الوصول إلى خدمات المنصة',
      noEnoughBalance: 'لا يوجد رصيد كافي لإصدار الشهادة',
      issueFreePaid: 'لا يمكنك إصدار شهادات من الرصيد [المجاني والمدفوع] في نفس الوقت',
      issueCertificates: 'هل انت متأكد انك تود إصدار {count} شهادة',
      orderCantBeRefunded: 'لا يمكن استعادة مبلغ الطلب بعد عملية الدفع',
      errorForAddIndividualLabor: 'سجل مكرر للعامل صاحب رقم الجواز {passportNumber} يوجد',
      warningForAddIndividualLabor: 'العامل صاحب جواز السفر رقم {passportNumber} له سجل مسبق بتاريخ مختلف',
      warningForAddLaborsGroup: 'العمال أصحاب أرقام جوازات السفر لديهم سجلات مسبقة في النظام بتاريخ مختلف',
      errorForAddLaborsGroup: 'لا يمكن تحميل العمالة بأرقام جوازات السفر هذه',
      accountInactive: 'كان حساب مركز الاختبار غير نشط',
      fileUploadSucces: 'تم رفع الملف بنجاح'
    },
    successMessages: {
      success: 'ناجحة',
      done: 'تم',
      accountUpdated: 'تم تحديث الحساب بنجاح'
    },
    sendEmailModal: {
      toAccount: 'ارسال نسخة الى حسابي',
      toLabors: 'ارسال نسخة الى العمالة',
      sendCertificateToEmails: 'مشاركة الشهادة'
    },
    labors: {
      editLabor: 'تحرير العمل',
      editEmail: 'تعديل البريد الالكتروني',
      labors: 'العمالة',
      editEmailHint: 'سيتم إستخدامه لمشاركة الشهادة',
      listOfLabors: 'قائمة العمال',
      filterLabors: 'تصفية القائمة',
      registeredLabors: 'العمالة المسجلين',
      downloadCsv: 'تحميل ملف CSV',
      laborsCounterTooltip: {
        all: 'عدد اختبارات جميع العمالة',
        pass: 'جميع العمالة الناجحين ',
        fail: 'جميع العمالة الراسبين',
        pending: 'عدد الشهادات الغير مدفوعة',
        expired: 'عدد الشهادات منتهية الصلاحية',
        rejected: 'عدد الاختبارات المرفوضة',
        reserved: 'عدد العمالة الذين حجزوا اختبارات'
      },
      counter: {
        all: 'الكل',
        pass: 'ناجح',
        fail: 'راسب',
        pending: 'معلق',
        expired: 'منتهية الصلاحية',
        rejected: 'مرفوض',
        reserved: 'محجوز'
      },
      group: 'مجموعة'
    },
    uploadSingleLabor: {
      addIndividual: 'إضافة عامل'
    },
    uploadGroup: {
      addGroup: 'إضافة مجموعة',
      downloadCsvSample: 'قم بتنزيل نموذج CSV',
      testCentersList: 'قائمة مراكز الاختبار',
      categoryList: 'قائمة الفئات',
      uploadFile: 'رفع الملف',
      noData: 'لا توجد بيانات متاحة',
      choseFile: 'يرجى اختيار الملف',
      allowedFileType: 'نوع الملف المسموح به: CSV',
      fileInfo: 'معلومات الملف',
      maxSizeErr: 'تم تجاوز الحد الأقصى لحجم الملف المسموح به'
    },
    nationalId: 'الهوية الوطنية',
    laborName: 'اسم العامل',
    passport: 'جواز السفر',
    examDate: 'يوم الاختبار',
    testCenter: 'مركز الاختبار',
    warning: 'تحذير',
    confirm: 'تأكيد',
    privacyPageLink: 'سياسة الخصوصية',
    termsOfUse: 'شروط الاستخدام',
    aboutUs: 'من نحن',
    knowledgeCenter: 'مركز المعرفة',
    occupation: 'مهنة',
    countries: 'الدول',
    score: 'نتيجة الاختبار ٪',
    selectAll: 'تحديد الكل',
    unselectAll: 'إلغاء تحديد الكل',
    noDataAvailable: 'لا توجد بيانات متاحة',
    individualInformation: 'معلومات العامل',
    selectOccupation: 'اختر المهنة',
    selectTestCenter: 'اختر مركز الاختبار',
    price: 'السعر',
    homePage: {
      signIn: 'تسجيل الدخول'
    },
    nav: {
      uploadedFiles: 'ملفات العمالة',
      labors: 'العمالة',
      payment: 'الدفعات',
      testCenters: 'مراكز الاختبار',
      transactionHistory: 'تاريخ المعاملة',
      reports: 'التقارير',
      examSessions: 'جلسة الاختبار',
      sessionsManagement: 'إدارة الجلسات',
      sessionsReport: 'تقرير الجلسات',
      startExam: 'بدء الاختبار',
      unassignedSessions: 'الجلسات الغير مسندة'
    },
    tableMain: {
      fileId: 'معرّف الملف',
      uploadDate: 'تاريخ الرفع',
      uploadFile: 'رفع الملف',
      numberOfPassedLabors: 'عدد العمالة الناجحة',
      numberOfLabors: 'عدد العمالة',
      totalLabors: 'مجموع العمالة',
      actions: 'الإجراءات',
      searchResults: 'نتائج البحث',
      prev: '<السابق',
      next: 'التالي>',
      laborId: 'رقم المعرّف',
      laborName: 'اسم العامل',
      passportNumber: 'رقم جواز السفر',
      testCenter: 'مركز الاختبار',
      category: 'الفئة',
      ownerName: 'المسؤول',
      examDate: 'موعد الاختبار',
      examResult: 'نتيجة الاختبار',
      email: 'البريد الالكتروني',
      amount: 'المبلغ',
      totalAmount: 'المبلغ الإجمالي',
      testCenterId: 'رقم المعرّف',
      name: 'الاسم',
      city: 'المدينة',
      testCenterOwner: 'صاحب مركز الاختبار',
      status: 'الحالة',
      referenceNumber: 'رقم المرجع',
      date: 'التاريخ',
      invoice: 'الفاتورة',
      report: 'التقرير',
      certificates: 'الشهادات',
      id: 'رقم المعرّف',
      fileName: 'اسم الملف',
      numberOfCertificates: 'عدد الشهادات',
      pricePerCertificate: 'السعر لكل شهادة',
      pricePerLabor: 'سعر الوحدة',
      grandTotal: 'المبلغ الإجمالي',
      certificateExpirationDate: 'تاريخ انتهاء الشهادة',
      categoryName: 'اسم الفئة',
      delete: 'حذف',
      send: 'ارسال',
      fullName: 'الاسم الكامل',
      rejectedReason: 'سبب الرفض',
      nationality: 'الجنسية',
      nationalId: 'الهوية الوطنية',
      noData: 'لا توجد بيانات متاحة',
      cancel: 'إلغاء',
      view: 'عرض',
      approve: 'قبول',
      groupId: 'رقم المجموعة',
      appliedOn: 'تاريخ التقديم',
      score: 'النتيجة',
      registrationMethod: 'طريقة التسجيل',
      self_registered: 'مسجل',
      tc_invited: 'مدعو من قبل مركز اختبار',
      startDateAndTime: 'الوقت والتاريخ',
      duration: 'المدة',
      noTestTakers: 'لا يوجد مسجلين',
      sortBy: 'فرز حسب',
      order: 'ترتيب حسب',
      startDate: 'تاريخ البداية',
      creationDate: 'تاريخ الانشاء',
      sessionId: 'معرف الجلسة',
      sessionDate: 'تاريخ الجلسة',
      startEndTime: 'وقت البدء / الانتهاء'
    },
    reviewStates: {
      rejected: 'مرفوض',
      active: 'نشط',
      approved: 'مقبول',
      first_review: 'مراجعة اولى',
      second_review: 'مراجعة اخيرة',
      rejected_by_legislator: 'تم رفضه من قبل المشرع'
    },
    statuses: {
      passed: 'ناجح',
      pending: 'معلق',
      failed: 'غير ناجحة',
      expired: 'منتهية الصلاحية',
      rejected: 'مرفوض',
      active: 'نشط',
      inactive: 'غير نشط',
      preparedCheckout: 'معلقة',
      prepared_checkout: 'معلقة',
      success: 'ناجحة',
      reserved: 'محجوز',
      completed: 'مكتمل',
      canceled: 'ملغي',
      withdrawn: 'منسحب',
      scheduled: 'مجدولة',
      in_progress: 'قيد التنفيذ',
      unpublished: 'غير منشورة',
      no_result_yet: 'لا توجد نتائج بعد',
      valid: 'صالحة',
      assessor_withdrawn: 'مجدولة',
      drafted: 'مسودة'
    },
    payment: {
      payment: 'الدفعات',
      balance: 'الرصيد',
      credits: 'الرصيد المتاح',
      totalAmount: 'المبلغ الإجمالي',
      numberOfLabors: 'عدد العمالة',
      transactionHistory: 'تفاصيل العمليات',
      issue: 'إصدار',
      getCredit: 'اشحن رصيدك',
      certificates: 'الشهادات',
      unitPrice: 'سعر الوحدة',
      total: 'المجموع',
      enterCardDetails: 'يرجى إدخال تفاصيل بطاقتك لعملية الشراء',
      transactionAmount: 'قيمة التحويل'
    },
    paymentConfirmation: {
      paymentConfirmation: 'الشهادات',
      grandTotal: 'المبلغ الإجمالي',
      transactionInformation: 'معلومات العملية',
      certificates: 'مشاركة الشهادة',
      certificatesDownloadText: 'يمكنك تنزيل قائمة الشهادات الآن',
      download: 'تحميل',
      sendEmail: 'إرسال'
    },
    testCenters: {
      testCenters: 'مراكز الاختبار',
      addTestCenter: 'إضافة مركز اختبار',
      testCenterOwner: 'صاحب مركز الاختبار',
      name: 'الاسم',
      generalInfo: 'معلومات عامة',
      generalInformation: 'معلومات عامة',
      testCenterInformation: 'معلومات مركز الاختبار',
      officialContactNumber: 'رقم الاتصال الرسمي',
      city: 'المدينة',
      country: 'الدولة',
      streetName: 'اسم الشارع',
      postalCode: 'الرمز البريدي',
      supportedOccupations: 'المهن المدعومة',
      email: 'البريد الالكتروني',
      location: 'الموقع',
      selectCategory: 'اختر الفئة',
      selectCountry: 'اختر الدولة',
      selectOccupation: 'اختر المهنة'
    },
    userInfo: {
      legislator: 'مُشرع',
      testCenterOwner: 'صاحب مركز الاختبار',
      changePassword: 'تغيير كلمة المرور',
      accountInformation: 'معلومات الحساب',
      logout: 'تسجيل الخروج',
      assessor: 'المراقب',
      labor: 'العمالة'
    },
    changePassword: {
      changePassword: 'تغيير كلمة المرور',
      currentPassword: 'كلمة المرور الحالية',
      newPassword: 'كلمة المرور الجديدة',
      confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
      changePasswordBtn: 'تغيير كلمة المرور'
    },
    accountInformation: {
      accountInformation: 'معلومات الحساب',
      contactInformation: 'معلومات التواصل',
      logo: 'الشعار',
      showLogo: 'إظهار الشعار',
      address: 'العنوان',
      postalCode: 'الرمز البريدي',
      fullName: 'الاسم الكامل',
      phoneNumber: 'رقم الجوال',
      email: 'البريد الالكتروني',
      edit: 'تعديل',
      save: 'حفظ',
      cancel: 'إلغاء',
      uploadLogo: 'إضافة الشعار',
      officialContactNumber: 'رقم الاتصال الرسمي'
    },
    checkValidity: {
      checkValidity: 'التحقق من الصلاحية',
      verify: 'تحقق',
      passportNumber: 'رقم جواز السفر',
      certificateSerialNumber: 'الرقم التسلسلي للشهادة',
      results: 'نتائج',
      laborName: 'اسم العامل',
      category: 'الفئة',
      validUntil: 'صالحة لغاية',
      enterCertificateSerialNumber: 'أدخل الرقم التسلسلي للشهادة',
      enterPassportNumber: 'أدخل رقم جواز السفر',
      certificateNumber: 'رقم الشهادة',
      enterCertificateNumber: 'ادخل رقم الشهادة',
      certificateExpired: 'الشهادة منتهية الصلاحية',
      certificateIsValid: 'الشهادة صالحة',
      certificateDoesntExist: 'الشهادة غير صالحة',
      noResultsFound: 'لم يتم العثور على اي نتيجة مرتبطة بالبيانات المدخلة'
    },
    validity: {
      checkCertificateAndExam: 'التحقق من الشهادة ونتيجة الاختبار',
      check: 'التحقق من صلاحية الشهادة',
      checkLaborResult: 'التحقق من حالة اختبار العامل',
      passportNumber: 'رقم جواز السفر',
      enterPassportNumber: 'أدخل رقم جواز السفر',
      serialNumber: 'الرقم التسلسلي للشهادة',
      enterSerialNumber: 'أدخل الرقم التسلسلي للشهادة',
      certificateInformation: 'بيانات الشهادة',
      certificateSerialNumber: 'الرقم التسلسلي للشهادة',
      laborName: 'اسم العامل',
      category: 'الفئة',
      validUntil: 'صالحة لغاية',
      occupationKey: 'رمز المهنة',
      occupationID: 'معرف المهنة',
      nationalityCode: 'رمز الجنسية',
      enterOccupationID: 'ادخل معرف المهنة',
      enterOccupationKey: 'ادخل رمز المهنة',
      enterNationalityCode: 'ادخل رمز الجنسية',
      laborResult: 'نتيجة الاختبار',
      noResults: 'لم يتم العثور على اي نتيجة مرتبطة بالبيانات المدخلة',
      certificateNumber: 'رقم الشهادة',
      enterCertificateNumber: 'ادخل رقم الشهادة',
      certificateExpired: 'الشهادة منتهية الصلاحية',
      certificateIsValid: 'الشهادة صالحة',
      certificateDoesntExist: 'الشهادة غير صالحة'
    },
    countDown: {
      verificationExpired: 'انتهت صلاحية رمز التحقق',
      resendSms: 'إعادة إرسال رمز SMS',
      resendCode: 'إعادة إرسال الرمز'
    },
    signIn: {
      step1: 'الخطوة ١',
      step2: 'الخطوة ٢',
      step3: 'الطوة ٣',
      heading: 'تسجيل الدخول',
      label: {
        email: 'البريد الإلكتروني',
        emailOrPhone: 'البريد الإلكتروني أو رقم الهاتف',
        password: 'كلمة المرور',
        rememberMe: 'تذكرني'
      },
      forgot: 'لقد نسيت كلمة المرور',
      continue_button: 'استمرار',
      error_msg: 'خطأ في البريد الإلكتروني أو كلمة المرور.',
      back_link: 'العودة إلى الصفحة الرئيسية',
      twoFactorVerification: 'التحقق ثنائي العامل',
      enterDigitsPhone: 'أدخل الرمز المكون من 6 أرقام الذي أرسلناه إليك عبر رقم الهاتف المسجل',
      enterDigitsEmail: 'أدخل الرمز المكون من 6 أرقام الذي أرسلناه إليك عبر البريد الإلكتروني المسجل',
      wrongCode: 'حدث خطأ. حاول مرة أخرى',
      verifySms: 'التحقق عن طريق الرسائل',
      verifyWhatsApp: 'التحقق عن طريق الواتس أب',
      verifyEmail: 'التحقق عن طريق البريد الإلكتروني',
      invalidEmailOrPassword: 'خطأ في البريد الالكتروني أو في كلمة المرور',
      invalidPhoneOrPassword: 'خطأ برقم الجوال أو كلمة المرور'
    },
    successRegistration: {
      signIn: 'تسجيل الدخول',
      welcome: 'مرحبًا بكم ',
      createdSuccess: 'تم إنشاء حسابك بنجاح!',
      validUntil: 'صالحة لغاية',
      enterPassportNumber: 'أدخل رقم جواز السفر',
      enterCertificateSerialNumber: 'أدخل الرقم التسلسلي للشهادة'
    },
    filesUploader: {
      dropFiles: 'قم بسحب الملفات لإرفاقها أو',
      view: 'تصفحها',
      fileSize: 'حد حجم تحميل الملف',
      mb: 'ميجا بايت',
      allowedTypes: 'نوع الملف المسموح به'
    },
    examSessions: {
      examSessions: 'جلسة الاختبار',
      openSessions: 'فتح جلسة',
      chooseSession: 'اختر جلسة',
      addSession: 'اضافة جلسة',
      seeMore: 'شاهد المزيد',
      newSession: 'جلسة جديدة',
      from: 'من',
      to: 'الى',
      time: 'الوقت',
      seats: 'المقاعد',
      chooseNumber: 'اختر الرقم',
      chooseCategory: 'اختر الفئة',
      doesNotRepeat: 'عدم تكرار',
      does_not_repeat: 'عدم تكرار',
      daily: 'يوميا',
      weekly: 'اسبوعيا',
      monthly: 'شهر',
      monthlyOnThe: 'شهريا كل',
      monthlyOnTheFirst: 'أول يوم من كل شهر',
      monthlyOnTheSecond: 'شهريا كل ثاني',
      monthlyOnTheThird: 'شهريا كل ثالث',
      monthlyOnTheFourth: 'شهريا كل رابع',
      monthlyOnTheLast: 'شهريا كل اخر',
      first: 'اول',
      second: 'ثاني',
      third: 'ثالث',
      fourth: 'رابع',
      last: 'اخر',
      custom: 'تخصيص',
      repeatSession: 'كرر الجلسة',
      successSession: 'تمت إضافة الجلسة بنجاح.',
      errorSession: 'لا يوجد مقيمين متاحين في وقت وتاريخ الجلسة، نرجو تغيير الوقت او التاريخ ليتم البحث عن مقيمين متاحين.',
      tooltip: {
        duration: 'يجب أن تتراوح مدة الجلسة مابين 90 دقيقة وساعتين'
      },
      repeat: {
        does_not_repeat: 'عدم تكرار',
        daily: 'يوميا',
        weekly_on: 'اسبوعيا',
        monthly_on_the_first: 'أول يوم من كل شهر'
      }
    },
    sessionsManagement: {
      title: 'إدارة الجلسات',
      sessionId: 'معرف الجلسة',
      category: 'الفئة',
      sessionDate: 'تاريخ الجلسة',
      duration: 'مدة',
      numberOfSeats: 'عدد المقاعد',
      numberOfReservations: 'عدد الحجوزات',
      repeatSession: 'تكرار الجلسة',
      examStatus: 'حالة جلسة الاختبار',
      typeHere: 'أكتب هنا',
      equals: 'يساوي',
      moreThan: 'أكثر من',
      lessThan: 'أقل من',
      seats: 'المقاعد',
      reservations: 'الحجوزات',
      selectCategory: 'اختر الفئة',
      selectRegistrationMethod: 'اختر طريقة التسجيل',
      examSessionStatus: 'حالة جلسة الاختبار',
      selectStatus: 'اختر الحالة',
      chooseDate: 'اخر التاريخ',
      from: 'من',
      to: 'إلى',
      enterCancelReason: 'الرجاء إدخال سبب الإلغاء',
      enterReason: 'ادخل السبب',
      confirmCancellation: 'أؤكد إلغاء الحجز وأقر بأن جميع البيانات المدخلة صحيحة وتحت مسؤوليتي',
      assignedAssessor: 'المقيّم المكلّف',
      selectAssessor: 'اختر المقيّم',
      rejectionReason: 'سبب الرفض'
    },
    sessionInformation: {
      session: 'الجلسة',
      code: 'الرمز',
      category: 'الفئة',
      sessionDate: 'تاريخ الجلسة',
      duration: 'مدة',
      numberOfSeats: 'عدد المقاعد',
      numberOfReservations: 'عدد الحجوزات',
      repeatSession: 'تكرار الجلسة',
      examStatus: 'حالة جلسة الاختبار',
      cancellationReason: 'سبب الإلغاء',
      sessionInformation: 'معلومات الجلسة',
      repeat: {
        does_not_repeat: 'عدم تكرار',
        daily: 'يوميا',
        weekly: 'اسبوعيا',
        monthly_on_the_first: 'أول يوم من كل شهر',
        monthly: 'شهريا'
      }
    },
    testTakers: {
      testTakers: 'المختبرون',
      fullName: 'الاسم الكامل للمختبر',
      identityNumber: 'رقم الهوية',
      reservationId: 'رقم الحجز',
      nationalId: 'رقم الهوية الوطنية',
      occupation: 'المهنة',
      examResult: 'نتيجة الاختبار',
      status: 'الحالة',
      attachment: 'المرفقات',
      actions: 'الإجراءات',
      reservationNum: 'رقم الحجز',
      reservationNumber: 'رقم الحجز',
      startCBTExam: 'بداية الاختبار النظري',
      startPracticalExam: 'بداية الاختبار العملي',
      startCBT: 'بداية النظري',
      startPractical: 'بداية العملي',
      testTakerFullName: 'اسم المختبر',
      uploadErrorOccurred: 'حدث خطأ، يرجى المحاولة مرة أخرى.',
      numberOfFilesMoreThanOne: 'يجب ألا يتجاوز عدد الملفات ملفًا واحدًا، ويجب ألا يتجاوز حجم الملف 1 ميجابايت، وأن يكون الملف بصيغة pdf، jpd، doc، docx، png، jpeg.',
      fileSubmittedSuccessfully: 'تم إرسال ملف المُتقدم بنجاح!'
    },
    practicalResults: {
      practicalResults: 'نتائج الاختبار العملي',
      pendingPractical: 'اختبارات عملية غير مكتملة',
      nationalId: 'الهوية الوطنية',
      score: 'النتيجة',
      attachments: 'المرفقات',
      hintScore: 'يرجى ادخال الدرجات لكل عامل',
      saveScores: 'حفظ النتائج',
      enterScore: 'ادخل الدرجة',
      confirm: 'هل أنت متأكد أنك تريد حفظ الدرجات وإكمال الاختبار؟',
      startPracticalExam: 'بدأ الاختبار العملي',
      tooltipText: 'تم ادخال الاختبار العملي مسبقاً'
    },
    paymentHint: {
      text: 'إذا لم يكن لديك حساب مصرفي ، يرجى زيارة وكالة توظيف موثوق بها للدفع من خلال نظام الفحص المهني بإستخدام الحساب البنكي الخاص بهم',
      more: 'عرض المزيد',
      less: 'عرض أقل',
      title: 'ملاحظة: استخدم بياناتك لتسجيل الدخول ومتابعة الدفع حتى يتم تأكيد الحجز',
      step1: 'الخطوة الاولى',
      step2: 'الخطوة الثانية',
      step3: 'الخطوة الثالثة',
      step4: 'الخطوة الرابعة',
      step5: 'الخطوة الخامسة',
      step1text: 'الذهاب الى وكالة التوظيف',
      step2text: 'دفع نقدي',
      step3text: 'الدخول الى حسابك',
      step4text: 'ادخال المعلومات البنكية لحساب وكالة التوظيف',
      step5text: 'إضافة',
      enterId: 'ادخال الرقم',
      enterInvoiceNum: 'ادخال رقم الفاتورةl',
      selectStatus: 'اختيار الحالة',
      from: 'من',
      to: 'الى',
      apply: 'تطبيق',
      clear: 'مسح'
    },
    startExam: {
      startExam: 'بدء الاختبار',
      testTakersAndExamInfo: 'معلومات الاختبار',
      termsAndConditions: 'الشروط والأحكام',
      passportNumber: 'رقم جواز السفر',
      reservationNumber: 'رقم الحجز',
      reservationInfo: 'معلومات الحجز',
      enterNumber: 'ادخل الرقم',
      check: 'تحقق',
      testTakerInfo: 'معلومات العامل',
      testDate: 'تاريخ الاختبار',
      time: 'الوقت',
      userName: 'اسم االمستخدم',
      nationality: 'الجنسية',
      occupation: 'المهنة',
      examType: 'نوع الاختبار',
      next: 'التالي',
      cbt: 'اختبار قائم على الكمبيوتر',
      error: 'لم يتم العثور على حجز بالبيانات المدخلة',
      error_passed: 'لا يمكنك بدء اختبار جلسة بتاريخ منتهي',
      cbt_and_practical: 'اختبار نظري وعملي',
      blankPrometricCode: 'كود برومترك يجب أن لا يكون فارغا',
      testTakerName: 'اسم المختبر',
      checkboxText: 'أقر بأنني قرأت وأوافق على بنود',
      validation: 'التحقق من بيانات المختبر',
      dataIsProceed: 'يتم معالجة بياناتك <strong>الرجاء الانتظار</strong>',
      connectionExpired: 'انتهى وقت الاتصال، يرجى المحاولة مجددا'
    },
    examInstructions: {
      instructions: 'التعليمات',
      conditions: {
        1: 'يقع تحت نطاق برنامج الفحص المهني العمالة في المهن التي يتم الإعلان عنها من خلال قنوات التواصل الرسمية لوزارة العمل والتنمية الاجتماعية، والبوابة الإلكترونية للبرنامج',
        2: 'يتم تسجيل العمالة للإختبارات في المراكز المعتمدة تحت مظلة برنامج الفحص المهني من خلال البوابة الإلكترونية للبرنامج، ولا تقبل نتيجة أي إختبار لم تتم إقامته من خلال أحد المراكز المعتمدة',
        3: 'يتكون اختبار الفحص المهني من جزئين، اختبار نظري عن طريق الحاسب الآلي واختبار عملي تطبيقي',
        4: 'المدة الزمنية للجزء النظري  30   دقيقة و يمكن للمختبر الانصراف قبل ذلك في حال الانتهاء قبل الوقت المحدد',
        5: 'المدة الزمنية للجزء العملي  30   دقيقة و يمكن للمختبر الانصراف قبل ذلك في حال الانتهاء قبل الوقت المحدد',
        6: 'نسبة النجاح المطلوبة لاختبار الفحص المهني هي 50% (يحتفظ البرنامج بحق تعديل هذه النسبة على أن يتم نشرها في البوابة الالكترونية وقنوات التواصل الرسمية للبرنامج)',
        7: 'يجب على المختبر توفير بطاقة الهوية عند الحضور إلى مركز الاختبار',
        8: 'يجب على المختبر الالتزام بجميع الضوابط السلوكية التابعة للمركز و في حال مخالفة ذلك يحق للمركز أو من يمثله باتخاذ كافة الإجراءات اللازمة',
        9: 'يحق للمختبر في حال عدم اجتياز الاختبار إعادة المحاولة',
        10: 'قيمة اختبار الفحص المهني تشمل الجزء النظري و الجزء العملي'
      },
      checkboxText: 'أقر بأنني قرأت وأوافق على بنود',
      startExam: 'بدء الاختبار'
    },
    passportModal: {
      headSubText: 'هل رقم الجواز صحيح؟',
      modalText: 'نأمل التأكد من إدخال رقم جواز صحيح لعدم إمكانية تعديل الرقم بعد التسجيل',
      confirmBtnText: 'تأكيد ومتابعة',
      cancelBtnText: 'العودة'
    },
    repeatSessionModal: {
      heading: 'تكرار الجلسة',
      day: 'يوم',
      week: 'اسبوع',
      month: 'شهر',
      repeatEvery: 'تكرار الجلسة كل',
      ends: 'ينتهي',
      on: 'في',
      after: 'بعد',
      sessions: 'جلسات',
      repeatOn: 'كرر على',
      monthlyOnDay: 'شهريا في اليوم',
      selectOption: 'حدد الخيار'
    },
    viewCancelReservationModal: {
      cancelledBy: 'ألغيت من قبل',
      userRole: 'دور المستخدم',
      cancellationReason: 'سبب الإلغاء',
      gotIt: 'متابعة'
    },
    metaTags: {
      description: 'يهدف البرنامج إلى التحقق من امتلاك العامل المهني للمهارات اللازمة من خلال أداء اختبار عملي و نظري في مجال تخصصه.',
      keywords: 'الفحص المهني للعمالة , برنامج الفحص المهني الدولي , برنامج الفحص المهني السعودية , برنامج الفحص المهني, PACC, SVP'
    },
    availableAssessorModal: {
      titleText: 'لا يوجد مقيّم متاح للجلسات بالفترات المحدده ادناه:',
      createAll: 'هل ترغب بإنشاء جميع الجلسات الاخرى؟',
      viewMore: 'عرض المزيد',
      timeLessThan: 'الوقت المحدد أقل من المدة الأدنى قبل بداية الجلسة <span/>({limit} ساعة)<span>'
    },
    userRoles: {
      super_admin: 'مشرف متميز',
      admin: 'مشرف',
      test_center_owner: 'صاحب مركز الاختبار',
      labor: 'العمالة'
    },
    ...landingTranslations.ar,
    ...assessorTranslations.ar,
    ...laborTranslations.ar,
    ...authTranslations.ar,
    ...visitorTranslations.ar,
    ...legislatorTranslations.ar,
    ...testCenterTranslations.ar
  }
};

export default messages;
