import AssessorsApi from '@/api/assessors.api';

const module = {
  state: {
    assessorDetails: null
  },
  getters: {
    getAssessorDetailsById: (state) => {
      return state.assessorDetails;
    },
    getUrls: (state) => {
      return state.assessorDetails?.qualifications_urls;
    }
  },
  mutations: {
    'SET_ASSESSOR_DETAILS_BY_ID' (state, data) {
      state.assessorDetails = data;
    }
  },
  actions: {
    /** getAssessorsList
     * @param commit
     * @param rootState
     * @param data
     * @returns {Promise<unknown>}
     */
    getAssessorsList: async ({ commit, rootState }, params) => {
      return await AssessorsApi.getAssessorsList(rootState.user.userSpace, {
        ...params,
        related_to_tc: false
      });
    },
    /** getAssessorsListRelatedByTC
     * @param commit
     * @param rootState
     * @param data
     * @returns {Promise<unknown>}
     */
    getAssessorsListRelatedByTC: async ({ commit, rootState }, params) => {
      return await AssessorsApi.getAssessorsListRelatedByTC(rootState.user.userSpace, { ...params, related_to_tc: true });
    },
    /** setAssessorStatus
     * @param commit
     * @param rootState
     * @param id
     * @param assessor
     * @returns {Promise<unknown>}
     */
    getAssessorById: async ({ commit, rootState }, id) => {
      try {
        const response = await AssessorsApi.getAssessorById(rootState.user.userSpace, id);
        commit('SET_ASSESSOR_DETAILS_BY_ID', response.data);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    /** setAssessorStatus
     * @param commit
     * @param rootState
     * @param id
     * @param assessor
     * @returns {Promise<unknown>}
     */
    setAssessorStatus: async ({ commit, rootState }, { id, assessor }) => {
      try {
        const response = await AssessorsApi.setAssessorStatus(rootState.user.userSpace, id, {
          assessor
        });
        commit('SET_ASSESSOR_DETAILS_BY_ID', response.data);
        return { id, review_state: response.data.review_state };
      } catch (e) {
        await Promise.reject(e);
      }
    }
  }
};

export default module;
