import Vue from 'vue';
import { langs, PER_PAGE, userSpaces } from '@/config';
import LocalStorageService from '@/services/localStorageService';
import { setUserPermissions } from '@/services/userPermissions';
import RootAPI from '@/api/root.api';

export default {
  /**
   * Qiwa lib actions
   **/
  'SET_PAGE_LOADING' ({ commit }, pageLoading) {
    commit('MUTATION_PAGE_LOADING', pageLoading);
  },
  'SET_LANG' ({ commit, state, dispatch }, lang) {
    if (langs.indexOf(lang) === -1) return;
    try {
      localStorage.setItem('lang', lang);
    } catch (e) {
      console.error(e);
    }
    if (state.lang === lang) return;

    const setFELocale = () => {
      commit('MUTATION_SET_LANG', lang);
      Vue.i18n.locale = lang;
    };

    dispatch('changeLocale')
      .finally(() => {
        setFELocale();
      });
  },
  changeLocale () {
    return Promise.resolve();
  },

  /**
   * Actions
   **/
  /**
   * Redirect user to '/home' if 'shouldRedirect' is true
   * @param {Boolean} shouldRedirect
   * @returns {void}
   */
  clearUserAccessAndRedirect ({ dispatch }, shouldRedirect = false) {
    dispatch('setUserSpace', userSpaces.visitor_space); // Set visitor_space
    setUserPermissions([]); // Reset all permissions
    LocalStorageService.clearUser();
    LocalStorageService.clearToken();
    LocalStorageService.clearTokenLifeTime();

    if (shouldRedirect) Vue.router.push('/home');
  },
  /** getGroups
   * @param state
   * @param id
   * @param filters (per_page, page)
   * @returns {Promise<unknown>}
   */
  getGroups: async ({ state }, params) => {
    return await RootAPI.getGroups(state.user.userSpace, {
      ...params,
      per_page: PER_PAGE
    });
  },
  /** getCategories
   * @param params (page, per_page, english_name, arabic_name, published, occupations_count, locale)
   * @returns {Promise<unknown>}
   */
  getCategories: async ({ state }, params) => {
    return await RootAPI.getCategories(state.user.userSpace, params);
  },
  /** getCategories
   * @param params (id)
   * @returns {Promise<unknown>}
   */
  getAssignedAssessors: async ({ state }, params) => {
    try {
      return await RootAPI.getAssignedAssessors(state.user.userSpace, params);
    } catch (e) {
      await Promise.reject(e);
    }
  },
  /** getTransactionsList
   * @param params ()
   * @returns {Promise<unknown>}
   */
  getTransactionsList: async ({ commit, state }, params) => {
    try {
      return await RootAPI.getTransactionsList(state.user.userSpace, {
        ...params,
        per_page: PER_PAGE
      });
    } catch (e) {
      await Promise.reject(e);
    }
  },
  /** getWithdrawalsList
   * @param params ()
   * @returns {Promise<unknown>}
   */
  getWithdrawalsList: async ({ commit, state }, params) => {
    return await RootAPI.getWithdrawalsList(state.user.userSpace, {
      ...params,
      per_page: PER_PAGE
    });
  },
  /** getPaymentsList
   * @param params ()
   * @returns {Promise<unknown>}
   */
  getPaymentsList: async ({ commit, state }, params) => {
    return await RootAPI.getPaymentsList(state.user.userSpace, {
      ...params,
      per_page: PER_PAGE
    });
  },
  /** downloadCertificatesById
   * @param {Number} id certificate ID
   * @returns {Promise<unknown>}
   */
  downloadCertificatesById: async ({ commit, state }, id) => {
    return await RootAPI.downloadCertificatesById(state.user.userSpace, id);
  },
  /** downloadCertificates
   * @param {Number} id withdrawal ID
   * @returns {Promise<unknown>}
   */
  downloadCertificates: async ({ commit, state }, id) => {
    return await RootAPI.downloadCertificates(state.user.userSpace, id);
  },
  /** sendCertificates
   * @param {Number} id Payment ID
   * @returns {Promise<unknown>}
   */
  sendCertificates: async ({ commit, state }, id) => {
    return await RootAPI.sendCertificates(state.user.userSpace, id);
  },
  /** downloadInvoice
   * @param {Number} id Payment ID
   * @param {String} type 'payments' or 'withdrawals'
   * @returns {Promise<unknown>}
   */
  downloadInvoice: async ({ commit, state }, { id, type }) => {
    return await RootAPI.downloadInvoice(state.user.userSpace, id, type);
  },
  downloadReport: async ({ commit, state }, { id, type }) => {
    return await RootAPI.downloadReport(state.user.userSpace, id, type);
  },
  downloadPassport: async ({ commit, state }, id) => {
    return await RootAPI.downloadPassport(state.user.userSpace, id);
  },
  /** partnerLogos
   * @returns {Promise<unknown>}
   */
  partnerLogos: async ({ state }) => {
    return await RootAPI.partnerLogos(state.user.userSpace);
  },
  /** Activity dates
   * @returns {Promise<unknown>}
   */
  getActivityDates: async ({ state }, id) => {
    return await RootAPI.getActivityDates(state.user.userSpace, id);
  },
  /** Check  Certificate Validity
   * @returns {Promise<unknown>}
   */
  checkCertificateValidity: async ({ state }, params) => {
    return await RootAPI.checkCertificateValidity(state.user.userSpace, params);
  },
  /** Check  Certificate Validity for visitor space
   * @returns {Promise<unknown>}
   */
  certificateValidate: async ({ state }, params) => {
    return await RootAPI.certificateValidate(state.user.userSpace, params);
  },
  /** Check  Labor Result for visitor space
   * @returns {Promise<unknown>}
   */
  checkLaborResult: async ({ state }, payload) => {
    return await RootAPI.checkLaborResult(state.user.userSpace, payload);
  },
  scrollBodyLock ({ commit, state, dispatch }, isOpen) {
    commit('MUTATION_IS_MODAL_OPEN', isOpen);
    document.body.style.overflow = 'hidden';
    if (!isOpen) {
      document.body.removeAttribute('style');
    }
  },
  getFeatureFlagsConfiguration: async ({ commit }) => {
    try {
      const response = await RootAPI.getFlipperConfiguration();
      const { feature_flags } = response.data;
      commit('SET_FEATURE_FLAGS_CONFIGURATION', feature_flags);
    } catch (e) {
      console.log(e);
    }
  }
};
