import RegistrationApi from '@/api/registration.api';
// TODO: Should be removed when feature flag "use_new_register" - will be deleted

const module = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
  /** LaborRegistration
   * @param {type Object} fields
   * @returns {Promise<unknown>}
   */
    async laborRegistration ({ commit }, data) {
      return await RegistrationApi.laborRegistration(data);
    },
    /** validateRegistration
     * @param {type Object} fields
     * @returns {Promise<unknown>}
     */
    async validateRegistration ({ commit }, data) {
      return await RegistrationApi.validateRegistration(data);
    },
    /** resendOtp
     * @param {type Object} fields
     * @returns {Promise<unknown>}
     */
    async resendOtp ({ commit }, data) {
      return await RegistrationApi.resendOtp(data);
    }
  }
};

export default module;
