import PasswordApi from '@/api/password.api';
import { extractErrorMessage, getLocalizedError } from '@takamol/svp-shared-components/errorHandling';
import store from '@/store';

export const validateToken = async (to, from, next) => {
  if (!to.query.password_token) await next({ name: 'login' });
  try {
    await PasswordApi.validateResetPasswordToken({ password_token: to.query.password_token });
    next();
  } catch ({ response }) {
    await next({
      name: 'login',
      query: { errorMessage: getLocalizedError(extractErrorMessage(response.data), store.getters.lang) }
    });
  }
};
