<template>
  <div class="svp-icon">
    <svg :width="width" :height="height" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.92857C0 0.863447 1.04934 0 2.34375 0H22.6563C23.9506 0 25 0.863447 25 1.92857V7.07143C25 8.13655 23.9506 9 22.6563 9H2.34375C1.04934 9 0 8.13655 0 7.07143V1.92857ZM3.20727 4.5C3.20727 3.78993 3.90681 3.21429 4.76977 3.21429C5.6327 3.21429 6.33227 3.78993 6.33227 4.5C6.33227 5.21007 5.6327 5.78571 4.76977 5.78571C3.90681 5.78571 3.20727 5.21007 3.20727 4.5ZM9.45727 3.21429C8.59432 3.21429 7.89477 3.78993 7.89477 4.5C7.89477 5.21007 8.59432 5.78571 9.45727 5.78571C10.3202 5.78571 11.0198 5.21007 11.0198 4.5C11.0198 3.78993 10.3202 3.21429 9.45727 3.21429Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.1951C0 10.9828 1.04934 10 2.34375 10H22.6563C23.9506 10 25 10.9828 25 12.1951V18.0488C25 19.2611 23.9506 20.2439 22.6563 20.2439H13.7541V22.8049H23.5198C24.167 22.8049 24.6917 23.2963 24.6917 23.9024C24.6917 24.5086 24.167 25 23.5198 25H1.64476C0.997544 25 0.472888 24.5086 0.472888 23.9024C0.472888 23.2963 0.997544 22.8049 1.64476 22.8049H11.4104V20.2439H2.34375C1.04934 20.2439 0 19.2611 0 18.0488V12.1951ZM3.20727 15.122C3.20727 14.3137 3.90681 13.6585 4.76977 13.6585C5.6327 13.6585 6.33227 14.3137 6.33227 15.122C6.33227 15.9302 5.6327 16.5854 4.76977 16.5854C3.90681 16.5854 3.20727 15.9302 3.20727 15.122ZM7.89477 15.122C7.89477 14.3137 8.59432 13.6585 9.45727 13.6585C10.3202 13.6585 11.0198 14.3137 11.0198 15.122C11.0198 15.9302 10.3202 16.5854 9.45727 16.5854C8.59432 16.5854 7.89477 15.9302 7.89477 15.122Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconTransaction',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
