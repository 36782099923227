<template>
  <div class="svp-icon">
    <svg :width="width" :height="height" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.50803 2.8691V4.84214L3.39061 5.03204C1.89351 5.16632 0.672301 6.41878 0.432201 8.06618C0.380013 8.42421 0.332563 8.78286 0.289826 9.14204C0.268626 9.32028 0.354163 9.49281 0.500413 9.56975L0.596738 9.62042C7.38263 13.1905 15.5084 13.1905 22.2943 9.62042L22.3907 9.56977C22.5369 9.49282 22.6224 9.32029 22.6012 9.14206C22.5585 8.78288 22.511 8.42421 22.4588 8.06618C22.2188 6.41878 20.9975 5.16632 19.5004 5.03204L17.383 4.84214V2.8691C17.383 1.66598 16.5908 0.643904 15.52 0.465434L13.9952 0.211285C12.3048 -0.070429 10.5862 -0.0704276 8.89593 0.211285L7.37104 0.465434C6.30021 0.643904 5.50803 1.66598 5.50803 2.8691ZM13.717 2.27157C12.2112 2.02059 10.68 2.02059 9.17406 2.27157L7.64918 2.52571C7.4962 2.55121 7.38303 2.69723 7.38303 2.8691V4.6955C10.0891 4.52325 12.8019 4.52325 15.508 4.6955V2.8691C15.508 2.69723 15.3949 2.55121 15.2419 2.52571L13.717 2.27157Z" fill="currentColor"/>
      <path d="M22.8433 12.0493C22.8339 11.8546 22.6507 11.7331 22.4903 11.812C15.5266 15.2378 7.36423 15.2378 0.400575 11.812C0.2402 11.7331 0.0568504 11.8546 0.0475241 12.0492C-0.0796921 14.7041 0.0484979 17.3702 0.4321 20.0021C0.6722 21.6495 1.89341 22.902 3.39051 23.0361L5.73041 23.246C9.53424 23.5873 13.3566 23.5873 17.1604 23.246L19.5003 23.0361C20.9974 22.902 22.2187 21.6495 22.4587 20.0021C22.8423 17.3702 22.9706 14.7041 22.8433 12.0493Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconCase',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
