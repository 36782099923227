<template>
  <div class="svp-icon">
    <svg :width="width" :height="height" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.53901 0L6.71643 8.2598L0.5 14.9754H1.89907L7.34154 9.09579L11.7389 14.9754H16.5L9.97501 6.25096L15.7612 0H14.3621L9.3499 5.41498L5.3001 0H0.53901Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconTwitter',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
