import store from '@/store';

export const isFeatureAvailable = (featureName = null) => {
  const featureFlagsConfiguration = store.getters.featureFlags;

  if (!featureName || !featureFlagsConfiguration) return false;
  const configByName = featureFlagsConfiguration.find(feature => feature.name === featureName);

  return configByName ? configByName?.state === 'true' : false;
};
