import Gateway from '@/api/index';

class RegistrationApi {
  laborRegistration = (data) => {
    return Gateway.post('individual_labor_space/registrations', data);
  }

  validateRegistration = (data) => {
    return Gateway.post('individual_labor_space/registrations/validate', data);
  }

  resendOtp = (data) => {
    return Gateway.post('individual_labor_space/registrations/resend_otp', data);
  }
}

export default new RegistrationApi();
