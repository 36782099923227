import Gateway from '@/api/index';

class SessionsReportApi {
  getSessionsReportsList = (url, params) => {
    return Gateway.get(`${url}/exam_sessions`, { params });
  }

  loadSessionReportById = (url, id) => {
    return Gateway.get(`${url}/exam_sessions/${id}`);
  }
}

export default new SessionsReportApi();
