import Vue from 'vue';
import App from './App.vue';
import i18n from './app/shared/i18n';
import router from '@/app/shared/router';

import store from './store';
import LocalStorageService from './services/localStorageService';
import api from './api';
import { isUserSuspended, setUserPermissions, setUserSpace } from './services/userPermissions';
import VueAxios from 'vue-axios';
import Buefy from 'buefy';
import Vuelidate from 'vuelidate';
import auth from '@websanova/vue-auth';
import authBasic from '@websanova/vue-auth/drivers/auth/basic.js';
import authAxios from '@websanova/vue-auth/drivers/http/axios.1.x.js';
import vueRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x.js';
import VueTelInput from 'vue-tel-input';
import VModal from 'vue-js-modal';
import Toasted from 'vue-toasted';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGtm from '@gtm-support/vue2-gtm';
import SvpSharedComponents from '@takamol/svp-shared-components';
import '@takamol/svp-shared-components/style.css';

import { ObserveVisibility } from 'vue-observe-visibility';
import VCalendar from 'v-calendar';
import DOMPurify from 'dompurify';

import { GTM_KEY, MAP_API_KEY } from './config';
import { isEmpty } from 'lodash';
import { getEnvironmentVar } from '@/utils/environments';

Vue.use(SvpSharedComponents);

Vue.use(VCalendar);

Vue.directive('sanitized-html', (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

Vue.config.errorHandler = (err, vm, info) => {
  if (getEnvironmentVar('ENV') === 'development') console.log(err, vm, info);
};

Vue.router = router;

Vue.use(VueGtm, {
  id: GTM_KEY,
  defer: true,
  compatibility: true,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
});

Vue.config.productionTip = false;
Vue.use(Buefy, { defaultToastDuration: 4000 });
Vue.use(Vuelidate);
Vue.use(VueAxios, api);

Vue.directive('observe-visibility', ObserveVisibility);

// Validation token
authAxios.invalidToken = (res) => {
  if (res.status === 401 && res.config.url === 'refresh') {
    return true;
  }
};

Vue.use(auth, {
  auth: authBasic,
  http: authAxios,
  router: vueRouter,
  rolesKey: 'rolesName',
  authRedirect: { name: 'Home' },
  forbiddenRedirect: { name: 'Home' },
  notFoundRedirect: { name: 'Home' },
  refreshData: {
    enabled: false
  },
  fetchData: {
    enabled: false
  },
  loginData: {
    url: '/sessions/otp',
    method: 'POST',
    redirect: '/',
    staySignedIn: true,
    fetchUser: false,
    success: (response) => {
      const { data } = response;
      // User object shouldn't be empty and shouldn't has 'suspended' status
      if (!isEmpty(data) && !isUserSuspended(data.user)) {
        const { access_payload: { access, access_expires_at }, user } = response.data;
        LocalStorageService.setUser(user);
        LocalStorageService.setAccessToken(access);
        LocalStorageService.setTokenLifeTime(access_expires_at);

        return response;
      }
      return response;
    }
  },
  logoutData: {
    url: '/logout',
    method: 'DELETE',
    redirect: { name: 'Home' },
    makeRequest: true,
    success (response) {
      store.dispatch('clearUserData', false); // Remove User and Token from localStorage
      return response;
    }
  },
  parseUserData (data) {
    // User object shouldn't be empty and shouldn't has 'suspended' status
    if (!isEmpty(data.user) && !isUserSuspended(data.user)) {
      const userData = {
        ...data.user,
        rolesName: data.user.roles.map(role => role.name)
      };

      setUserSpace(userData.rolesName); // Set user space depends on role
      setUserPermissions(userData.rolesName, userData); // Set all permissions

      LocalStorageService.setUser(userData);
      return userData;
    }
    return {};
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: MAP_API_KEY,
    language: 'en',
    libraries: 'places'
  }
});
Vue.use(VueTelInput, {
  mode: 'national',
  enabledCountryCode: true,
  validCharactersOnly: true,
  disabledFetchingCountry: true
});
Vue.use(VModal, { dynamic: true, injectModalsContainer: true, styles: { borderRadius: '8px' } });
Vue.use(Toasted, {
  className: 'alert-msg-inner',
  containerClass: 'alert-msg',
  position: 'top-center',
  duration: 3000
});

/**
 * @returns {Promise}
 */
const exportApp = async () => {
  /**
   * Set Vue.auth.user from localStorage
   */
  const parsedUser = LocalStorageService.getUser();

  if (!isEmpty(parsedUser) && isEmpty(Vue.auth.user())) {
    Vue.auth.user({ ...parsedUser });

    // Set user space and Permissions based on user role
    setUserSpace(parsedUser.rolesName);
    try {
      await setUserPermissions(parsedUser.rolesName, parsedUser);
    } catch (err) {
      console.log(err);
    }
  }

  return new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
  });
};

export const vm = exportApp;
