<template>
  <footer class="svp-footer">
    <div class="content">
      <div class="resources">
        <router-link v-for="(resource, idx) in resources" :key="idx" :to="{ name: resource.pathName }">
          {{ $t(resource.text) }}
        </router-link>
      </div>
      <div class="support">
        <div class="links">
          <a :href="link.href" v-for="link in links">
            <component :is="link.icon" height="14px"/>
          </a>
        </div>
      </div>
      <span class="copyright">
        {{ $t('copyright') }} © {{ formattedYear }} PACC {{ $t('allRightsReserved') }}
      </span>
    </div>
    <div class="logos">
      <img src="@/assets/img/takamolLogo.svg" width="76" height="40" alt="Takamol logo" />
      <img src="@/assets/img/hrsdLogo.svg" width="143" height="40" alt="Hrsd logo" />
    </div>
  </footer>
</template>

<script>
import IconCase from '@/app/shared/components/appShell/icons/IconCase.vue';
import IconYoutube from '@/app/shared/components/appShell/icons/IconYoutube.vue';
import IconFacebook from '@/app/shared/components/appShell/icons/IconFacebook.vue';
import IconTwitter from '@/app/shared/components/appShell/icons/IconTwitter.vue';
import IconLinkedin from '@/app/shared/components/appShell/icons/IconLinkedin.vue';
import moment from 'moment';

export default {
  name: 'SvpFooter',
  components: { IconLinkedin, IconTwitter, IconFacebook, IconYoutube, IconCase },
  data () {
    return {
      resources: [
        {
          pathName: 'PrivacyPolicyPage',
          text: 'privacyPageLink'
        },
        {
          pathName: 'TermsOfUsePage',
          text: 'termsOfUse'
        },
        {
          pathName: 'KnowledgeCenter',
          text: 'knowledgeCenter'
        }
      ],
      links: [
        {
          href: 'https://www.facebook.com/SaudiPACC?mibextid=LQQJ4d',
          icon: 'icon-facebook'
        },
        {
          href: 'https://twitter.com/SaudiPACC',
          icon: 'icon-twitter'
        },
        {
          href: 'https://www.linkedin.com/showcase/74930086/admin/analytics/visitors/',
          icon: 'icon-linkedin'
        },
        {
          href: 'https://www.youtube.com/@professionalaccreditation3045/featured',
          icon: 'icon-youtube'
        }
      ]
    };
  },
  computed: {
    formattedYear () {
      return moment().format('YYYY');
    }
  }
};
</script>

<style lang="scss">
.svp-footer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  bottom: 0;
  padding: 20px;
  min-height: 98px;
  border-top: 1px solid $color-primary;
  color: $color-blue-light1;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  @include media('>=desktop') {
    font-size: 14px;
    line-height: 21px;
    padding-left: 80px;
    padding-right: 80px;
  }
  @include media('>=widescreen') {
    flex-direction: row;
  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    @include media('>phone') {
      gap: 50px;
    }
    @include media('>=widescreen') {
      margin-left: 75px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex: 1;
    font-size: 12px;
    line-height: 18px;
    @include media('>=widescreen') {
      flex-direction: row;
      justify-content: space-between;
    }
    @include media('>=lagescreen') {
      font-size: 14px;
      line-height: 21px;
    }
    .resources {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      justify-content: center;
      a {
        color: $color-grey-middle;
        font-size: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .support {
      display: flex;
      gap: 30px;
      align-items: center;
      .links {
        display: flex;
        gap: 15px;
        .svp-icon {
          height: 17px;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-green-light4;
          width: 24px;
          height: 24px;
          border-radius: $border-radius-soft;
          color: $color-primary;
          transition: all $time-medium ease-in;
          &:hover {
            background-color: $color-blue;
            color: $color-white;
          }
        }

      }
    }
  }
}
</style>
