export default {
  'MUTATION_SET_LANG' (state, lang) {
    state.lang = lang;
  },
  'MUTATION_PAGE_LOADING' (state, pageLoading) {
    state.pageLoading = pageLoading;
  },
  'MUTATION_IS_MODAL_OPEN' (state, isOpen = false) {
    state.isModalOpen = isOpen;
  },
  'SET_FEATURE_FLAGS_CONFIGURATION' (state, conf) {
    state.featureFlags = conf;
  }
};
