import LaborSessionsApi from '@/api/laborSessions.api';

const module = {
  state: {
    userCredits: 0,
    reservationType: ''
  },
  getters: {
    getUserCredits: (state) => {
      return state.userCredits;
    },
    reservationType: (state) => {
      return state.reservationType;
    }
  },
  mutations: {
    'SET_USER_CREDITS' (state, credits) {
      state.userCredits = credits;
    },
    'SET_RESERVATION_TYPE' (state, reservationType) {
      state.reservationType = reservationType;
    }
  },
  actions: {
    /** getAvailableDates
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    getAvailableDates: async (context, params) => {
      return await LaborSessionsApi.getAvailableDates({
        per_page: 10000,
        ...params
      });
    },
    /** Create reservation
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    createReservation: async ({ rootState, commit }, params) => {
      try {
        const response = await LaborSessionsApi.createReservation(rootState.user.userSpace, params);
        await commit('SET_RESERVATION_TYPE', 'international');
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    /** getReservations
     * @param state
     * @returns {Promise<unknown>}
     */
    getReservations: async ({ rootState }) => {
      return await LaborSessionsApi.getReservations(rootState.user.userSpace);
    },
    /** getReservationById
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    getReservationsById: async ({ rootState }, id) => {
      return await LaborSessionsApi.getReservationsById(rootState.user.userSpace, id);
    },
    /** getTicket
     * @param state
     * @param id
     * @returns {Promise<unknown>}
     */
    getTicket: async ({ rootState }, id) => {
      return await LaborSessionsApi.getTicket(rootState.user.userSpace, id);
    },
    /** getCertificate
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    getCertificate: async ({ rootState }, id) => {
      return await LaborSessionsApi.getCertificate(rootState.user.userSpace, id);
    },
    /** fetchUserCredits
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    fetchUserCredits: async ({ state, commit }, payload) => {
      try {
        const { id } = payload;
        const response = await LaborSessionsApi.fetchUserCredits(id);
        commit('SET_USER_CREDITS', response.data.reservation_credits);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    validatePendingPayment: async ({ state, commit }) => {
      return await LaborSessionsApi.validatePendingPayment();
    },
    payWithUserCredits: async ({ state, commit }, payload) => {
      return await LaborSessionsApi.payWithUserCredits(payload);
    },
    /** getReservationPayment
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    getReservationPayments: async ({ rootState }, params) => {
      return await LaborSessionsApi.getReservationPayments(rootState.user.userSpace, params);
    },
    /** getInvoice
     * @param state
     * @param params
     * @returns {Promise<unknown>}
     */
    getInvoice: async ({ rootState }, id) => {
      return await LaborSessionsApi.getInvoice(rootState.user.userSpace, id);
    }
  }
};

export default module;
