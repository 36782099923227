import Gateway from '@/api/index';

class TestCentersApi {
  getTestCenters = (url, params) => {
    return Gateway.get(`${url}/test_centers`, { params });
  }

  createNewTestCenter = (url, data) => {
    return Gateway.post(`${url}/test_centers`, data);
  }

  updateTestCenter = (url, id, data) => {
    return Gateway.put(`${url}/test_centers/${id}`, data);
  }

  getTestCenterById = (url, id) => {
    return Gateway.get(`${url}/test_centers/${id}`);
  }

  updateTestCenterStatus = (url, id, data) => {
    return Gateway.patch(`${url}/test_centers/${id}/update_status`, data);
  }
}

export default new TestCentersApi();
