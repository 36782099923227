import Gateway from '@/api/index';

class PaymentApi {
  prepareCheckout = (url, data) => {
    return Gateway.post(`${url}/payments`, data);
  }

  retrievingPaymentResult = (url, id) => {
    return Gateway.put(`${url}/payments/${id}`);
  }

  prepareCheckoutSession = (url, data) => {
    return Gateway.post(`${url}/payments`, data);
  }

  getTransactionDetails = (url, { id, type }) => {
    return Gateway.get(`${url}/${type}/${id}`);
  }

  getWithdrawalList = (url, params) => {
    return Gateway.get(`${url}/withdrawals/`, { params });
  }

  getListGroupsWithLaborsIDs = (url, params) => {
    return Gateway.get(`${url}/groups`, { params });
  }

  issuingCertificates = (url, params) => {
    return Gateway.post(`${url}/withdrawals`, params);
  }
}

export default new PaymentApi();
