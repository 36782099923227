import SessionsReportApi from '@/api/sessionsReport';

const module = {
  state: {
    sessionDetails: null,
    sessionsList: []
  },
  getters: {
    getSessionDetails: (state) => {
      return state.sessionDetails;
    },
    getCurrentSessionById: (state) => (id) => state.sessionsList.find((item) => Number(item.id) === Number(id))
  },
  mutations: {
    'SET_SESSION_DETAILS_BY_ID' (state, data) {
      state.sessionDetails = data;
    },
    'SET_SESSIONS_LIST' (state, data) {
      state.sessionsList = data;
    }
  },
  actions: {
    getSessionsReportsList: async ({ commit, rootState }, params) => {
      return await SessionsReportApi.getSessionsReportsList(rootState.user.userSpace, params);
    },
    loadSessionReportById: async ({ commit, rootState }, id) => {
      try {
        const response = await SessionsReportApi.loadSessionReportById(rootState.user.userSpace, id);
        await commit('SET_SESSION_DETAILS_BY_ID', response.data);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    }
  }
};

export default module;
