import Gateway from '@/api/index';

class ContentApi {
  getContentPage = (name) => {
    return Gateway.get(`visitor_space/named_content_page/${name}`);
  }

  getFAQpage = () => {
    return Gateway.get('visitor_space/faqs');
  }

  getFAQPageList = (params) => {
    return Gateway.get('visitor_space/content_page_items', { params });
  }

  getOccupationsList = (params) => {
    return Gateway.get('/visitor_space/categories', { params });
  }

  getOccupationsListForLabor = (url, params) => {
    return Gateway.get(`${url}/occupations`, { params });
  }

  getUserGuidePdf = () => {
    return Gateway.get('/visitor_space/user_guides');
  }
}

export default new ContentApi();
