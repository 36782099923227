import VModal from 'vue-js-modal';
import ConfirmModal from '@/app/shared/components/modals/ConfirmModal.vue';
import { confirmModalSettings } from '@/config';
import i18n from '@/app/shared/i18n';

const module = {
  state: {
    formTouched: false
  },
  getters: {
    formTouched: state => state.formTouched
  },
  mutations: {
    'SET_TOUCH' (state, flag) {
      state.formTouched = flag;
    }
  },
  actions: {
    showModalBetweenRouters ({ commit }, { to, from, next }) {
      const isRedirectFromPaymentPage = from.path.includes('payment');
      const isRedirectFromBookingSteps = from.path.includes('booking');

      const getModalTexts = () => {
        if (isRedirectFromPaymentPage) {
          return {
            headLabel: i18n.t('warning'),
            headText: i18n.t('messagesAlert.leavePaymentPage')
          };
        } else if (isRedirectFromBookingSteps) {
          return {
            headLabel: i18n.t('Confirm Booking Process Stop'),
            headText: i18n.t('Are you sure you want to stop the booking process?'),
            confirmBtnText: i18n.t('Stop Booking'),
            cancelBtnText: i18n.t('Continue booking')
          };
        } else {
          return {
            headLabel: i18n.t('warning'),
            headText: i18n.t('messagesAlert.leaveThisPage')
          };
        }
      };
      const modalTexts = getModalTexts();
      VModal.event.$modal.show(ConfirmModal,
        modalTexts,
        confirmModalSettings,
        {
          confirm (e) {
            commit('SET_TOUCH', false);
            commit('SET_STEP', 1);
            next();
          }
        });
    },
    setTouch ({ commit }, flag) {
      commit('SET_TOUCH', flag);
    }
  }
};

export default module;
