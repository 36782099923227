<template>
  <svp-layout :nav-items="navList" :use-aside="showAside">
    <router-view/>
  </svp-layout>
</template>

<script>
import SvpLayout from '@/app/shared/components/appShell/layout/SvpLayout.vue';
import IconTimer from '@/app/shared/components/appShell/icons/IconTimer.vue';
import IconRosette from '@/app/shared/components/appShell/icons/IconRosette.vue';
import IconAssessor from '@/app/shared/components/appShell/icons/IconAssessor.vue';

export default {
  name: 'AssessorLayout',
  components: { SvpLayout },
  computed: {
    navList () {
      const navItems = [];
      const addNavItem = (icon, descriptionPath, routeName) => {
        navItems.push({ icon, descriptionPath, routeName });
      };

      addNavItem(IconTimer, 'nav.unassignedSessions', 'UnassignedSessionsPage');
      addNavItem(IconAssessor, 'assignedSessionsItem', 'AssignedSessionsPage');
      addNavItem(IconRosette, 'nav.startExam', 'AssessorStartExam');

      return navItems;
    },
    showAside () {
      return this.$route.meta.showAside;
    }
  }
};
</script>
