import PasswordApi from '@/api/password.api';

const module = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    /**
     * userSetPassword
     * @param commit
     * @param id
     * @param data
     * @returns {Promise<unknown>}
     */
    userSetPassword: async ({ commit }, data) => {
      return await PasswordApi.userSetPassword(data);
    },
    /**
     * validateResetPasswordToken
     * @param commit
     * @param password_token
     * @returns {Promise<unknown>}
     */
    validateResetPasswordToken: async ({ commit }, { password_token }) => {
      return await PasswordApi.validateResetPasswordToken({ password_token });
    },

    /**
     * reset password by email
     * forgot password
     * @param commit
     * @param email
     * @returns {Promise<unknown>}
     */
    resetPasswordByEmail: async ({ commit }, payload) => {
      return await PasswordApi.resetPasswordByEmail(payload);
    },
    /**
     * reset password by phone
     * @param commit
     * @param email
     * @returns {Promise<unknown>}
     */
    resetPasswordByPhone: async ({ commit }, payload) => {
      return await PasswordApi.resetPasswordByPhone(payload);
    },
    /**
     * validate phone otp code
     * @param commit
     * @param email
     * @returns {Promise<unknown>}
     */
    validateResetPhoneOtpCode: async ({ commit }, payload) => {
      return await PasswordApi.validateResetPhoneOtpCode(payload);
    },
    /**
     * userChangePassword
     * @param commit
     * @param id
     * @param data
     * @returns {Promise<unknown>}
     */
    userChangePassword: async ({ commit }, { id, data }) => {
      return await PasswordApi.userChangePassword(id, data);
    }
  }
};

export default module;
