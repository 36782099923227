import { requestConfig } from '@/utils/environments';
import { initializeClarity } from './integrations/clarity-integration';

(async () => {
  try {
    await requestConfig('/config.json');
    initializeClarity();
  } catch (error) {
    console.error(error);
  }
  try {
    const { vm } = require('./app.js');
    const vmInstance = await vm();
    await vmInstance.$store.dispatch('getFeatureFlagsConfiguration'); // should be from vm instance
    vmInstance.$mount('#app');
  } catch (error) {
    console.error(error);
  }
})();
