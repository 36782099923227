import Gateway from '@/api/index';

class LaborSessionsApi {
  getAvailableDates = (params) => {
    return Gateway.get('individual_labor_space/exam_sessions/available_dates', { params });
  }

  createReservation = (url, params) => {
    return Gateway.post(`${url}/exam_reservations`, params);
  }

  getReservations = (url) => {
    return Gateway.get(`${url}/exam_reservations`);
  }

  getReservationsById = (url, id) => {
    return Gateway.get(`${url}/exam_reservations/${id}`);
  }

  getTicket = (url, id) => {
    return Gateway.get(`${url}/tickets/${id}/show_pdf`, { responseType: 'blob' });
  }

  getCertificate = (url, id) => {
    return Gateway.get(`${url}/certificates/${id}/show_pdf`, { responseType: 'blob' });
  }

  fetchUserCredits = (id) => {
    return Gateway.get(`/users/${id}/balance`);
  }

  getReservationPayments = (url, params) => {
    return Gateway.get(`${url}/payments`, { params });
  }

  getInvoice = (url, id) => {
    return Gateway.get(`${url}/invoices/${id}/show_pdf`, { responseType: 'blob' });
  }

  payWithUserCredits = (params) => {
    return Gateway.post('/individual_labor_space/reservation_credits/use', params);
  }

  validatePendingPayment = () => {
    return Gateway.get('/individual_labor_space/payments/validate_pending');
  }

  getBookingHoldingDuration = () => {
    return Gateway.get('/individual_labor_space/exam_constraints');
  }

  createTemporaryReservation = (params) => {
    return Gateway.post('/individual_labor_space/temporary_seats', params);
  }
}

export default new LaborSessionsApi();
