import Gateway from '@/api/index';

class PasswordApi {
  userSetPassword = (data) => {
    return Gateway.put('/users/set_password', data);
  }

  activateLegislator = (data) => {
    return Gateway.put('/users/activate', data);
  }

  validateResetPasswordToken = (params) => {
    return Gateway.get('/users/validate_reset_password_token', { params });
  }

  userChangePassword = (id, data) => {
    return Gateway.put(`/users/${id}/change_password`, data);
  }

  resetPasswordByEmail = (data) => {
    return Gateway.post('users/reset_password', data);
  }

  resetPasswordByPhone = (data) => {
    return Gateway.post('users/reset_password_by_phone', data);
  }

  validateResetPhoneOtpCode = (data) => {
    return Gateway.post('users/validate_reset_phone_otp_code', data);
  }
}

export default new PasswordApi();
