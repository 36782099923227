<template>
  <div class="user-info">
    <b-dropdown class="dropdown-right" @active-change="showProfileLinks = !showProfileLinks">
      <div slot="trigger" class="user-info__opener">
        <div class="user-info__avatar mr-md-2">
          <img :src="logoSrc" alt="avatar">
        </div>
        <div class="user-info__desc">
          <div class="user-info__name" v-text="name"/>
          <div class="user-info__role" v-text="role"/>
        </div>
        <svp-icon :class="{'is-dropdown-open': showProfileLinks}"
                  class="user-info__icon"
                  height="20px"
                  icon-name="IconChevronUp"
                  width="20px"
        />
      </div>
      <b-dropdown-item has-link>
        <router-link :to="{ name: 'Home' }" v-text="'User profile'"/>
        <router-link v-if="showNewChangePass" :to="{ name: 'changePass' }" v-text="$t('userInfo.changePassword')"/>
        <router-link v-else :to="{ name: 'changePassOld' }" v-text="$t('userInfo.changePassword')"/>
        <a href="/" v-text="$t('userInfo.logout')" @click.prevent="logout"/>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { isFeatureAvailable } from '@/utils/featureFlags';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'userInfoNew',
  props: {
    showLogo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showProfileLinks: false
    };
  },
  computed: {
    ...mapGetters(['getIsLabor', 'getIsAssessor', 'getIsLegislator', 'getIsTestCenterOwner', 'accountInfo']),
    role () {
      if (this.getIsLegislator) return this.$t('userInfo.legislator');
      if (this.getIsAssessor) return this.$t('userInfo.assessor');
      if (this.getIsLabor) return this.$t('userInfo.labor');
      if (this.getIsTestCenterOwner) return this.$t('userInfo.testCenterOwner');
      return '';
    },
    name () {
      return this.user.full_name;
    },
    user () {
      return this.$auth.user() || {};
    },
    logoSrc () {
      return this.accountInfo ? this.accountInfo.logo_url : require('@/assets/img/user.svg');
    },
    showNewChangePass () {
      return (isFeatureAvailable('use_new_change_password'));
    }
  },
  methods: {
    ...mapActions(['setTouch', 'clearPermissions']),
    async logout () {
      await this.setTouch(false);
      await this.$auth.logout();
      await this.clearPermissions();
    }
  }
};
</script>
<style lang="scss" scoped>
.user-info {
  &__desc {
    margin-right: 4px;
  }

  &__name {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $color-text;
  }

  &__role {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $color-neutral-border-dark;
  }

  &__opener {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__avatar {
    width: 36px;
    height: 36px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &__icon {
    transition: transform 0.3s ease;
    transform: rotate(180deg);

    .svp-icon {
      color: $color-neutral-border-dark;
    }
  }

  .is-dropdown-open {
    transform: rotate(0);
  }
}
</style>
