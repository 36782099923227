import ContentApi from '@/api/content.api';

const module = {
  // Name of pages should be the same as on Admin side
  state: {
    landingPageName: 'landing_page',
    aboutPageName: 'about_page',
    policyPageName: 'policy_page',
    termsOfUsePageName: 'terms_of_use_page',
    currentContentPage: null
  },
  getters: {
    landingPageName: state => state.landingPageName,
    aboutPageName: state => state.aboutPageName,
    policyPageName: state => state.policyPageName,
    termsOfUsePageName: state => state.termsOfUsePageName,
    currentContentPage: ({ currentContentPage }) => {
      const result = {};

      if (currentContentPage?.content_page_items?.length) {
        currentContentPage.content_page_items.forEach(({ item_name, value }) => {
          result[item_name] = value;
        });
      }

      return result;
    }
  },
  mutations: {
    'SET_PRIVACY_POLICY_PAGE' (state, payload) {
      state.privacyPolicyPage.header = payload.header;
      state.privacyPolicyPage.text = payload.content;
    },
    'SET_CURRENT_CONTENT_PAGE' (state, payload) {
      state.currentContentPage = payload;
    }
  },
  actions: {
    /** getContentPage
     * @param {String} name
     * @returns {Promise<unknown>}
     */
    async getContentPage ({ commit }, name) {
      try {
        const response = await ContentApi.getContentPage(name);
        await commit('SET_CURRENT_CONTENT_PAGE', response.data);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    /** getFAQpage
     * @param {String} name
     * @returns {Promise<unknown>}
     */
    getFAQpage: async ({ commit }) => {
      return await ContentApi.getFAQpage();
    },
    /** getOccupationsList
     * @param {String} name
     * @returns {Promise<unknown>}
     */
    getOccupationsList: async ({ commit }, params) => {
      return await ContentApi.getOccupationsList(params);
    },
    /** getOccupationsListForLabor
     * @param {String} name
     * @returns {Promise<unknown>}
     */
    getOccupationsListForLabor: async ({ commit, rootState }, params) => {
      return await ContentApi.getOccupationsListForLabor(rootState.user.userSpace, params);
    }
  }
};

export default module;
