import Gateway from '@/api/index';

class AssessorsApi {
  getAssessorsList = (url, params) => {
    return Gateway.get(`${url}/assessors`, { params });
  }

  getAssessorById = (url, id) => {
    return Gateway.get(`${url}/assessors/${id}`);
  }

  getAssessorsListRelatedByTC = (url, params) => {
    return Gateway.get(`${url}/assessors`, { params });
  }

  setAssessorStatus = (url, id, data) => {
    return Gateway.put(`${url}/assessors/${id}`, data);
  }

  startExam = (data) => {
    return Gateway.post('assessor_space/exam_sessions/start_exam', data);
  }

  getAssessorExamInfo = (params) => {
    return Gateway.get('/assessor_space/exam_reservations/test_taker_exam_info', { params });
  }
}

export default new AssessorsApi();
