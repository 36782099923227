import Vue from 'vue';
import { setUserPermissions } from '@/services/userPermissions';
import { permissionsNames, userSpaces } from '@/config';
import LocalStorageService from '@/services/localStorageService';
import UserApi from '@/api/user.api';

const module = {
  state: {
    userSpace: userSpaces.visitor_space, // visitor_space - default
    isLegislator: false,
    isTestCenterOwner: false,
    isLabor: false,
    isAssessor: false,
    permissionForViewOnly: false,
    userBalance: 0,
    freeCertificates: {},
    userTimeZone: '',
    userCountry: ''
  },
  getters: {
    userSpace: state => state.userSpace,
    getIsLegislator: state => state.isLegislator,
    getIsTestCenterOwner: state => state.isTestCenterOwner,
    getIsLabor: state => state.isLabor,
    getIsAssessor: state => state.isAssessor,
    permissionForViewOnly: state => state.permissionForViewOnly,
    userBalance: state => state.userBalance,
    freeCertificates: state => state.freeCertificates,
    getUserTimeZone: state => state.userTimeZone,
    getUserCountry: state => state.userCountry
  },
  actions: {
    /**
     * Reset all permissions
     * Set visitor_space
     * Remove User and Token from localStorage
     */
    clearUserData: ({ commit, dispatch }) => {
      dispatch('setUserSpace', userSpaces.visitor_space); // Set visitor_space
      dispatch('clearUserAccessAndRedirect', false); // Remove User and Token from localStorage
      dispatch('setUserBalance', 0); // Reset user credit balance
    },
    setUserSpace ({ commit }, data) {
      commit('SET_USER_SPACE', data);
    },
    /**
     * @param {Array} rolesArray
     */
    setUserRoles ({ commit }, rolesArray) {
      const { center_owner, legislator, labor, assessor } = permissionsNames; // From config

      if (rolesArray) {
        commit('SET_LEGISLATOR_ROLE', !!rolesArray.find(role => role === legislator));
        commit('SET_TEST_CENTER_OWNER_ROLE', !!rolesArray.find(role => role === center_owner));
        commit('SET_LABOR_ROLE', !!rolesArray.find(role => role === labor));
        commit('SET_ASSESSOR_ROLE', !!rolesArray.find(role => role === assessor));
      }
    },
    /**
     * @param {Boolean} flag
     */
    setPermissionViewOnly ({ commit }, flag) {
      commit('SET_VIEW_PERMISSION', flag);
    },
    /** getCreditBalance
     * @param params ()
     * @returns {Promise<unknown>}
     */
    getCreditBalance: async ({ commit }, id) => {
      try {
        const response = await UserApi.getCreditBalance(id);
        const { data } = response;
        if (data) {
          await commit('SET_USER_BALANCE', data.balance);
          await commit('SET_FREE_CERTIFICATES', {
            freeCertificatesLeft: data.free_certificates_left,
            freeCertificatesTotal: data.free_certificates_total
          });
        }
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    setUserBalance: ({ commit }, balance) => {
      commit('SET_USER_BALANCE', balance);
    },
    setUserTimeZone: ({ commit }, timeZone) => {
      commit('SET_USER_TIMEZONE', timeZone);
    },
    setUserCountry: ({ commit }, country) => {
      commit('SET_USER_COUNTRY', country);
    }
  },
  mutations: {
    'SET_USER_SPACE' (state, data) {
      state.userSpace = data;
    },
    'SET_LEGISLATOR_ROLE' (state, data) {
      state.isLegislator = data;
    },
    'SET_TEST_CENTER_OWNER_ROLE' (state, data) {
      state.isTestCenterOwner = data;
    },
    'SET_LABOR_ROLE' (state, data) {
      state.isLabor = data;
    },
    'SET_ASSESSOR_ROLE' (state, data) {
      state.isAssessor = data;
    },
    'SET_VIEW_PERMISSION' (state, flag) {
      state.permissionForViewOnly = flag;
    },
    'SET_USER_BALANCE' (state, balance) {
      state.userBalance = Number(balance) || 0;
    },
    'SET_FREE_CERTIFICATES' (state, certificates) {
      state.freeCertificates = certificates;
    },
    'SET_USER_TIMEZONE' (state, timeZone) {
      state.userTimeZone = timeZone;
    },
    'SET_USER_COUNTRY' (state, country) {
      state.userCountry = country;
    }
  }
};

export default module;
