import AuthenticationApi from '@/api/authentication.api';

const module = {
  state: {
    twoFA: false,
    otpMethod: ''
  },
  getters: {
    getOtpMethod: (state) => state.otpMethod
  },
  mutations: {
    'SWITCH_TWO_FA' (state, payload) {
      state.twoFA = payload;
    },
    'SET_OTP_METHOD' (state, method) {
      state.otpMethod = method;
    }
  },
  actions: {
    /**
     *
     * @param commit
     */
    enableTwoFA ({ commit }) {
      commit('SWITCH_TWO_FA', true);
    },
    disableTwoFA ({ commit }) {
      commit('SWITCH_TWO_FA', false);
    },
    setOtpMethod ({ commit }, method) {
      commit('SET_OTP_METHOD', method);
    },
    /**
     * reset2fa
     * @param commit
     * @param email
     * @param locale
     * @returns {Promise<unknown>}
     */
    reset2fa: async ({ commit }, { email, locale }) => {
      return await AuthenticationApi.reset2fa({
        email,
        locale
      });
    },
    /**
   * validate2faToken
   * @param commit
   * @param token
   * @returns {Promise<unknown>}
   */
    validate2faToken: async ({ commit }, { token }) => {
      return await AuthenticationApi.validate2faToken({
        reset_2fa_token: token
      });
    }
  }
};

export default module;
