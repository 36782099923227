import Gateway from '@/api/index';

class LaborsApi {
  getLabors = (url, params) => {
    return Gateway.get(`${url}/labors`, { params });
  }

  getLaborsList = (url, params) => {
    return Gateway.get(`${url}/labors`, { params });
  }

  getRegisteredLaborsList = (url, params) => {
    return Gateway.get(`${url}/reservations`, { params });
  }

  uploadFile = (url, formData) => {
    return Gateway.post(`${url}/labors/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  createLabor = (url, data) => {
    return Gateway.post(`${url}/labors`, data);
  }

  updateLabor = (url, data, id) => {
    return Gateway.put(`${url}/labors/${id}`, data);
  }

  getLaborsByWithdrawalId = (url, data) => {
    return Gateway.get(`${url}/labors`, data);
  }

  sendLaborsCertificates = (url, params) => {
    return Gateway.get(`${url}/labors/send_certificates`, { params });
  }

  downloadLaborsCsvFile = (params) => {
    return Gateway.get('legislator_space/labors/download', { params });
  }

  downloadRegisteredLaborsCsvFile = (params) => {
    return Gateway.get('legislator_space/reservations/download', { params });
  }
}

export default new LaborsApi();
