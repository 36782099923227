<template>
  <div class="svp-icon">
    <svg :width="width" :height="height" viewBox="0 0 667 664" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M333.333 0C150 0 0 149.667 0 334.001C0 500.667 122 639.001 281.333 664.001V430.667H196.667V334.001H281.333V260.333C281.333 176.667 331 130.667 407.333 130.667C443.667 130.667 481.667 137 481.667 137V219.333H439.667C398.333 219.333 385.333 245 385.333 271.334V334.001H478L463 430.667H385.333V664.001C463.88 651.594 535.407 611.517 586.997 551.001C638.587 490.487 666.847 413.521 666.667 334.001C666.667 149.667 516.667 0 333.333 0Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconFacebook',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
