<template>
  <div class="auth-layout">
    <header class="header">
      <div class="content">
        <div class="logo" >
          <img
            :src="require('@/assets/img/logo.svg')"
            alt="logo"
            class="header-logo"
            width="150px"
            @click="$router.push({name: 'Home'})"
            @keydown.enter="$router.push({name: 'Home'})"
            tabindex="0"
          >
        </div>
        <language-switcher class="lang-control" />
      </div>
    </header>
    <main class="main">
      <router-view/>
    </main>
    <svp-footer/>
  </div>
</template>

<script>
import LanguageSwitcher from '@/app/shared/components/ui/LanguageSwitcher.vue';
import SvpFooter from '@/app/shared/components/appShell/footer/SvpFooter.vue';

export default {
  name: 'AuthLayout',
  components: { SvpFooter, LanguageSwitcher }
};
</script>

<style lang="scss" scoped>
.auth-layout {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .header {
    height: 90px;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: end;
    box-shadow: $header-shadow;
    z-index: 10;
    @include media('>=tablet' ) {
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
    }
    @include media('>=desktop' ) {
      padding-left: 80px;
      padding-right: 80px;
    }
    .content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .logo {
        cursor: pointer;
      }
    }
  }
  .main {
    flex-grow: 1;
    background: radial-gradient(131.81% 109.3% at 24.41% 15.07%, rgba(20, 130, 135, 0.03) 0%, rgba(255, 211, 97, 0.08) 19.58%, rgba(20, 130, 135, 0.06) 68.2%, rgba(245, 150, 30, 0.06) 84.53%), #FFF;  }
}
</style>
