export const assessorTranslations = {
  en: {
    assessor: {
      duration: 'Duration',
      status: 'Status',
      view: 'View',
      testCenter: 'Test Center',
      category: 'Category',
      city: 'City',
      actions: 'Actions',
      unAssignedSessions: {
        unassignedExamSessions: 'Unassigned exam sessions',
        startDateAndTime: 'Start Date and Time',
        reject: 'Reject',
        approve: 'Approve'
      },
      assignedSessions: {
        assignedSessions: 'Assigned sessions',
        assignedExamSessions: 'Assigned exam sessions',
        practicalResult: 'Practical result',
        dateAndTime: 'Date and Time',
        noAssignedSessions: 'No assigned sessions yet'
      },
      assessorBasicInfo: {
        numberOfReservations: 'Number of reservations',
        session: 'Session',
        category: 'Category',
        city: 'City',
        testCenter: 'Test Center',
        dateAndTime: 'Date and Time',
        duration: 'Durations',
        fullCapacity: 'Number of Seats',
        sessionStatus: 'Session status',
        map: 'Map',
        assignedAssessor: 'Assigned assessor'
      }
    }
  },
  ar: {
    assessor: {
      duration: 'المدة',
      status: 'الحالة',
      view: 'عرض',
      testCenter: 'مركز الاختبار',
      category: 'المهنة',
      city: 'المدينة',
      actions: 'الإجراءات',
      unAssignedSessions: {
        unassignedExamSessions: 'جلسات الاختبار الغير مسندة',
        startDateAndTime: 'الوقت والتاريخ',
        approve: 'قبول',
        reject: 'رفض'
      },
      assignedSessions: {
        assignedSessions: 'الجلسات المسندة',
        assignedExamSessions: 'جلسات الاختبار',
        practicalResult: 'نتيجة الاختبار العملي',
        dateAndTime: 'التاريخ و الوقت',
        noAssignedSessions: 'لا توجد جلسات مسندة لك'
      },
      assessorBasicInfo: {
        numberOfReservations: 'عدد الحجوزات',
        session: 'الجلسة',
        category: 'الفئة',
        city: 'المدينة',
        testCenter: 'مركز الاختبار',
        dateAndTime: 'التاريخ و الوقت',
        duration: 'مدة',
        fullCapacity: 'عدد المقاعد',
        sessionStatus: 'الحالة',
        map: 'الخريطة',
        assignedAssessor: 'المقيّم المكلّف'
      }
    }
  }
};
