import { permissionsNames } from '@/config';

/**
 * @param roleNames array of roles
 * @type Array
 * @return Array
 */
export const getUserRole = (roleNames) => {
  if (roleNames) return roleNames.find(role => role === permissionsNames.legislator || role === permissionsNames.center_owner || role === permissionsNames.labor || role === permissionsNames.assessor);
};
