import Gateway from '@/api/index';

class ExamSessionsApi {
  getAvailableDatesSvp = (url, params) => {
    return Gateway.get(`${url}/exam_sessions/available_dates`, { params });
  }

  getPracticalResults = (url, id) => {
    return Gateway.get(`${url}/exam_sessions/${id}`);
  }

  updatePracticalResult = (url, { id, result }) => {
    return Gateway.post(`${url}/exam_sessions/${id}/update_practical_results`, result);
  }

  getExamSessions = (url, params) => {
    return Gateway.get(`${url}/exam_sessions`, { params });
  }

  getAssignedExamSessions = (url, params) => {
    return Gateway.get(`${url}/exam_sessions/assigned`, { params });
  }

  getExamSessionById = (url, id) => {
    return Gateway.get(`${url}/exam_sessions/${id}`);
  }

  createSession = (url, data) => {
    return Gateway.post(`${url}/exam_sessions`, data);
  }

  cancelSessions = (url, id, data) => {
    return Gateway.post(`${url}/exam_sessions/${id}/cancel`, data);
  }

  cancelReservations = (url, id, data) => {
    return Gateway.post(`${url}/exam_reservations/${id}/cancel`, data);
  }

  recheduleReservation = (url, id, data) => {
    return Gateway.post(`${url}/exam_reservations/${id}/reschedule`, data);
  }

  getTestTakerExamInfo = (url, params) => {
    return Gateway.get(`${url}/exam_reservations/test_taker_exam_info`, { params });
  }

  startExam = (url, data) => {
    return Gateway.post(`${url}/exam_sessions/start_exam`, data);
  }

  getUnassignedSessions = (url, params) => {
    return Gateway.get(`${url}/exam_sessions`, { params });
  }

  getExamSessionReservations = (url, params, id) => {
    return Gateway.get(`${url}/exam_sessions/${id}/reservations`, { params });
  }

  approveSessionByAssessor = (url, id) => {
    return Gateway.post(`${url}/exam_sessions/${id}/approve_session_application`);
  }

  rejectSessionByAssessor = (url, id) => {
    return Gateway.post(`${url}/exam_sessions/${id}/reject_session_application`);
  }

  getExamConstraints = (url) => {
    return Gateway.get(`${url}/exam_constraints`);
  }

  updateExamEvidence = (id, formData) => {
    return Gateway.post(`test_center_owner_space/exam_reservations/${id}/upload_exam_evidence`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default new ExamSessionsApi();
