import Gateway from '@/api/index';

class AuthenticationApi {
  reset2fa = (params) => {
    return Gateway.get('users/restore_2fa_request', { params });
  }

  validate2faToken = (params) => {
    return Gateway.get('/users/restore_2fa', { params });
  }
}

export default new AuthenticationApi();
